import {ProductType} from "../products/elements/productsTable/ProductsTable";

export type ReleaseType = {
    id: string,
    productId: {
        id: string,
        name: string,
    },
    name: string,
    description: string,
    releaseDate: string,
    licenseText: string,
    licenseTitle: string,
    isArchived: boolean,
    security: boolean,
    releaseCustomers: ReleaseCustomerType[],
};

export type ReleaseFileType = {
    id: string,
    checksum: string,
    description: string,
    releaseId: number,
    filePath: string
};

export type Release = {
  id: number,
  name: string,
  productId: ProductType,
  releaseDate: string,
  releaseFiles: ReleaseFileType[]
}

export const defaultRelease = {
  id: '',
  productId: {
    id: '',
    name: '',
  },
  name: '',
  description: '',
  releaseDate: '',
  licenseTitle: '',
  licenseText: '',
  isArchived: false,
  security: false,
  releaseCustomers: [
    {
      id: '',
      customer: {
        custId: 0,
        email: '',
        name: '',
      },
      disabled: false,
      notified: 0,
      licenseAcceptanceDate: '',
    }],
};

export type ReleaseCustomerType = {
    id: string,
    customer: {
        custId: number,
        email: string,
        name: string,
    },
    disabled: boolean,
    notified: number,
    licenseAcceptanceDate: string,
}

export type ReleaseCustomerPageType = {
  id: string,
  page: number,
  perPage: number,
  customer: {
      custId: number,
      email: string,
      name: string,
  },
  disabled: boolean,
  notified: number,
  licenseAcceptanceDate: string,
}

export type CustomerReleaseType = {
  release: {
    id: string,
    name: string,
    description: string
  },
  disabled: boolean,
}

export type CustomerReleasePageType = {
  page: number,
  perPage: number,
  total: number,
  customerReleases: CustomerReleaseType[]
}

export const defaultReleaseCustomer = {
  id: '',
  customer: {
    custId: 0,
    email: '',
    name: '',
  },
  disabled: false,
  notified: 0,
  licenseAcceptanceDate: '',
};

export type CustomerType = {
    custId: number,
    userId: string,
    email: string,
    name: string
}

export const defaultCustomer = {
  custId: 0,
  userId: '',
  email: '',
  name: ''
};
