import React from 'react';
import { Drawer } from "@mui/material";
import { DetailsField } from '../../../types/types';
import CustomersDetails from '../../customers/elements/customersDetails/CustomersDetails';
import { DrawerStyles } from './Drawers.styles';

type DetailsDrawerProps = {
    detailsFields: DetailsField[],
    drawerTitle: string,
    isDrawerOpen: boolean,
    closeDrawerCallback: (action: React.SetStateAction<boolean>) => void,
};

const DetailsDrawer: React.FC<DetailsDrawerProps> = (props: DetailsDrawerProps) => {
  const closeDrawer = (event: any, callback: (action: React.SetStateAction<boolean>) => void) => {
    if (
      event.type === 'keydown'
            && ((event as React.KeyboardEvent).key === 'Tab'
                || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    callback(false);
  };

  return (
    <Drawer
      anchor="right"
      open={props.isDrawerOpen}
      onClose={(event) => closeDrawer(event, props.closeDrawerCallback)}
    >
      <DrawerStyles>
        <h2 className='bar'>
          {props.drawerTitle}
        </h2>
        {props.detailsFields.map((detailsField, index) => (
          <CustomersDetails
            key={index}
            title={detailsField.title}
            secureAreas={detailsField.secureAreas}
          />
        ))}
      </DrawerStyles>
    </Drawer>
  );
};

export default DetailsDrawer;
