import React from 'react';
import {AuthRoleType} from "./layout/sidebar/Sidebar";

type ReportingAccessProps = {
  data: Array<AuthRoleType> | undefined,
  yes: () => any,
  no: () => any,
}

let isReporting = false;

const check = (data: Array<AuthRoleType>) => {
  data.forEach((role) => {
    if (role.name === 'reporting') {
      isReporting = true;
    }
  });
};

const ReportingAccess: React.FC<ReportingAccessProps> = (props: ReportingAccessProps) => {
  if (props.data)
    check(props.data);
  return isReporting
    ? props.yes()
    : props.no();
};

ReportingAccess.defaultProps = {
  yes: () => <></>,
  no: () => <></>,
};

export default ReportingAccess;
