import React from 'react';
import { FormControl, InputLabel, Select, MenuItem} from '@mui/material';

export interface SelectProps {
  id: string,
  name: string
}

type SelectFieldProps = {
  name: string,
  label: string,
  options: Array<SelectProps> | undefined,
  required: boolean,
  handleChange: (event: any) => void,
};


const GenericSelectField: React.FC<SelectFieldProps> = (props: SelectFieldProps) => {
  const wrapper = React.createRef();

  return <>
    {props.options ? (
      <FormControl
        variant="outlined"
        required
      >
        <InputLabel>{props.label}</InputLabel>
        <Select
          variant="standard"
          value={props.name}
          onChange={props.handleChange}
          ref={wrapper}
          label={props.label}
          required={props.required}
          name={props.name}>
          {props.options.map(option =>
            <MenuItem key={option.id} value={option.name}>{option.name}</MenuItem>
          )}
        </Select>
      </FormControl>
    ) : null}
  </>;
};

export default GenericSelectField;
