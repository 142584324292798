import { gql } from '@apollo/client';

export const GET_LICENSES_BY_ACCOUNT = gql`
  query LicenseByAccount($organizationId: Int) {
  licensesByAccount(organizationId: $organizationId) {
    licenseId
    edition
    addons
    licenseType
    expiryDate
    connectors
    createdAt
    licenseVersion
    license
  }
}
`;
