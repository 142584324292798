import React from 'react';
import { faDownload } from '@fortawesome/free-solid-svg-icons';

import GenericButton from '../generic/GenericButton';
import styles from './DownloadButton.module.css';

type DownloadButtonProps = {
    value?: string,
    label?: string,
    title?: string,
    onClick?: (event: any) => void | undefined
};

const DownloadButton: React.FC<DownloadButtonProps> = (props: DownloadButtonProps) => (
  <GenericButton
    className={styles.button}
    icon={faDownload}
    label={props.label}
    title={props.title}
    onClick={props.onClick}
    value={props.value}
  />
);

export default DownloadButton;
