import React from 'react';
import { List, ListItem, ListItemText } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faInfo, faShieldAlt, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons';

import Card from '../../../widgets/cards/Card';
import styles from './ReleaseDetails.module.css';

type ReleaseDetailsProps = {
    description: string,
    isArchived: boolean,
    releaseDate: string,
    security: boolean,
    onClick: () => void,
};

const ReleaseDetails: React.FC<ReleaseDetailsProps> = (props: ReleaseDetailsProps) => {
  const enablementText = props.isArchived ? 'This release is disabled from being distributed to customers' : 'This release is enabled for customer distribution';

  return (
    <Card
      className={styles.release_details}
      cardTitle="Details"
      onClick={props.onClick}
    >
      <List>
        <ListItem>
          <FontAwesomeIcon fixedWidth icon={faInfo} />
          <ListItemText primary={props.description} />
        </ListItem>
        <ListItem>
          <FontAwesomeIcon fixedWidth icon={faCalendarAlt} />
          <ListItemText primary={props.releaseDate} />
        </ListItem>
        <ListItem>
          {props.isArchived ? (
            <FontAwesomeIcon fixedWidth color="#d36161" icon={faTimesCircle} />
          ) : (
            <FontAwesomeIcon fixedWidth color="#82bc00" icon={faCheckCircle} />
          )}
          <ListItemText primary={enablementText} />
        </ListItem>
        {props.security && (
        <ListItem>
          <FontAwesomeIcon fixedWidth icon={faShieldAlt} />
          <ListItemText primary="There are one or more security fixes present in this product release" />
        </ListItem>
        )}
      </List>
    </Card>
  );
};

export default ReleaseDetails;
