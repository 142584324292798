import React from 'react';
import { List, ListItem, ListItemText, ListItemIcon } from '@mui/material'
import { ChevronRight } from '@mui/icons-material';

import Card from '../../../widgets/cards/Card';
import styles from './AccountAreas.module.css';

type SecureArea = {
    name: string,
    secureAreaId: string
};

type AccountAreasProps = {
    secureAreas: SecureArea[],
    onClick: () => void,
};

const AccountAreas: React.FC<AccountAreasProps> = (props: AccountAreasProps) => (
  <Card
    className={styles.account_areas}
    cardTitle="Resource Access"
    onClick={props.onClick}
  >
    <List>
      {props.secureAreas.map((secureArea: SecureArea) => (
        <ListItem key={secureArea.secureAreaId}>
          <ListItemIcon>
            <ChevronRight />
          </ListItemIcon>
          <ListItemText primary={secureArea.name} />
        </ListItem>
      ))}
    </List>
  </Card>
);

export default AccountAreas;
