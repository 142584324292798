import React from 'react';
import { Auth0ContextInterface, useAuth0 } from '@auth0/auth0-react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';

import {faUser} from '@fortawesome/free-solid-svg-icons';
import {
  ADD_SECURE_AREA,
  DELETE_ASSOCIATION,
  DELETE_SECURE_AREA,
  GET_ACCOUNT_BY_ID,
  GET_CUSTOMERS_BY_ACCOUNT_BY_ID,
  UPDATE_ACCOUNT,
} from '../../../../client/queries/accounts';
import { GET_ROLES } from '../../../../client/queries/roles';
import { GET_USER_ROLES } from '../../../../client/queries/users';
import { GET_SALESFORCE_ID } from '../../../../client/queries/salesforce';
import { GET_LICENSES_BY_ACCOUNT } from '../../../../client/queries/licenses';

import AdminAccess from '../../../AdminAccess';
import PrimaryButton from '../../../widgets/buttons/primary/PrimaryButton';
import BarLoader from '../../../widgets/loaders/barLoader/BarLoader';
import CustomerAccountTable, {
  CustomerAccountType,
  CustomerPageType
} from '../customerAccountTable/CustomerAccountTable';
import InfoBar from '../../../widgets/infobar/InfoBar';
import ConfirmWithOptionsDialog from '../../../widgets/confirmWithOptionsDialog/ConfirmWithOptionsDialog';
import NoMatch from '../../../layout/nomatch/NoMatch';
import GenericDrawer from '../../../layout/drawers/GenericDrawer';
import AccountsSearchCustomerDrawer from '../../../layout/drawers/searchCustomers/AccountsSearchCustomerDrawer';
import { FormField } from '../../../../types/types';
import AccountDetails from '../accountDetails/AccountDetails';
import AccountAreas from '../accountAreas/AccountAreas';
import LicensesTable from '../licensesTable/LicensesTable';
import {AuthRoleType} from "../../../layout/sidebar/Sidebar";
import { AccountsPageContentContainer, AccountsPageLayout } from './accounts.styles'
import { defaultSnackbarState, SnackbarType } from '../../../widgets/infobar/types';

type AccountType = {
    organizationid: string,
    name: string,
    accesscode: string,
    salesforceid: string,
};

type SalesforceAccountType = {
    id: string,
    name: string,
    link: string,
}

type AssociationType = {
    custid: number,
    orgid: string | undefined,
    roleid: string,
}

export type RoleType = {
    roleId: number,
    name: string
}

type SecureArea = {
    secureAreaId: string,
    name: string,
    checked: boolean,
};

const AccountsPage: React.FC = () => {
  const { isAuthenticated, user }: Auth0ContextInterface = useAuth0();
  const user_id = user?.sub;

  const [selectedCustomerRoles, setSelectedCustomerRoles] = React.useState<RoleType[]>([]);
  const [customerPageNumber, setCustomerPageNumber] = React.useState<number>(0);
  const [customerPage, setCustomerPage] = React.useState<CustomerPageType>({
    page: 0,
    perPage: 0,
    total: 0,
    customers: []
  });
  const { id } = useParams();
  const [isSearchDrawerOpen, setIsSearchDrawerOpen] = React.useState<boolean>(false);
  const [isAreaDrawerOpen, setIsAreaDrawerOpen] = React.useState(false);
  const [snackbar, setSnackbar] = React.useState<SnackbarType>(defaultSnackbarState);
  const [confirmDialog, setConfirmDialog] = React.useState<boolean>(false );
  const [inputSalesForceId, setInputSalesForceId] = React.useState<string>('');
  const [roleDialog, setRoleDialog] = React.useState('');

  const [secureAreas, setSecureAreas] = React.useState<SecureArea[]>([]);
  const [associationToDelete, setAssociationToDelete] = React.useState<AssociationType>({
    custid: 0,
    orgid: '0',
    roleid: '0',
  });

  const [isUpdateDrawerOpen, setIsUpdateDrawerOpen] = React.useState(false);
  const [existingAccount, setExistingAccount] = React.useState<AccountType>({
    organizationid: '',
    name: '',
    accesscode: '',
    salesforceid: '',
  });

  const decorateSecureAreas = (account: any) => {
    const enabledAreaIds = account.accountById.secureAreas.map((area: SecureArea) => area.secureAreaId);
    const decoratedSecureAreas = account.searchSecureAreas.map((area: SecureArea) => {
      if (enabledAreaIds.includes(area.secureAreaId)) {
        return { ...area, checked: true };
      }
      return { ...area, checked: false };
    });
    setSecureAreas(decoratedSecureAreas);
  };

  // Queries
  const { loading: rolesLoading, data: roles, error: rolesError } = useQuery(GET_ROLES);

  const { loading: adminLoading, data, error: adminError } = useQuery<{ customerRoles: AuthRoleType[] }>(GET_USER_ROLES, {
    variables: { id: user_id },
  });

  const {
    loading: accountLoading, data: account, refetch, error: accountError,
  } = useQuery(GET_ACCOUNT_BY_ID, {
    variables: { organizationid: id },
    onCompleted: (acc) => decorateSecureAreas(acc),
  });

  const {
    loading: customerPageByAccountIdLoading, data: customerPageByAccountIdData, refetch: refetchCustomerPageByAccountId
  } = useQuery<{ accountById: { customersPage: CustomerPageType } }>(GET_CUSTOMERS_BY_ACCOUNT_BY_ID,
    {
      variables: { organizationid: id, customerPage: customerPageNumber },
      onCompleted: data => {
        setCustomerPage(data.accountById.customersPage)
      },
      onError: error => {
        setSnackbar({ isOpen: true, message: 'It was not possible to retrieve the customers. Error: ' +
            error.message, severity: 'error' });
      },
      notifyOnNetworkStatusChange: true,
    });

  const [ validateSalesforceId ] = useLazyQuery<{ searchCustomerId: SalesforceAccountType }>(GET_SALESFORCE_ID, {
    onCompleted: () => {
      handleUpdateAccount();
    },
    onError: e => {
      setSnackbar({ isOpen: true, message: e.message, severity: 'error' });
      setIsUpdateDrawerOpen(false);
    }
  });

  const { loading: licensesLoading, data: licenses, error: licensesError } = useQuery(GET_LICENSES_BY_ACCOUNT, {
    variables: { organizationId: id },
  });

  // Mutations
  const [updateExistingAccount] = useMutation(UPDATE_ACCOUNT, {
    refetchQueries: [{
      query: GET_ACCOUNT_BY_ID,
      variables: { organizationid: id },
    }],
    onCompleted: () => {
      setSnackbar({ isOpen: true, message: 'Account updated!', severity: 'success' });
    },
    onError: (err) => {
      setSnackbar({ isOpen: true, message: 'Problem updating account. Please try again.', severity: 'error' });
    }
  });

  const openSnackbar = (data: any) => {
    if (data.deleteCustomerAccount.ok) setSnackbar({ isOpen: true, message: 'Role was successfully removed from customer!', severity: 'success' });
    else setSnackbar({ isOpen: true, message: 'Unable to remove role from customer.', severity: 'error' });
  };

  const [deleteAssociation] = useMutation(DELETE_ASSOCIATION, {
    refetchQueries: [{
      query: GET_CUSTOMERS_BY_ACCOUNT_BY_ID,
      variables: { organizationid: id, customerPage: 0 },
    }],
    onCompleted: openSnackbar,
  });

  const [addSecureAreaToAccount] = useMutation(ADD_SECURE_AREA, {
    refetchQueries: [{
      query: GET_ACCOUNT_BY_ID,
      variables: { organizationid: id },
    }],
  });

  const [deleteSecureAreaFromAccount] = useMutation(DELETE_SECURE_AREA, {
    refetchQueries: [{
      query: GET_ACCOUNT_BY_ID,
      variables: { organizationid: id },
    }],
  });

  // Remove Roles from Customers
  const openDeleteCustomerDialog = (event: React.MouseEvent<HTMLButtonElement>) => {
    let custId = 0;
    customerPageByAccountIdData?.accountById.customersPage.customers.forEach((customer: CustomerAccountType ) => {
      if (customer.custId === parseInt(event.currentTarget.value)) {
        custId = customer.custId;
        setSelectedCustomerRoles(customer.roles);
      }
    });
    setAssociationToDelete({ ...associationToDelete, custid: custId, orgid: id });
    setConfirmDialog(true);
  };

  const handleConfirmDialogRoleChange = (event: React.ChangeEvent<any>) => {
    setRoleDialog(event.target.value);
    setAssociationToDelete({ ...associationToDelete, roleid: event.target.value });
  };

  const handleConfirmDialogCancel = () => {
    setConfirmDialog(false);
  };

  const handleConfirmDialogOk = async () => {
    await deleteAssociation({
      variables: {
        custId: associationToDelete.custid,
        orgId: associationToDelete.orgid,
        roleId: associationToDelete.roleid,
      },
    });
    setRoleDialog('');
    setAssociationToDelete({ custid: 0, orgid: '0', roleid: '0' });
    setConfirmDialog(false);
  };

  const handleAreaChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const updatedSecureAreas = secureAreas.map((area) => {
      if (area.secureAreaId === event.currentTarget.value) {
        return { ...area, checked: event.currentTarget.checked };
      }
      return area;
    });
    setSecureAreas(updatedSecureAreas);
  };

  const filterEditedAreas = () => {
    const enabledAreaIds = account.accountById.secureAreas.map((area: SecureArea) => area.secureAreaId);
    return secureAreas.filter((area) => (
      area.checked && !enabledAreaIds.includes(area.secureAreaId)) ||
      (!area.checked && enabledAreaIds.includes(area.secureAreaId)));
  };

  async function asyncForEach(array: any[], callback: (element: any, index: number, array: any[]) => any) {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array);
    }
  }

  const handleEditAreas = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const filteredAreas = filterEditedAreas();
    try {
      await asyncForEach(filteredAreas, async (area) => {
        if (area.checked) {
          await addSecureAreaToAccount({
            variables: {
              organizationId: id,
              secureAreaId: area.secureAreaId,
            },
          });
        } else {
          await deleteSecureAreaFromAccount({
            variables: {
              organizationId: id,
              secureAreaId: area.secureAreaId,
            },
          });
        }
      });
      refetch();
      setIsAreaDrawerOpen(false);
      setSnackbar({ isOpen: true, message: 'Resource areas updated successfully', severity: 'success' });
    } catch (e) {
      setSnackbar({
        isOpen: true,
        message: 'Could not update resource areas on account. Please try again',
        severity: 'error',
      });
    }
  };

  // Update Account Details
  async function handleUpdateAccount() {
    await updateExistingAccount({
      variables: {
        organizationid: existingAccount.organizationid,
        name: existingAccount.name,
        accesscode: existingAccount.accesscode,
        salesforceid: inputSalesForceId,
      },
    });
    setExistingAccount({
      organizationid: '', name: '', accesscode: '', salesforceid: '',
    });
    setInputSalesForceId('');
    setIsUpdateDrawerOpen(false);
  }

  const updateAccount = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (inputSalesForceId && inputSalesForceId !== existingAccount.salesforceid) {
      validateSalesforceId({variables: { search: inputSalesForceId}});
    } else {
      handleUpdateAccount();
    }
  };

  const openUpdateDrawer = () => {
    const selectedAccount: AccountType = account.accountById;

    setExistingAccount({
      organizationid: selectedAccount.organizationid,
      name: selectedAccount.name,
      accesscode: selectedAccount.accesscode,
      salesforceid: selectedAccount.salesforceid,
    });

    setInputSalesForceId(selectedAccount.salesforceid);
    setIsUpdateDrawerOpen(true);
  };

  const handleUpdatedAccountNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setExistingAccount({ ...existingAccount, name: event.currentTarget.value });
  };

  const handleUpdatedAccountAccessCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setExistingAccount({ ...existingAccount, accesscode: event.currentTarget.value });
  };

  const handleUpdatedAccountSalesforceIDChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputSalesForceId(event.currentTarget.value)
  };

  const handleChangeCustomerPage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
    setCustomerPageNumber(newPage);
    refetchCustomerPageByAccountId({organizationid: id, customerPage: newPage});
    setSnackbar({severity: "info", isOpen: true, message: "Loading customers."})
  };

  // Helpers
  const openCreateLicensePage = () => {
    window.location.assign(`https://license.tasktop.com/organization/${id}`);
  };

  const closeSnackbar = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') return;
    setSnackbar({ ...snackbar, isOpen: false });
  };

  const openSearchDrawer = () => {
    setIsSearchDrawerOpen(true);
  };

  const openAreaDrawer = () => {
    setIsAreaDrawerOpen(true);
  };

  if (adminLoading
        || accountLoading
        || rolesLoading
        || licensesLoading
  ) return (<BarLoader />);

  if ( adminError || accountError || rolesError || !account ) return (<NoMatch/>);

  if (licensesError)
    setSnackbar({ isOpen: true, severity: "error", message: 'Error to get the license information.'});

  const createAreaFields = () => {
    const formFields: FormField[] = secureAreas.map((secureArea: SecureArea) => (
      {
        checked: secureArea.checked,
        value: secureArea.secureAreaId,
        label: secureArea.name,
        fieldType: 'checkboxField',
        required: false,
        handleChange: handleAreaChange,
      }
    ));
    return formFields;
  };

  const existingAccountFields: FormField[] = [
    {
      value: existingAccount.name,
      label: 'Account Name',
      fieldType: 'textField',
      required: true,
      handleChange: handleUpdatedAccountNameChange,
    },
    {
      value: existingAccount.accesscode,
      label: 'Access Code',
      fieldType: 'textField',
      required: true,
      handleChange: handleUpdatedAccountAccessCodeChange,
    },
    {
      value: inputSalesForceId ? inputSalesForceId : '',
      label: 'Salesforce ID',
      fieldType: 'textField',
      required: false,
      handleChange: handleUpdatedAccountSalesforceIDChange,
    },
  ];

  return (
    <>
      {isAuthenticated
        ? (
          <AdminAccess
            role="admin"
            data={data?.customerRoles}
            yes={() => (
              <AccountsPageContentContainer>
                <InfoBar
                  isOpen={snackbar.isOpen}
                  message={snackbar.message}
                  closeSnackbar={closeSnackbar}
                  severity={snackbar.severity}
                />
                <ConfirmWithOptionsDialog
                  isOpen={confirmDialog}
                  setOpen={setConfirmDialog}
                  title="Remove this role from the customer?"
                  message="This customer will no longer have access to the role's content on this account."
                  data={selectedCustomerRoles}
                  role={roleDialog}
                  handleRoleChange={handleConfirmDialogRoleChange}
                  handleCancel={handleConfirmDialogCancel}
                  handleOk={handleConfirmDialogOk}
                />
                <GenericDrawer
                  formFields={createAreaFields()}
                  drawerTitle="Edit Resource Area Access"
                  isDrawerOpen={isAreaDrawerOpen}
                  closeDrawerCallback={setIsAreaDrawerOpen}
                  submitForm={handleEditAreas}
                />
                <GenericDrawer
                  formFields={existingAccountFields}
                  drawerTitle="Update Account"
                  isDrawerOpen={isUpdateDrawerOpen}
                  closeDrawerCallback={setIsUpdateDrawerOpen}
                  submitForm={updateAccount}
                />
                <AccountsSearchCustomerDrawer
                  userId={user_id}
                  accountID={account.accountById.accesscode}
                  refetchCustomersPage={refetchCustomerPageByAccountId}
                  setSnackbar={setSnackbar}
                  drawerTitle="Find Customer"
                  isDrawerOpen={isSearchDrawerOpen}
                  closeDrawerCallback={setIsSearchDrawerOpen}
                  rolesDB={roles.allRoles}
                />
                <AccountsPageLayout>
                  <h2 className="accountTitle">
                    {account.accountById.name}
                  </h2>
                  <AccountDetails
                    accesscode={account.accountById.accesscode}
                    salesforceid={account.accountById.salesforceid}
                    onClick={openUpdateDrawer}
                  />
                  <AccountAreas
                    secureAreas={account.accountById.secureAreas}
                    onClick={openAreaDrawer}
                  />
                  <div className="accountsSection">
                    <div className="actions">
                      <PrimaryButton
                        icon={faUser}
                        label="Associate Customers"
                        className='add_button'
                        onClick={openSearchDrawer}
                        type="submit"
                      />
                    </div>

                    <div className='accountCustomers'>
                      {
                        !customerPageByAccountIdLoading ?
                          <CustomerAccountTable
                            customerPage={customerPage}
                            handleChangePage={handleChangeCustomerPage}
                            deleteCustomer={openDeleteCustomerDialog}
                          /> : <BarLoader/>
                      }
                    </div>
                  </div>
                  <div className="accountLicenses">
                    <LicensesTable
                      licenses={licenses.licensesByAccount}
                      accountName={account.accountById.name}
                      onCreateLicense={openCreateLicensePage}
                    />
                  </div>
                </AccountsPageLayout>
              </AccountsPageContentContainer>
            )}
            no={() => <NoMatch />}
          />
        )
        : null}
    </>
  );
};

export default AccountsPage;
