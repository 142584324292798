export type SnackbarType = {
    isOpen: boolean,
    message: string,
    severity: 'error' | 'warning' | 'info' | 'success',
};

export const defaultSnackbarState: SnackbarType = {
  isOpen: false,
  message: '',
  severity: 'success',
};
