import React from 'react';

import { FormControl, FormLabel, RadioGroup } from '@mui/material';
import { RadioGroupProps } from './radioTypes'
import GenericRadioButton from './GenericRadioButton'

const GenericRadioGroup: React.FC<RadioGroupProps> = (props: RadioGroupProps) => {

  function camelize(str: string) {
    return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    }).replace(/\s+/g, '');
  }

  return <>
        <FormControl
          variant="standard"
          component="fieldset">
          <FormLabel component="legend">{props.label}</FormLabel>
          <RadioGroup aria-label={props.label} name={camelize(props.label)} value={props.value} onChange={props.handleChange} row={props.row ? props.row : false}>
            {props.fields ? (
              props.fields.map((field, i) => (
                <GenericRadioButton key={`${field.value}-${i}`} value={field.value} label={field.label} />
              ))) : null}
          </RadioGroup>
        </FormControl>
  </>;
};

export default GenericRadioGroup;
