import React from 'react';

import styles from './SecondaryButton.module.css';

type SecondaryButtonProps = {
    label: string,
    type?: 'button' | 'submit' | 'reset' | undefined,
    onClick: () => void
};

const SecondaryButton: React.FC<SecondaryButtonProps> = ({
  label, type, onClick
}: SecondaryButtonProps) => (
  <button type={type} onClick={onClick} className={styles.btn}>
    {label}
  </button>
);

export default SecondaryButton;
