import React from 'react';

import styles from './VerifyEmailPage.module.css';

const VerifyEmailPage: React.FC = () => (
  <div className={styles.no_match}>
    <h1>A verification email was sent to you.</h1>
    <p>Please verify your account before continuing.</p>
  </div>
);

export default VerifyEmailPage;
