import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { TableCell, TableRow, Grid } from '@mui/material';
import { Security } from '@mui/icons-material';
import TableWrapper from '../../../layout/table/TableWrapper';
import DetailsButton from '../../../widgets/buttons/details/DetailsButton';
import { ReleaseType } from '../../types'
import { BarLoader } from 'react-spinners';
import { TasktopSwitch } from '../../../widgets/switches/Switch.styles';


type ReleasesTableProps = {
  releases: ReleaseType[],
  toggleStatus: (event: React.ChangeEvent<HTMLInputElement>) => void,
  loading: boolean
};

const ReleasesTable: React.FC<ReleasesTableProps> = ({
  releases, toggleStatus, loading
}: ReleasesTableProps) => {
  const [page, setPage] = React.useState(0);
  const rowsPerPage = 10;

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
    setPage(newPage);
  };

  const headColumns = (
    <>
      <TableCell>Name</TableCell>
      <TableCell>Description</TableCell>
      <TableCell>Release Date</TableCell>
      <TableCell>Product</TableCell>
      <TableCell align="right">Enabled</TableCell>
      <TableCell align="center">Actions</TableCell>
    </>
  );

  useEffect(() => {
    setPage(0)
  }, [releases])

  if (loading) return (<BarLoader />);

  const tableContents = releases.map((release: ReleaseType) => (
    <TableRow key={release.id}>
      <TableCell
        component="th"
        scope="row"
      >
        <Grid container direction="row" alignItems="center" spacing={1}>
          <Grid item>
            {release.name}
          </Grid>
          {(release.security
            ? (
              <Grid item>
                <Security />
              </Grid>
            )
            : null)}
        </Grid>
      </TableCell>
      <TableCell>
        {release.description}
      </TableCell>
      <TableCell>
        {release.releaseDate.substring(0, release.releaseDate.indexOf('T'))}
      </TableCell>
      <TableCell>
        {release.productId.name}
      </TableCell>
      <TableCell align="right">
        <TasktopSwitch
          checked={!release.isArchived}
          value={release.id}
          name="isArchived"
          onChange={toggleStatus}
        />
      </TableCell>
      <TableCell align="center">
        <Link to={{ pathname: `/releases/${release.id}` }}>
          <DetailsButton />
        </Link>
      </TableCell>
    </TableRow>
  ));

  return (
    <TableWrapper
      headColumns={headColumns}
      tableContents={tableContents}
      page={page}
      handleChangePage={handleChangePage}
      rowsPerPage={rowsPerPage}
    />
  );
};

export default ReleasesTable;
