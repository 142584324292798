import React, {useEffect} from 'react';
import { Auth0ContextInterface, useAuth0 } from '@auth0/auth0-react';
import { Routes, Route } from 'react-router-dom';

import { Link } from "@mui/material";
import { ThemeProvider } from '@mui/styles';
import { createTheme } from '@mui/material/styles';

import Sidebar from './components/layout/sidebar/Sidebar';
import BarLoader from './components/widgets/loaders/barLoader/BarLoader';
import Welcome from './components/landing/Landing';
import NoMatch from './components/layout/nomatch/NoMatch';

import { pages } from './pages';
import './App.css';
import { useMutation } from "@apollo/client";
import { SIGN_UP } from "./client/queries/customers";
import VerifyEmailPage from "./components/layout/verifyEmailPage/VerifyEmailPage";
import CookieConsent from "react-cookie-consent";
import PageTemplate from './components/pageTemplate/PageTemplate';

const theme = createTheme();

const App: React.FC = () => {
  const {isAuthenticated, user, isLoading, error}: Auth0ContextInterface = useAuth0();
  const [ signUp ] = useMutation(SIGN_UP);

  useEffect(() => {
    if (isAuthenticated && user)
      signUp({ variables: { userId : user.sub }});
  }, [isAuthenticated, signUp, user]);

  if (isLoading) {
    return (
      <div className="app_loading">
        <BarLoader/>
      </div>
    );
  }

  return error ? (<VerifyEmailPage/> ) :
  (
    <ThemeProvider theme={theme}>
      <div className="app">
        <Sidebar pages={pages}/>
        <Routes>
          {pages.map((page, index) => {
            return (
              <Route key={index} path={page.path} element={<PageTemplate name={page.name} component={page.component} />}>
              </Route>
            );
          })}
          <Route path='/' element={<Welcome />} />
          <Route path='*' element={<NoMatch />} />
        </Routes>
        <CookieConsent
          location="bottom"
          buttonText="Proceed"
          declineButtonText="Decline"
          cookieName="tt_cookie_consent"
          cookieValue='dismiss'
          enableDeclineButton={true}
          expires={730}
          buttonStyle={{
            backgroundColor: "#82bc00",
            color: "#f6f6f6",
            borderRadius: "3px",
            fontSize: "14px",
            fontWeight: "bold"
          }}
          declineButtonStyle={{
            backgroundColor: "white",
            border: "1px solid #ccc",
            color: "#666",
            borderRadius: "3px",
            fontSize: "14px",
            fontWeight: "bold"
          }}
        >
          This website uses cookies to provide a better user experience, personalize ads, and analyze our traffic.
          By clicking {"'Proceed'"} you are consenting to the use of cookies.
          For more information about how we use cookies or to learn how you can disable cookies, click
          <Link
            href="https://links.tasktop.com/cookie-policy"
            target='_blank'
            className="link_policy"
            underline="hover">
            Learn More
          </Link>
        </CookieConsent>
      </div>
    </ThemeProvider>
);
};

export default App;
