import React from 'react';
import { List, ListItem, ListItemText, ListItemIcon } from '@mui/material'
import { Person, Email, MailOutline } from '@mui/icons-material';

import Card from '../../../widgets/cards/Card';
import styles from './settingsDetails.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faShieldAlt } from '@fortawesome/free-solid-svg-icons';

type SettingsDetailsProps = {
  name?: string,
  email?: string,
  newsletter?: string,
  releaseUpdates?: string,
  onClick: () => void,
};

const SettingsDetails: React.FC<SettingsDetailsProps> = (props: SettingsDetailsProps) => {
  return (
    <Card
      className={styles.account_details}
      cardTitle="Details"
      onClick={props.onClick}>
      <List>
        <ListItem>
          <ListItemIcon>
            <Person />
          </ListItemIcon>
          <ListItemText primary={props.name}
            secondary="Name" />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <Email />
          </ListItemIcon>
          <ListItemText primary={props.email}
            secondary="Email - Cannot be changed" />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <MailOutline />
          </ListItemIcon>
          <ListItemText primary={props.newsletter === "true" ?
            "Yes, I would like to receive marketing newsletters" :
            "No, I would not like to receive marketing newsletters"}
            secondary="Marketing Newsletter"
          />
        </ListItem>
        <ListItem>
          {props.releaseUpdates === "productUpdates" && (
            <>
              <ListItemIcon>
                <MailOutline />
              </ListItemIcon>
              <ListItemText primary="I would like to receive all product updates"
                secondary="Product Updates"
              />
            </>
          )}
          {props.releaseUpdates === "securityUpdates" && (
            <>
              <ListItemIcon>
                <FontAwesomeIcon fixedWidth icon={faShieldAlt} />
              </ListItemIcon>
              <ListItemText primary="I would like to receive security-related product release updates"
                secondary="Security Updates"
              />
            </>
          )}
          {props.releaseUpdates === "noUpdates" && (
            <>
              <ListItemIcon>
                <FontAwesomeIcon fixedWidth icon={faBan} />
              </ListItemIcon>
              <ListItemText primary="Don't send any product release updates"
                secondary="No Updates"
              />
            </>
          )}
        </ListItem>
      </List>
    </Card>
  );
};

export default SettingsDetails;
