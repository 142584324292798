import React from 'react';
import { FormControl, FormGroup } from '@mui/material';

import { TasktopSwitch } from './Switch.styles';

interface ReleaseSwitchProps {
  handleChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void,
}

const SwitchesGroupReleases: React.FC<ReleaseSwitchProps> = (props: ReleaseSwitchProps) => {
  const { handleChange } = props

  return (
    <FormControl variant="standard" component="fieldset">
      <FormGroup>
        <TasktopSwitch onChange={handleChange} name="enableCustomer" />
      </FormGroup>
    </FormControl>
  );
}

export default SwitchesGroupReleases
