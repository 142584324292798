import React from 'react';
import classnames from 'classnames';
import { IconDefinition, SizeProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styles from './GenericButton.module.css';

type ButtonProps = {
    icon: IconDefinition,
    className?: string,
    label?: string,
    onClick?: (event: any) => void | undefined,
    size?: SizeProp,
    value?: string,
    title?: string
};

const GenericButton: React.FC<ButtonProps> = (props: ButtonProps) => {
  const classNames = classnames(styles.button, props.className);

  return (
    <button
      aria-label={props.label ? props.label : ''}
      className={classNames}
      onClick={props.onClick}
      type="button"
      value={props.value ? props.value : ''}
      title={props.title ? props.title : ''}
    >
      <FontAwesomeIcon size={props.size ? props.size : 'lg'} icon={props.icon} />
    </button>
  );
};

export default GenericButton;
