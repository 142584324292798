import { gql } from '@apollo/client';

export const GET_ROLES = gql`
  query GetRoles {
    allRoles {
      roleId
      name
    }
  }
`;
