import React from 'react';
import classnames from 'classnames';
import { faEdit } from '@fortawesome/free-regular-svg-icons';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

import GenericButton from '../buttons/generic/GenericButton';
import styles from './Card.module.css';

type CardProps = {
    className: string,
    cardTitle: string,
    buttonIcon?: IconDefinition,
    children: JSX.Element | any,
    onClick: () => void,
};

const Card: React.FC<CardProps> = (props: CardProps) => {
  const classname = classnames(styles.card, props.className);
  const icon = props.buttonIcon || faEdit;

  return (
    <div className={classname}>
      <h3>{props.cardTitle}</h3>
      <GenericButton icon={icon} onClick={props.onClick} label={props.cardTitle} />
      {props.children}
    </div>
  );
};

export default Card;
