import React from 'react';

import styles from './ResourcesHelp.module.css';

const ResourcesHelp: React.FC = () => (
  <div className={styles.resources_help}>
    <h1>Click the &ldquo;Enter Activation Code&ldquo; button on the top left and enter your code to get started.</h1>
    <p>If you don&apos;t have an activation code, please contact your organization&apos;s administrator for an activation code for access to premium content.</p>
    <p>If you are an administrator and would like to request an access code, contact us at support@tasktop.com</p>
  </div>
);

export default ResourcesHelp;
