import React, { useEffect } from 'react';
import { TableRow, TableCell, TableSortLabel } from '@mui/material';
import TableWrapper from '../../../layout/table/TableWrapper';
import { ContentSpaceType } from '../../../contentSpaces/types';

type ResourcesTableProps = {
  resources: ContentSpaceType[]
};

const docsURL = "https://docs.tasktop.com"

type Order = 'asc' | 'desc'

const ResourcesTable: React.FC<ResourcesTableProps> = (props: ResourcesTableProps) => {
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof ContentSpaceType>('name');
  const [page, setPage] = React.useState(0);
  const rowsPerPage = 10;

  useEffect(() => {
    setPage(0)
  }, [props.resources])

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
    setPage(newPage);
  };

  function getComparator(order: Order, orderBy: keyof ContentSpaceType): (a: ContentSpaceType, b: ContentSpaceType) => number {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  const descendingComparator = (a: ContentSpaceType, b: ContentSpaceType, orderBy: keyof ContentSpaceType) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  const sortResources = (sortBy: keyof ContentSpaceType) => () => {
    const isAsc = orderBy === sortBy && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(sortBy);
  };

  const resourceSort = (array: ContentSpaceType[], comparator: (a: ContentSpaceType, b: ContentSpaceType) => number) => {
    const stabilizedThis = array.map((el, index) => [el, index] as [ContentSpaceType, number]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  const headColumns = (
    <>
      <TableCell sortDirection={orderBy === 'name' ? order : false}>
        <TableSortLabel
          active={orderBy === 'name'}
          direction={orderBy === 'name' ? order : 'asc'}
          onClick={sortResources('name')}
        >
          Resource
        </TableSortLabel>
      </TableCell>
      <TableCell align="right" />
    </>
  );

  const tableContents = resourceSort(props.resources, getComparator(order, orderBy))
    .map((secureArea: ContentSpaceType) => (
      <TableRow key={secureArea.name}>
        <TableCell
          component="td"
          scope="row"
        >
          <a className="resources" href={docsURL + secureArea.path}>{secureArea.name}</a>
        </TableCell>
      </TableRow>
    ));

  return (
    <TableWrapper
      headColumns={headColumns}
      tableContents={tableContents}
      page={page}
      handleChangePage={handleChangePage}
      rowsPerPage={rowsPerPage}
    />
  );
};

export default ResourcesTable;
