import React from 'react';
import { Auth0ContextInterface, useAuth0 } from '@auth0/auth0-react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';

import { TextField } from "@mui/material";
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { GET_USER_ROLES } from '../../client/queries/users';
import {
  CREATE_RELEASE, TOGGLE_RELEASE_STATUS, SEARCH_FILTERED_RELEASES, VERSIONS_BY_PRODUCT
} from '../../client/queries/releases';
import { SEARCH_PRODUCTS_FOR_RELEASES, SEARCH_ENABLED_PRODUCTS_FOR_RELEASES } from '../../client/queries/products';

import ReleasesTable from './elements/releasesTable/ReleasesTable';
import PrimaryButton from '../widgets/buttons/primary/PrimaryButton';
import GenericDrawer from '../layout/drawers/GenericDrawer';
import InfoBar from '../widgets/infobar/InfoBar';
import BarLoader from '../widgets/loaders/barLoader/BarLoader';
import NoMatch from '../layout/nomatch/NoMatch';
import AdminAccess from '../AdminAccess';
import ReleaseFilter from "../downloadReleases/elements/releaseFilter/ReleaseFilter";
import SecondaryButton from '../widgets/buttons/secondary/SecondaryButton';
import { defaultRelease, ReleaseType } from './types';
import { FormField } from '../../types/types';
import { defaultSnackbarState, SnackbarType } from '../widgets/infobar/types';
import { AuthRoleType } from "../layout/sidebar/Sidebar";
import { ReleasesContentContainer } from './Releases.styles';

type ProductType = {
  id: string,
  name: string
};

const NUMBER_OF_RELEASES = 5;

const Releases: React.FC = () => {
  const { isAuthenticated, user }: Auth0ContextInterface = useAuth0();
  const user_id = user?.sub;
  const [snackbar, setSnackbar] = React.useState<SnackbarType>(defaultSnackbarState);
  const [product, setProduct] = React.useState('');
  const [filterProduct, setFilterProduct] = React.useState<string>('');
  const [version, setVersion] = React.useState<string>('');
  const [selectedInitialDate, setSelectedInitialDate] = React.useState<Date | null>(null);
  const [selectedEndDate, setSelectedEndDate] = React.useState<Date | null>(null);
  const [isAddReleaseDrawerOpen, setIsAddReleaseDrawerOpen] = React.useState<boolean>(false);
  const [newRelease, setNewRelease] = React.useState<ReleaseType>(defaultRelease);
  const [inputString, setInputString] = React.useState<string>('');
  const [releaseSearch, setReleaseSearch] = React.useState<string>('');

  // Queries

  const [releasesQuery, {
    loading: releasesLoading,
    error: releasesError,
    data: releasesData,
  }] = useLazyQuery<{
    searchFilteredReleases: ReleaseType[]
  }>(SEARCH_FILTERED_RELEASES, {
    variables: {
      search: releaseSearch,
      product: filterProduct,
      version: version,
      initialDate: selectedInitialDate,
      endDate: selectedEndDate
    },
  })

  const [productsQuery, {
    error: searchEnabledProductsError,
    data: searchEnabledProductsData }] = useLazyQuery<{ searchProducts: ProductType[] }>(SEARCH_ENABLED_PRODUCTS_FOR_RELEASES, {
      variables: {
        search: '',
        isarchived: false
      },
    });

  const [versionsByProductQuery, { data: versionsByProductData }] =
    useLazyQuery<{ versionsByProduct: string[] }>(VERSIONS_BY_PRODUCT, {
      variables: {
        product: filterProduct,
        top: NUMBER_OF_RELEASES,
        initialDate: selectedInitialDate,
        endDate: selectedEndDate
      },
      onError: () => {
        setSnackbar({ isOpen: true, severity: "error", message: 'Not able to find any version for this product' });
      },
    });

  // During the React 18 upgrade, this function was implemented as a workaround because calling the individual functions within the onCompleted block were causing the page to break. As of now, this is unresolved, and there have been others that have experienced this issue - https://github.com/apollographql/apollo-client/issues/9846.

  const userRolesHelperFunction = () => {
    releasesQuery()
    productsQuery()
    versionsByProductQuery()
  }

  const { loading: adminLoading, error: adminError, data } = useQuery<{ customerRoles: AuthRoleType[] }>(GET_USER_ROLES, {
    variables: { id: user_id },
    onCompleted: (data) => {
      if (data) {
        userRolesHelperFunction() // See note above
      }
    },
    notifyOnNetworkStatusChange: true
  });

  const { loading: searchProductsLoading, error: searchProductsError, data: products } = useQuery(SEARCH_PRODUCTS_FOR_RELEASES, {
    variables: { search: '' },
  });

  // Mutations
  const [toggleReleaseStatus] = useMutation(TOGGLE_RELEASE_STATUS);
  const [addRelease] = useMutation(CREATE_RELEASE, {
    refetchQueries: [{
      query: SEARCH_FILTERED_RELEASES,
      variables: {
        search: releaseSearch,
        product: filterProduct,
        version: version,
        initialDate: selectedInitialDate,
        endDate: selectedEndDate
      },
    }],
    onCompleted: () => {
      setSnackbar({ isOpen: true, message: 'Release saved!', severity: 'success' });
    },
    onError: () => {
      setSnackbar({ isOpen: true, message: `Problem saving release. Please try again.`, severity: 'error' });
    },
    notifyOnNetworkStatusChange: true
  });

  const productsByCustomer: string[] = searchEnabledProductsData ? searchEnabledProductsData.searchProducts.map((product: ProductType) => product.name) : [];
  const releases: ReleaseType[] = releasesData ? releasesData.searchFilteredReleases : [];
  const versionsByProduct: string[] = filterProduct !== '' && versionsByProductData ? versionsByProductData.versionsByProduct : [];

  // Helpers
  const closeSnackbar = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') return;
    setSnackbar({ ...snackbar, isOpen: false });
  };

  const openAddReleaseDrawer = () => {
    setIsAddReleaseDrawerOpen(true);
  };

  const createRelease = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    await addRelease({
      variables: {
        name: newRelease.name,
        description: newRelease.description,
        releaseDate: newRelease.releaseDate,
        productId: newRelease.productId.id,
        licenseTitle: newRelease.licenseTitle,
        licenseText: newRelease.licenseText,
        security: newRelease.security,
      },
    });
    setNewRelease(defaultRelease);
    setProduct('');
    setIsAddReleaseDrawerOpen(false);
  };

  const handleNewReleaseNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewRelease({ ...newRelease, name: event.currentTarget.value });
  };

  const handleNewReleaseDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewRelease({ ...newRelease, description: event.currentTarget.value });
  };

  const handleNewReleaseDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewRelease({ ...newRelease, releaseDate: event.currentTarget.value });
  };

  const handleNewReleaseProductChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    products.searchProducts.forEach((product: ProductType) => {
      if (product.name === event.target.value) {
        setNewRelease({ ...newRelease, productId: { id: product.id, name: product.name } });
      }
    });
    setProduct(event.target.value);
  };

  const handleNewReleaseLicenseTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewRelease({ ...newRelease, licenseTitle: event.currentTarget.value });
  };

  const handleNewReleaseLicenseTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewRelease({ ...newRelease, licenseText: event.currentTarget.value });
  };

  const handleNewReleaseSecurityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewRelease({ ...newRelease, security: event.target.checked });
  };

  const toggleStatus = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const data = await toggleReleaseStatus({
        variables: {
          id: event.target.value,
          isArchived: !event.target.checked,
        },
      });
      const { isArchived } = data.data.updateRelease.release;
      const message = (isArchived) ? 'Release disabled!' : 'Release enabled!';
      const severity = (isArchived) ? 'warning' : 'success';
      setSnackbar({ isOpen: true, message, severity });
    } catch (e) {
      setSnackbar({ isOpen: true, message: 'Problem updating release. Please try again.', severity: 'error' });
    }
  };

  // Filter Releases
  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setInputString(event.currentTarget.value);
  };

  const handleProductFilter = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (event.currentTarget.textContent) {
      setFilterProduct(event.currentTarget.textContent);
      setVersion('')
      releasesQuery();
      versionsByProductQuery();
    }
  }

  const handleVersionFilter = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (event.currentTarget.textContent) {
      setVersion(event.currentTarget.textContent);
      releasesQuery();
    }
  };

  const handleInitialDateChange = (date: Date | null) => {
    let d = date
    if (date) {
      d = new Date(date)
    }
    setSelectedInitialDate(d);
    releasesQuery();
  };

  const handleEndDateChange = (date: Date | null) => {
    let d = date
    if (date) {
      d = new Date(date)
    }
    setSelectedEndDate(d);
    releasesQuery();
  };

  const handleFilterSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setReleaseSearch(inputString);
    releasesQuery();
  };

  const resetTable = async () => {
    setInputString('');
    setReleaseSearch('');
    setFilterProduct('');
    setVersion('');
    setSelectedInitialDate(null);
    setSelectedEndDate(null);
    releasesQuery();
  };

  const createReleaseFields = () => {
    const fields: FormField[] = [
      {
        value: newRelease.name,
        label: 'Release Name',
        fieldType: 'textField',
        required: true,
        handleChange: handleNewReleaseNameChange,
      },
      {
        value: newRelease.description,
        label: 'Description',
        fieldType: 'textArea',
        required: true,
        handleChange: handleNewReleaseDescriptionChange,
      },
      {
        value: newRelease.releaseDate,
        label: 'Release Date (YYYY-MM-DD)',
        fieldType: 'textField',
        required: true,
        handleChange: handleNewReleaseDateChange,
      },
      {
        value: product,
        options: products.searchProducts,
        label: 'Product',
        fieldType: 'selectField',
        required: true,
        handleChange: handleNewReleaseProductChange,
      },
      {
        value: newRelease.licenseTitle,
        label: 'License Title',
        fieldType: 'textField',
        required: true,
        handleChange: handleNewReleaseLicenseTitleChange,
      },
      {
        value: newRelease.licenseText,
        label: 'License Text',
        fieldType: 'textArea',
        required: true,
        handleChange: handleNewReleaseLicenseTextChange,
      },
      {
        value: newRelease.security.toString(),
        checked: newRelease.security,
        label: 'Security Issue',
        fieldType: 'checkboxField',
        required: false,
        handleChange: handleNewReleaseSecurityChange,
      },
    ];
    return fields;
  };

  if (adminLoading || searchProductsLoading) return (<BarLoader />);
  if (adminError || searchProductsError || releasesError || searchEnabledProductsError) return (<NoMatch />);

  return <>
    {isAuthenticated
      ? (
        <>
          {adminLoading ? (
            <BarLoader />
          ) : (
            <AdminAccess
              role="admin"
              data={data?.customerRoles}
              yes={() => (
                <ReleasesContentContainer>
                  <ReleaseFilter
                    styleClass='filterContainer'
                    selectedProduct={filterProduct}
                    selectedVersion={version}
                    customerProducts={productsByCustomer}
                    customerVersions={versionsByProduct}
                    productFilter={handleProductFilter}
                    versionFilter={handleVersionFilter}
                    releaseDateFilter={{
                      handleInitialDateChange: handleInitialDateChange,
                      handleEndDateChange: handleEndDateChange,
                      selectedInitialDate: selectedInitialDate,
                      selectedEndDate: selectedEndDate
                    }}
                  />
                  <div>
                    <InfoBar
                      isOpen={snackbar.isOpen}
                      message={snackbar.message}
                      closeSnackbar={closeSnackbar}
                      severity={snackbar.severity}
                    />
                    <GenericDrawer
                      formFields={createReleaseFields()}
                      drawerTitle="Add Release"
                      isDrawerOpen={isAddReleaseDrawerOpen}
                      closeDrawerCallback={setIsAddReleaseDrawerOpen}
                      submitForm={createRelease}
                    />
                    <PrimaryButton
                      icon={faPlus}
                      label="Add Release"
                      onClick={openAddReleaseDrawer}
                      type="button"
                    />
                    <form onSubmit={handleFilterSubmit} autoComplete="off">
                      <TextField
                        variant="standard"
                        id="standard-search"
                        label="Search for..."
                        type="search"
                        value={inputString}
                        onChange={handleFilterChange} />
                      <PrimaryButton label="Search" type="submit" />
                      <SecondaryButton label="Reset" type="reset" onClick={resetTable} />
                    </form>
                    <div>
                      <ReleasesTable
                        releases={releases}
                        toggleStatus={toggleStatus}
                        loading={releasesLoading}
                      />
                    </div>
                  </div>
                </ReleasesContentContainer>
              )}
              no={() => <NoMatch />}
            />
          )
          }
        </>
      ) : null}
  </>;
};

export default Releases;
