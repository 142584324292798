import React from 'react';
import { Dialog, DialogContent, DialogActions, DialogTitle } from '@mui/material';

import DangerButton from '../buttons/danger/DangerButton';
import SecondaryButton from '../buttons/secondary/SecondaryButton';

type ConfirmDeleteDialogProps = {
    isOpen: boolean,
    setOpen: (isOpen: boolean) => void,
    title: string,
    message: string,
    handleCancel: () => void,
    handleOk: (event: any) => void
};

const ConfirmDeleteDialog: React.FC<ConfirmDeleteDialogProps> = ({
  isOpen, setOpen, title, message, handleCancel, handleOk,
}: ConfirmDeleteDialogProps) => (
  <Dialog
    onClose={
      (event, reason) => {
        if(reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
          setOpen(false);
        }
      }
    }
    maxWidth="xs"
    aria-labelledby="confirmation-dialog-title"
    open={isOpen}
  >
    <DialogTitle id="confirmation-dialog-title">{title}</DialogTitle>
    <DialogContent dividers>
      {message}
    </DialogContent>
    <DialogActions>
      <SecondaryButton onClick={handleCancel} label="Cancel" />
      <DangerButton onClick={handleOk} label="Delete" />
    </DialogActions>
  </Dialog>
);

export default ConfirmDeleteDialog;
