import React from 'react';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { Snackbar } from '@mui/material';

type SnackbarProps = {
    isOpen: boolean,
    message: string,
    closeSnackbar: (event: React.SyntheticEvent | Event, reason?: string) => void,
    severity: 'error' | 'warning' | 'info' | 'success'
};

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const InfoBar: React.FC<SnackbarProps> = ({
  isOpen, closeSnackbar, message, severity,
}: SnackbarProps) => (
  <Snackbar
    open={isOpen}
    autoHideDuration={3000}
    onClose={closeSnackbar}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
  >
    <Alert onClose={closeSnackbar} severity={severity} sx={{ width: '100%' }}>
      {message}
    </Alert>
  </Snackbar>
);

export default InfoBar;
