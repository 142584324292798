import React from 'react';
import { FormControlLabel, Checkbox } from '@mui/material';

type CheckboxFieldProps = {
  value: string,
  checked: boolean,
  label: string,
  required: boolean,
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
};

const GenericCheckboxField: React.FC<CheckboxFieldProps> = (props: CheckboxFieldProps) => {
  function camelize(str: string) {
    return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    }).replace(/\s+/g, '');
  }

  return (
    <>
      <FormControlLabel
        control={(
          <Checkbox
            color="default"
            value={props.value}
            checked={props.checked}
            required={props.required}
            name="checkbox"
            id={`${camelize(props.label)}-checkbox`}
            onChange={props.handleChange}
          />
        )}
        label={props.label}
      />
    </>
  );
};

export default GenericCheckboxField;
