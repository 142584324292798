import React, { useEffect } from 'react';
import { Auth0ContextInterface, useAuth0 } from '@auth0/auth0-react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';

import { faPlus, faUser, faFileImport } from '@fortawesome/free-solid-svg-icons';
import AdminAccess from '../../../AdminAccess';
import BarLoader from '../../../widgets/loaders/barLoader/BarLoader';
import NoMatch from '../../../layout/nomatch/NoMatch';
import ReleaseDetails from '../releaseDetails/ReleaseDetails';
import ReleaseLicenseInfo from '../releaseLicenseInfo/ReleaseLicenseInfo';
import ReleaseCustomersTable from '../releaseCustomersTable/ReleaseCustomersTable';
import ReleasesPageTitle from '../releasesPageTitle/ReleasesPageTitle';
import ReleaseFilesTable from '../releaseFilesTable/ReleaseFilesTable';
import PrimaryButton from '../../../widgets/buttons/primary/PrimaryButton';
import GenericDrawer from '../../../layout/drawers/GenericDrawer';
import ConfirmDialog from '../../../widgets/confirmDialog/ConfirmDialog';
import InfoBar from '../../../widgets/infobar/InfoBar';
import { defaultSnackbarState, SnackbarType } from '../../../widgets/infobar/types';
import { FormField } from '../../../../types/types';
import {
  defaultRelease,
  ReleaseCustomerType,
  ReleaseFileType,
  ReleaseType,
} from '../../types';

import { GET_USER_ROLES } from '../../../../client/queries/users';
import {
  CREATE_RELEASE_FILE,
  DELETE_RELEASE_FILE,
  ENABLE_RELEASE_FOR_MANY_CUSTOMERS,
  GET_FILES_BY_RELEASE_ID,
  GET_RELEASE_BY_ID,
  GET_RELEASE_OF_SAME_PRODUCT,
  TOGGLE_RELEASE_CUSTOMER_ACCESS,
  UPDATE_RELEASE,
  UPDATE_RELEASE_FILE,
  GET_CUSTOMERS_OF_RELEASE,
} from '../../../../client/queries/releases';
import { SEARCH_PAGINATED_CUSTOMERS_BY_RELEASE_ID } from '../../../../client/queries/customers';
import { AuthRoleType } from "../../../layout/sidebar/Sidebar";
import ReleasesSearchCustomerDrawer from '../../../layout/drawers/searchCustomers/ReleasesSearchCustomerDrawer';

import styles from './ReleasesPage.module.css';
import { TextField } from "@mui/material";
import SecondaryButton from '../../../widgets/buttons/secondary/SecondaryButton';


const ReleasesPage: React.FC = () => {
  // Collect user information
  const { isAuthenticated, user }: Auth0ContextInterface = useAuth0();
  const user_id = user?.sub;

  // Constants
  const { id } = useParams();
  const defaultFile = {
    id: '',
    checksum: '',
    description: '',
    releaseId: 0,
    filePath: '',
  };

  // types
  type PaginatedCustomersByReleaseIdQuery = {
    releaseId: any,
    search: string,
    page: number,
    perPage: number
  }

  // State definitions
  const [isEditDetailsDrawerOpen, setIsEditDetailsDrawerOpen] = React.useState<boolean>(false);
  const [isEditLicenseDrawerOpen, setIsEditLicenseDrawerOpen] = React.useState<boolean>(false);
  const [isAccessReleaseCustomerImportDrawerOpen, setIsAccessReleaseCustomerImportDrawerOpen] = React.useState(false);
  const [updatingRelease, setUpdatingRelease] = React.useState<ReleaseType>(defaultRelease);
  const [allReleases, setAllReleases] = React.useState<[ReleaseType]>([defaultRelease]);
  const [release, setRelease] = React.useState<ReleaseType>(defaultRelease);
  const [snackbar, setSnackbar] = React.useState<SnackbarType>(defaultSnackbarState);
  const [page, setPage] = React.useState<number>(0);
  const PER_PAGE = 10;
  const [totalCustomers, setTotalCustomers] = React.useState<number>(0);
  const [enabledCustomers, setEnabledCustomers] = React.useState<ReleaseCustomerType[]>([]);
  const [isSearchDrawerOpen, setIsSearchDrawerOpen] = React.useState<boolean>(false);
  const [inputString, setInputString] = React.useState<string>('');
  const [paginatedCustomerByReleaseIdQuery, setPaginatedCustomerByReleaseIdQuery] = React.useState<PaginatedCustomersByReleaseIdQuery>({
    releaseId: id,
    search: '',
    page: 0,
    perPage: PER_PAGE
  });

  // Release files information
  const [isAddFilesDrawerOpen, setIsAddFilesDrawerOpen] = React.useState(false);
  const [isUpdateFilesDrawerOpen, setIsUpdateFilesDrawerOpen] = React.useState(false);
  const [newFile, setNewFile] = React.useState<ReleaseFileType>(defaultFile);
  const [existingFile, setExistingFile] = React.useState<ReleaseFileType>(defaultFile);
  const [fileToDeleteId, setFileToDeleteId] = React.useState<number>(0);
  const [confirmDialog, setConfirmDialog] = React.useState<boolean>(false);

  // Queries
  const { loading: rolesLoading, error: rolesError, data: roles } = useQuery<{ customerRoles: AuthRoleType[] }>(GET_USER_ROLES, {
    variables: { id: user_id },
  });

  const {
    loading: releaseLoading,
    error: releaseError,
    data: releaseData,
    refetch: releaseRefetch,
  } = useQuery<{ releaseById: ReleaseType }>(GET_RELEASE_BY_ID, {
    variables: {
      id,
    },
    fetchPolicy: 'network-only',
    onCompleted: data => {
      setUpdatingRelease(data.releaseById);
      getReleaseOfSameProduct({ variables: { product: data.releaseById.productId.name, excludeId: id } });
    },
  });

  const [getReleaseOfSameProduct, {
    loading: releaseOfSameProductLoading,
    error: releaseOfSameProductError,
  }] = useLazyQuery<{ searchReleases: [ReleaseType] }>(GET_RELEASE_OF_SAME_PRODUCT, {
    onCompleted: data => {
      setAllReleases(data.searchReleases)
    },
  });

  const [getReleaseCustomers] = useLazyQuery<{ releaseById: ReleaseType }>(GET_CUSTOMERS_OF_RELEASE, {
    onCompleted: data => {
      handleCustomerImport(data.releaseById.releaseCustomers.map(releaseCustomers => releaseCustomers.customer.custId));
    },
    onError: error => setErrorMessage("There has been an error retrieving customers. Error: " + error.message)
  });

  const { loading: filesLoading, error: filesError, data: files } = useQuery(GET_FILES_BY_RELEASE_ID, {
    variables: { releaseId: id },
  });


  const [searchPaginatedCustomersByReleaseId, {
    loading: searchPaginatedCustomersLoading,
    error: searchPaginatedCustomersError,
    refetch: tableRefetch,
  }] = useLazyQuery(SEARCH_PAGINATED_CUSTOMERS_BY_RELEASE_ID, {
    onCompleted: (paginatedCustomers) => {
      const total = paginatedCustomers.searchPaginatedCustomersByReleaseId.total === null ? 0 : paginatedCustomers.searchPaginatedCustomersByReleaseId.total;
      const customers = paginatedCustomers.searchPaginatedCustomersByReleaseId.customers === null ? [] : paginatedCustomers.searchPaginatedCustomersByReleaseId.customers;
      setTotalCustomers(total);
      setEnabledCustomers(customers);
    },
    notifyOnNetworkStatusChange: true,
  });


  useEffect(() => {
      searchPaginatedCustomersByReleaseId({
        variables: paginatedCustomerByReleaseIdQuery
      });
  }, [searchPaginatedCustomersByReleaseId, paginatedCustomerByReleaseIdQuery]);

  const thisRelease: ReleaseType = releaseData ? releaseData.releaseById : defaultRelease;

  // Mutations
  const [toggleReleaseCustomerAccess] = useMutation<{ toggleReleaseCustomer: { releaseCustomer: ReleaseCustomerType } }>(TOGGLE_RELEASE_CUSTOMER_ACCESS, {
    refetchQueries: [{
      query: GET_RELEASE_BY_ID,
      variables: { id },
    }],
  });
  const [updateRelease] = useMutation(UPDATE_RELEASE, {
    refetchQueries: [{
      query: GET_RELEASE_BY_ID,
      variables: { id },
    }],
    onError: () => setErrorMessage('There was a problem editing the release. Please try again'),
    onCompleted: () => setSuccessMessage('Release saved')
  });

  const [importCustomers] = useMutation<{ enableReleaseForManyCustomers: { releaseCustomer: ReleaseCustomerType } }>(ENABLE_RELEASE_FOR_MANY_CUSTOMERS, {
    refetchQueries: [{
      query: GET_RELEASE_BY_ID,
      variables: { id },
    }],
  });

  const [createReleaseFile] = useMutation(CREATE_RELEASE_FILE, {
    refetchQueries: [{
      query: GET_FILES_BY_RELEASE_ID,
      variables: { releaseId: id },
    }],
    onError: () => setErrorMessage("Problem saving release file. Please try again."),
    onCompleted: () => setSuccessMessage("Release file saved!")
  });

  const [updateReleaseFile] = useMutation(UPDATE_RELEASE_FILE, {
    refetchQueries: [{
      query: GET_FILES_BY_RELEASE_ID,
      variables: { releaseId: id },
    }],
    onError: () => setErrorMessage('There was a problem editing the release file. Please try again'),
    onCompleted: () => setSuccessMessage('Release file saved')
  });

  const [deleteReleaseFile] = useMutation(DELETE_RELEASE_FILE, {
    refetchQueries: [{
      query: GET_FILES_BY_RELEASE_ID,
      variables: { releaseId: id },
    }],
  });

  // Helpers
  const openReleaseDetailsDrawer = () => {
    setIsEditDetailsDrawerOpen(true);
  };

  const openReleaseLicenseDrawer = () => {
    setIsEditLicenseDrawerOpen(true);
  };

  const openAddReleaseFileDrawer = () => {
    setIsAddFilesDrawerOpen(true);
  };

  const openSearchDrawer = () => {
    setIsSearchDrawerOpen(true);
  };

  const openAccessReleaseCustomerImportDrawer = () => {
    setIsAccessReleaseCustomerImportDrawerOpen(true);
  };

  const openUpdateFileDrawer = (event: React.MouseEvent<HTMLButtonElement>) => {
    files.filesByReleaseId.forEach((file: any) => {
      if (file.id === event.currentTarget.value) {
        setExistingFile(file);
      }
    });
    setIsUpdateFilesDrawerOpen(true);
  };

  const openDeleteFileDialog = (event: React.MouseEvent<HTMLButtonElement>) => {
    files.filesByReleaseId.forEach((file: any) => {
      if (file.id === event.currentTarget.value) {
        setFileToDeleteId(file.id);
      }
    });
    setConfirmDialog(true);
  };

  const handleConfirmDialogCancel = () => {
    setConfirmDialog(false);
  };

  const handleConfirmDialogOk = async () => {
    try {
      await deleteReleaseFile({
        variables: {
          id: fileToDeleteId,
        },
      });
      setFileToDeleteId(0);
      setSnackbar({ isOpen: true, message: 'File was successfully deleted!', severity: 'success' });
    } catch (e) {
      setSnackbar({ isOpen: true, message: 'Unable to delete file.', severity: 'error' });
    }
    setConfirmDialog(false);
  };

  const closeSnackbar = () => {
    setSnackbar({ ...snackbar, isOpen: false });
  };

  const setErrorMessage = (message: string) => {
    setSnackbar({ isOpen: true, message, severity: 'error' });
  };

  const setSuccessMessage = (message: string) => {
    setSnackbar({ isOpen: true, message, severity: 'success' });
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUpdatingRelease({ ...updatingRelease, name: event.currentTarget.value });
  };

  const handleDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUpdatingRelease({ ...updatingRelease, description: event.currentTarget.value });
  };

  const handleReleaseDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUpdatingRelease({ ...updatingRelease, releaseDate: event.currentTarget.value });
  };

  const handleIsArchivedChange = () => {
    setUpdatingRelease((prevState) => ({ ...updatingRelease, isArchived: !prevState.isArchived }));
  };

  const handleSecurityChange = () => {
    setUpdatingRelease((prevState) => ({ ...updatingRelease, security: !prevState.security }));
  };

  const handleLicenseTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUpdatingRelease({ ...updatingRelease, licenseTitle: event.currentTarget.value });
  };

  const handleLicenseTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUpdatingRelease({ ...updatingRelease, licenseText: event.currentTarget.value });
  };

  const handleNewFileChecksumChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewFile({ ...newFile, checksum: event.currentTarget.value });
  };

  const handleNewFileDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewFile({ ...newFile, description: event.currentTarget.value });
  };

  const handleNewFilePathChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewFile({ ...newFile, filePath: event.currentTarget.value });
  };

  const handleExistingFileChecksumChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setExistingFile({ ...existingFile, checksum: event.currentTarget.value });
  };

  const handleExistingFileDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setExistingFile({ ...existingFile, description: event.currentTarget.value });
  };

  const handleExistingFilePathChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setExistingFile({ ...existingFile, filePath: event.currentTarget.value });
  };

  const handleAutocompleteReleaseNameChange = (event: React.ChangeEvent<HTMLInputElement>, value: ReleaseType) => {
    if (value) {
      allReleases.forEach((release: ReleaseType) => {
        if (value.id === release.id) setRelease(release);
      });
    } else {
      setRelease(defaultRelease);
    }
  };

  const saveReleaseDetails = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    await updateRelease({
      variables: {
        id: updatingRelease.id,
        name: updatingRelease.name,
        description: updatingRelease.description,
        releaseDate: updatingRelease.releaseDate.substring(0, updatingRelease.releaseDate.indexOf('T')),
        isArchived: updatingRelease.isArchived,
        security: updatingRelease.security,
      },
    });
    setIsEditDetailsDrawerOpen(false);
  };

  const saveReleaseLicense = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    await updateRelease({
      variables: {
        id: updatingRelease.id,
        licenseTitle: updatingRelease.licenseTitle,
        licenseText: updatingRelease.licenseText,
      },
    });
    setIsEditLicenseDrawerOpen(false);
  };

  const saveNewReleaseFile = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    await createReleaseFile({
      variables: {
        checksum: newFile.checksum,
        releaseId: id,
        description: newFile.description,
        filePath: newFile.filePath,
      },
    });
    setNewFile(defaultFile);
    setIsAddFilesDrawerOpen(false);
  };

  const handleCustomerImport = async (customerIdArray: number[]): Promise<void> => {
    try {
      if (customerIdArray.length > 0) {
        const { data } = await importCustomers({
          variables: {
            customerId: customerIdArray,
            releaseId: id,
          },
        });
        if (data) {
          const { disabled } = data.enableReleaseForManyCustomers.releaseCustomer;
          const message = (disabled) ? 'Release Customer Access disabled!' : 'Release Customer Access enabled!';
          const severity = (disabled) ? 'warning' : 'success';
          setSnackbar({ isOpen: true, message, severity });
          setIsSearchDrawerOpen(false)
          tableRefetch && tableRefetch()
        }
      }
      else {
        setSnackbar({ isOpen: true, message: 'Release has no customers to import.', severity: 'info' });
        setIsSearchDrawerOpen(false)
      }
    } catch (e) {
      setSnackbar({ isOpen: true, message: 'Problem importing customers. Please try again.', severity: 'error' });
      setIsSearchDrawerOpen(false)
    }
  }

  const saveNewReleaseCustomerImport = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    await getReleaseCustomers({variables: {id: release.id}});
    setIsAccessReleaseCustomerImportDrawerOpen(false);
  };


  const saveExistingReleaseFile = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    await updateReleaseFile({
      variables: {
        id: existingFile.id,
        checksum: existingFile.checksum,
        releaseId: id,
        description: existingFile.description,
        filePath: existingFile.filePath,
      },
    });
    setExistingFile(defaultFile);
    setIsUpdateFilesDrawerOpen(false);
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
    setPage(newPage);
    setPaginatedCustomerByReleaseIdQuery((prev) => ({ ...prev, page: newPage }));
  };

  // Render helpers
  if (rolesLoading || releaseLoading || releaseOfSameProductLoading || filesLoading || !releaseData) return (<BarLoader />);
  if (rolesError || releaseError || releaseOfSameProductError || filesError) return (<NoMatch />);

  const toggleAccess = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const { data } = await toggleReleaseCustomerAccess({
        variables: {
          customerId: event.target.value,
          releaseId: id,
        },
      });
      if (data) {
        const { disabled } = data.toggleReleaseCustomer.releaseCustomer;
        await releaseRefetch();
        const message = (disabled) ? 'Release Customer Access Disabled!' : 'Release Customer Access Enabled!';
        const severity = (disabled) ? 'warning' : 'success';
        setSnackbar({ isOpen: true, message, severity });
      }
    } catch (e) {
      setSnackbar({ isOpen: true, message: 'Problem updating release. Please try again.', severity: 'error' });
    }
  };

  // Populate forms
  const editDetailsFormFields: FormField[] = [
    {
      value: updatingRelease.name,
      label: 'Name',
      fieldType: 'textField',
      required: true,
      handleChange: handleNameChange,
    },
    {
      value: updatingRelease.description,
      label: 'Description',
      fieldType: 'textField',
      required: true,
      handleChange: handleDescriptionChange,
    },
    {
      value: updatingRelease.releaseDate,
      label: 'Release Date',
      fieldType: 'textField',
      required: true,
      handleChange: handleReleaseDateChange,
    },
    {
      value: updatingRelease.isArchived.toString(),
      label: 'Enabled',
      fieldType: 'checkboxField',
      required: false,
      checked: !updatingRelease.isArchived,
      handleChange: handleIsArchivedChange,
    },
    {
      value: updatingRelease.security.toString(),
      label: 'Security Issue',
      fieldType: 'checkboxField',
      required: false,
      checked: updatingRelease.security,
      handleChange: handleSecurityChange,
    },
  ];

  const editLicenseFormFields: FormField[] = [
    {
      value: updatingRelease.licenseTitle,
      label: 'License Title',
      fieldType: 'textField',
      required: true,
      handleChange: handleLicenseTitleChange,
    },
    {
      value: updatingRelease.licenseText,
      label: 'License Text',
      fieldType: 'textArea',
      required: true,
      handleChange: handleLicenseTextChange,
    },
  ];

  const newFileFields: FormField[] = [
    {
      value: newFile.checksum,
      label: 'Checksum',
      fieldType: 'textField',
      required: true,
      handleChange: handleNewFileChecksumChange,
    },
    {
      value: newFile.description,
      label: 'Description',
      fieldType: 'textArea',
      required: true,
      handleChange: handleNewFileDescriptionChange,
    },
    {
      value: newFile.filePath,
      label: 'File Path',
      fieldType: 'textField',
      required: true,
      handleChange: handleNewFilePathChange,
    },
  ];

  const existingFileFields: FormField[] = [
    {
      value: existingFile.checksum,
      label: 'Checksum',
      fieldType: 'textField',
      required: true,
      handleChange: handleExistingFileChecksumChange,
    },
    {
      value: existingFile.description,
      label: 'Description',
      fieldType: 'textArea',
      required: true,
      handleChange: handleExistingFileDescriptionChange,
    },
    {
      value: existingFile.filePath,
      label: 'File Path',
      fieldType: 'textField',
      required: true,
      handleChange: handleExistingFilePathChange,
    },
  ];

  const enableImportCustomerReleaseFields: FormField[] = [
    {
      value: release.name,
      label: 'Import from Release',
      fieldType: 'autoCompleteField',
      selectedOption: release,
      autoCompleteOptions: allReleases,
      required: true,
      handleChange: () => null,
      handleAutocomplete: handleAutocompleteReleaseNameChange,
      optionLabel: (release: ReleaseType) => {
        if (release.id === '') return '';
        return `${release.productId.name} (${release.name})`;
      },
    },
  ];

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setInputString(event.currentTarget.value);
  }

  const handleFilterSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setPage(0);
    setPaginatedCustomerByReleaseIdQuery((prev) => ({ ...prev, page: 0, search: inputString }));
  }

  const resetReleaseCustomerTable = () => {
    setInputString('');
    setPage(0);
    setPaginatedCustomerByReleaseIdQuery((prev) => ({ ...prev, page: 0, search: '' }));
  }

  return <>
    {isAuthenticated
      ? (
        <AdminAccess
          role="admin"
          data={roles?.customerRoles}
          yes={() => (
            <div className={styles.release_container}>
              <InfoBar
                isOpen={snackbar.isOpen}
                message={snackbar.message}
                closeSnackbar={closeSnackbar}
                severity={snackbar.severity}
              />
              <ConfirmDialog
                isOpen={confirmDialog}
                setOpen={setConfirmDialog}
                title="Delete this file?"
                message="Once deleted, this file will no longer be associated with this release."
                handleCancel={handleConfirmDialogCancel}
                handleOk={handleConfirmDialogOk}
              />
              <ReleasesPageTitle
                productName={thisRelease.productId.name}
                releaseName={thisRelease.name}
              />
              <ReleaseDetails
                description={thisRelease.description}
                releaseDate={thisRelease.releaseDate}
                isArchived={thisRelease.isArchived}
                security={thisRelease.security}
                onClick={openReleaseDetailsDrawer}
              />
              <ReleaseLicenseInfo
                licenseTitle={thisRelease.licenseTitle}
                licenseText={thisRelease.licenseText}
                onClick={openReleaseLicenseDrawer}
              />
              <div className={styles.release_customer}>
                <div className={styles.release_customer_actions}>
                  <PrimaryButton
                    icon={faUser}
                    label="Add Customer"
                    className={styles.add_button}
                    onClick={openSearchDrawer}
                    type="submit"
                  />

                  <PrimaryButton
                    icon={faFileImport}
                    label="Import Customer"
                    className={styles.add_button}
                    onClick={openAccessReleaseCustomerImportDrawer}
                    type="button"
                  />
                </div>
                <form onSubmit={handleFilterSubmit} autoComplete="off">
                  <TextField
                    variant="standard"
                    id="standard-search"
                    label="Search for..."
                    type="search"
                    value={inputString}
                    onChange={handleFilterChange} />
                  <PrimaryButton label="Search" type="submit" />
                  <SecondaryButton label="Reset" type="reset" onClick={resetReleaseCustomerTable} />
                </form>
                {(searchPaginatedCustomersLoading ?
                  <BarLoader /> :
                  searchPaginatedCustomersError ?
                    <NoMatch /> :
                    <ReleaseCustomersTable
                      releaseCustomers={enabledCustomers}
                      handleChangePage={handleChangePage}
                      toggleAccess={toggleAccess}
                      rowsPerPage={PER_PAGE}
                      page={page}
                      total={totalCustomers}
                    />
                )}
              </div>
              <GenericDrawer
                formFields={editDetailsFormFields}
                drawerTitle="Edit Release Details"
                isDrawerOpen={isEditDetailsDrawerOpen}
                closeDrawerCallback={setIsEditDetailsDrawerOpen}
                submitForm={saveReleaseDetails}
              />
              <GenericDrawer
                formFields={editLicenseFormFields}
                drawerTitle="Edit License Information"
                isDrawerOpen={isEditLicenseDrawerOpen}
                closeDrawerCallback={setIsEditLicenseDrawerOpen}
                submitForm={saveReleaseLicense}
              />
              <div className={styles.release_files}>
                <PrimaryButton
                  icon={faPlus}
                  label="Add File"
                  className={styles.add_button}
                  onClick={openAddReleaseFileDrawer}
                  type="button"
                />
                <ReleaseFilesTable
                  releaseFiles={files.filesByReleaseId}
                  editFile={openUpdateFileDrawer}
                  deleteFile={openDeleteFileDialog}
                />
              </div>
              <GenericDrawer
                formFields={newFileFields}
                drawerTitle="Add Release File"
                isDrawerOpen={isAddFilesDrawerOpen}
                closeDrawerCallback={setIsAddFilesDrawerOpen}
                submitForm={saveNewReleaseFile}
              />
              <GenericDrawer
                formFields={existingFileFields}
                drawerTitle="Update Release File"
                isDrawerOpen={isUpdateFilesDrawerOpen}
                closeDrawerCallback={setIsUpdateFilesDrawerOpen}
                submitForm={saveExistingReleaseFile}
              />
              <ReleasesSearchCustomerDrawer
                drawerTitle="Find Customer"
                isDrawerOpen={isSearchDrawerOpen}
                closeDrawerCallback={setIsSearchDrawerOpen}
                handleCustomerImport={handleCustomerImport}
              />
              <GenericDrawer
                formFields={enableImportCustomerReleaseFields}
                drawerTitle="Import Customers from Other Release"
                isDrawerOpen={isAccessReleaseCustomerImportDrawerOpen}
                closeDrawerCallback={setIsAccessReleaseCustomerImportDrawerOpen}
                submitForm={saveNewReleaseCustomerImport}
              />
            </div>
          )}
          no={() => <NoMatch />}
        />
      )
      : null}
  </>;
};

export default ReleasesPage;
