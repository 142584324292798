import React from 'react';
import { Auth0ContextInterface, useAuth0 } from '@auth0/auth0-react';
import { useQuery } from '@apollo/client';

import BarLoader from '../../widgets/loaders/barLoader/BarLoader';
import NoMatch from '../../layout/nomatch/NoMatch';
import LicenseData from '../LicenseData/LicenseData';
import ReportingAccess from '../../ReportingAccess';
import { GET_USER_ROLES } from '../../../client/queries/users';
import {
  ALL_LICENSE_DATA,
} from '../../../client/queries/hubLicenseData';
import {AuthRoleType} from "../../layout/sidebar/Sidebar";

const HubLicenseReport: React.FC = () => {
  const { isAuthenticated, user }: Auth0ContextInterface = useAuth0();
  const user_id = user?.sub;

  // Queries
  const { loading: roleLoading, error: roleError, data: role } = useQuery<{ customerRoles: AuthRoleType[] }>(GET_USER_ROLES, {
    variables: { id: user_id },
  });
  const {
    loading: hubLicenseDataLoading,
    error: hubLicenseDataError,
    data: hubLicenseData,
  } = useQuery(ALL_LICENSE_DATA);

  if (roleLoading|| hubLicenseDataLoading) return <BarLoader />;

  if (roleError || hubLicenseDataError) return <NoMatch />;

  return (
    <>
      {isAuthenticated
        ? (
          <ReportingAccess
            data={role?.customerRoles}
            yes={() => (
              <div>
                <LicenseData
                  hubLicenseData={hubLicenseData.allLicenseData}
                />
              </div>
            )}
            no={() => <NoMatch />}
          />
        )
        : null}
    </>
  );
};

export default HubLicenseReport;
