import React from 'react';

import HubLicenseDataTable from '../hubLicenseDataTable/HubLicenseDataTable'
import { HubLicenseDataType } from '../types';

import styles from './LicenseData.module.css';

type LicenseDataProps = {
  hubLicenseData: HubLicenseDataType[]
};

const LicenseData: React.FC<LicenseDataProps> = (props: LicenseDataProps) => (
  <div className={styles.license_data_container}>
    <HubLicenseDataTable hubLicenseData={props.hubLicenseData} />
  </div>
);

export default LicenseData;
