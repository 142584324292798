import { styled } from '@mui/material/styles';

export const AccountsPageContentContainer = styled('div')`
  background-color: #f6f6f6;

  .add_button {
    align-self: center;
    justify-self: left;
  }
`

export const AccountsPageLayout = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto auto auto;
  gap: 25px;

  .accountTitle {
    grid-column: 1 / span 2;
    grid-row: 1;
    font-weight: 400;
    margin: 0;
  }

  .accountsSection {
    display: flex;
    flex-direction: column;
    grid-column: 1 / span 2;
    grid-row: 3;

    .actions {
      align-items: flex-start;
      margin: 10px 0;
    }

    .accountCustomers {
      width: 100%;
    }
  }

  .accountLicenses {
    grid-column: 1 / span 2;
    grid-row: 4;
  }
`
