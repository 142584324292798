import { gql } from '@apollo/client';

export const ALL_LICENSE_DATA = gql`
  query AllLicenseData {
      allLicenseData {
          licenseId
          name
          description
          edition
          licenseVersion
          licenseType
          expiryDate
          supportDate
          createdAt
      }
  }
`;