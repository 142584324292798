import { Table, TableBody, TableHead } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledTable = styled(Table)`
  background-color: #fff;
  overflow: hidden;
`

export const StyledTableHead = styled(TableHead)`
  th {
    color: #333;
    font-size: 16px;
    font-weight: 600;
  }
`

export const StyledTableBody = styled(TableBody)`
  background-color: #fff;
  border: 1px solid #fff;


  tr {
    th, td {
      font-size: 15px;
      color: #666;

      .MuiTableSortLabel-active {
        color: #666;
      }
    }

    td svg {
      visibility: hidden;
    }

    :hover svg {
      visibility: visible;
    }

    a {
      color: #82bc00;
      text-decoration: none;
      cursor: pointer;
      font-size: 15px;

      :hover {
        color: #9bca37;
        text-decoration: underline;
      }
    }
  }
`
