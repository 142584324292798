import React from 'react';
import MaterialTable from '@material-table/core';
import { ExportCsv, ExportPdf } from '@material-table/exporters';
import { TasktopTableWrapper } from './TasktopTable.styles';

type TasktoptableProps = {
    title: string,
    columns: any[],
    data: any[],
    export?: boolean,
    actions?: JSX.Element,
};

const TasktopTable: React.FC<TasktoptableProps> = (props: TasktoptableProps) => (
  <TasktopTableWrapper>
    <MaterialTable
      title={props.title}
      columns={props.columns}
      data={props.data}
      options={props.export ? {
          exportMenu: [{
            label: 'Export PDF',
            exportFunc: (cols, datas) => ExportPdf(cols, datas, `${props.title}`)
          }, {
            label: 'Export CSV',
            exportFunc: (cols, datas) => ExportCsv(cols, datas, `${props.title}`)
          }],
          exportAllData: true,
          sorting: true,
        } : undefined}
    />
  </TasktopTableWrapper>
);

export default TasktopTable;
