import { gql } from '@apollo/client';

export const SEARCH_CONTENT_SPACES = gql`
    query SearchContentSpaces($search: String) {
        searchSecureAreas (search: $search) {
            secureAreaId
            name
            path
        }
    }
`;

export const CREATE_CONTENT_SPACE = gql`
    mutation CreateContentSpace($name: String, $path: String) {
        createSecureArea (name: $name, path: $path) {
            secureArea {
                secureAreaId
                name
                path
            }
        }
    }
`;

export const UPDATE_CONTENT_SPACE = gql`
    mutation UpdateContentSpace($secureAreaId: Int, $name: String, $path: String) {
        updateSecureArea (id: $secureAreaId, name: $name, path: $path) {
            secureArea {
                secureAreaId
                name
                path
            }
        }
    }
`;
