import React from 'react';
import { faEdit } from '@fortawesome/free-regular-svg-icons';

import GenericButton from '../generic/GenericButton';
import styles from './EditButton.module.css';

type EditButtonProps = {
    value?: string,
    label?: string,
    title?: string,
    onClick?: (event: any) => void | undefined
};

const EditButton: React.FC<EditButtonProps> = (props: EditButtonProps) => (
  <GenericButton
    className={styles.button}
    icon={faEdit}
    label={props.label}
    title={props.title}
    onClick={props.onClick}
    value={props.value}
  />
);

export default EditButton;
