import React from 'react';
import { Divider } from '@mui/material';
import { Auth0ContextInterface, useAuth0 } from '@auth0/auth0-react';
import { useQuery } from '@apollo/client';

import BarLoader from '../../widgets/loaders/barLoader/BarLoader';
import NoMatch from '../../layout/nomatch/NoMatch';
import ProductDownloads from '../productDownloads/ProductDownloads';
import ReportingAccess from '../../ReportingAccess';
import { GET_USER_ROLES } from '../../../client/queries/users';
import {
  SEARCH_ORACLE_DOWNLOAD_COUNT_BY_RELEASE,
  SEARCH_ORACLE_DOWNLOAD_COUNT_BY_VERSION,
  SEARCH_ORACLE_TOTAL_DOWNLOAD_COUNT,
} from '../../../client/queries/downloads';
import styles from './Reporting.module.css';
import {AuthRoleType} from "../../layout/sidebar/Sidebar";

const HubDownloadReport: React.FC = () => {
  const { isAuthenticated, user }: Auth0ContextInterface = useAuth0();
  const user_id = user?.sub;

  // Queries
  const { loading: roleLoading, error: roleError, data: role } = useQuery<{ customerRoles: AuthRoleType[] }>(GET_USER_ROLES, {
    variables: { id: user_id },
  });

  const {
    loading: syncDownloadsLoading,
    error: syncDownloadsError,
    data: syncDownloadCount,
  } = useQuery(SEARCH_ORACLE_TOTAL_DOWNLOAD_COUNT, {
    variables: {
      search: 'Tasktop Sync',
    },
  });

  const {
    loading: syncDownloadsByVersionLoading,
    error: syncDownloadsByVersionError,
    data: syncDownloadsByVersion,
  } = useQuery(SEARCH_ORACLE_DOWNLOAD_COUNT_BY_VERSION, {
    variables: {
      search: 'Tasktop Sync',
    },
  });

  const {
    loading: syncDownloadsByReleaseLoading,
    error: syncDownloadsByReleaseError,
    data: syncDownloadsByRelease,
  } = useQuery(SEARCH_ORACLE_DOWNLOAD_COUNT_BY_RELEASE, {
    variables: {
      search: 'Tasktop Sync',
    },
  });

  const {
    loading: hubDownloadsLoading,
    error: hubDownloadsError,
    data: hubDownloadCount,
  } = useQuery(SEARCH_ORACLE_TOTAL_DOWNLOAD_COUNT, {
    variables: {
      search: 'Tasktop Integration Hub',
    },
  });

  const {
    loading: hubDownloadsByVersionLoading,
    error: hubDownloadsByVersionError,
    data: hubDownloadsByVersion,
  } = useQuery(SEARCH_ORACLE_DOWNLOAD_COUNT_BY_VERSION, {
    variables: {
      search: 'Tasktop Integration Hub',
    },
  });

  const {
    loading: hubDownloadsByReleaseLoading,
    error: hubDownloadsByReleaseError,
    data: hubDownloadsByRelease,
  } = useQuery(SEARCH_ORACLE_DOWNLOAD_COUNT_BY_RELEASE, {
    variables: {
      search: 'Tasktop Integration Hub',
    },
  });

  if (roleLoading
        || syncDownloadsLoading
        || syncDownloadsByVersionLoading
        || syncDownloadsByReleaseLoading
        || hubDownloadsLoading
        || hubDownloadsByVersionLoading
        || hubDownloadsByReleaseLoading) return <BarLoader />;

  if (roleError
        || syncDownloadsError
        || syncDownloadsByVersionError
        || syncDownloadsByReleaseError
        || hubDownloadsError
        || hubDownloadsByVersionError
        || hubDownloadsByReleaseError) return <NoMatch />;

  return (
    <>
      {isAuthenticated
        ? (
          <ReportingAccess
            data={role?.customerRoles}
            yes={() => (
              <div className={styles.reporting_container}>
                <ProductDownloads
                  title="Hub Download Statistics (Oracle JDK)"
                  downloadCount={hubDownloadCount.searchOracleDownloadCount}
                  downloadCountsByVersion={hubDownloadsByVersion.searchOracleDownloadCountByVersion}
                  downloadCountsByRelease={hubDownloadsByRelease.searchOracleDownloadCountByRelease}
                />
                <Divider />
                <ProductDownloads
                  title="Sync Download Statistics (Oracle JDK)"
                  downloadCount={syncDownloadCount.searchOracleDownloadCount}
                  downloadCountsByVersion={syncDownloadsByVersion.searchOracleDownloadCountByVersion}
                  downloadCountsByRelease={syncDownloadsByRelease.searchOracleDownloadCountByRelease}
                />
              </div>
            )}
            no={() => <NoMatch />}
          />
        )
        : null}
    </>
  );
};

export default HubDownloadReport;
