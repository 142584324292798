import React from 'react';
import classnames from 'classnames';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './DangerButton.module.css';

type DangerButtonProps = {
    className?: string,
    icon?: IconDefinition,
    type?: 'button' | 'submit' | 'reset' | undefined,
    label: string,
    onClick?(event: any): void
};

const DangerButton: React.FC<DangerButtonProps> = ({
  className, icon, label, onClick, type,
}: DangerButtonProps) => {
  const classNames = classnames(styles.btn, className);

  return (
    <button type={type} onClick={onClick} className={classNames}>
      {icon ? (<FontAwesomeIcon icon={icon} />) : null}
      {label}
    </button>
  );
};

export default DangerButton;
