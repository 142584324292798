import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';

import styles from './LogoutButton.module.css';

const LogoutButton: React.FC = () => {
  const { logout } = useAuth0();

  return (
    <div
      className={styles.logout}
      onClick={() => logout({ returnTo: window.location.origin })}
    >
      <FontAwesomeIcon icon={faSignOutAlt} />
    </div>
  );
};

export default LogoutButton;
