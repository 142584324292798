import React from "react";
import { Accordion, AccordionSummary, Typography, AccordionDetails, List, ListItem, ListItemText } from '@mui/material';
import { ExpandMore } from "@mui/icons-material";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { CssTextField } from "./ReleaseFilter.styles";


type FilterProps = {
  styleClass: string,
  selectedProduct: string,
  selectedVersion: string,
  customerProducts: string[],
  customerVersions: string[],
  productFilter: (event: React.MouseEvent<HTMLDivElement>) => void,
  versionFilter: (event: React.MouseEvent<HTMLDivElement>) => void,
  releaseDateFilter: {
    handleInitialDateChange: (date: Date | null) => void,
    handleEndDateChange: (date: Date | null) => void,
    selectedInitialDate: Date | null,
    selectedEndDate: Date | null,
  }
}

const ReleaseFilter: React.FC<FilterProps> = (
  { styleClass, selectedProduct, selectedVersion, customerProducts, customerVersions, productFilter, versionFilter, releaseDateFilter }: FilterProps) => {

  const [expanded, setExpanded] = React.useState(false)

  React.useEffect(() => {
    if (customerVersions.length > 0) {
      setExpanded(true)
    } else {
      setExpanded(false)
    }
  }, [customerVersions])

  const handleClick = () => {
    if (expanded) {
      setExpanded(false)
    } else {
      setExpanded(true)
    }
  }

  return (<div>
    <Accordion defaultExpanded={true} className={styleClass} >
      <AccordionSummary className="title"
        expandIcon={<ExpandMore />}
      >
        <Typography>Product</Typography>
      </AccordionSummary>
      <AccordionDetails className="content">
        <List component="nav">
          {customerProducts.map((product, index) => {
            return <ListItem key={index} button={true} onClick={productFilter} selected={selectedProduct === product}>
              <ListItemText title={product} secondary={product} />
            </ListItem>
          })}
        </List>
      </AccordionDetails>
    </Accordion>
    <Accordion expanded={expanded} disabled={customerVersions.length === 0} >
      <AccordionSummary
        expandIcon={<ExpandMore />}
        onClick={handleClick}
      >
        <Typography>Version</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <List component="nav">
          {customerVersions.map((version, index) => {
            return <ListItem key={index} button={true} onClick={versionFilter} selected={selectedVersion === version}>
              <ListItemText title={version} secondary={version} />
            </ListItem>
          })}
        </List>
      </AccordionDetails>
    </Accordion>
    <Accordion >
      <AccordionSummary
        expandIcon={<ExpandMore />}
        onClick={handleClick}
      >
        <Typography>Release Date</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DesktopDatePicker
            label="Initial Date"
            inputFormat="DD/MM/YYYY"
            value={releaseDateFilter.selectedInitialDate}
            onChange={releaseDateFilter.handleInitialDateChange}
            renderInput={(params) => <CssTextField sx={{mb: 2}} {...params} />}
          />
          <DesktopDatePicker
            label="End Date"
            inputFormat="DD/MM/YYYY"
            value={releaseDateFilter.selectedEndDate}
            onChange={releaseDateFilter.handleEndDateChange}
            renderInput={(params) => <CssTextField {...params} />}
          />
        </LocalizationProvider>
      </AccordionDetails>

    </Accordion>
  </div>);
}

export default ReleaseFilter;


