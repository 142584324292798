import React from 'react';
import classnames from 'classnames';
import styles from './DetailsCard.module.css';

type DetailsCardProps = {
  className: string,
  title: string,
  children: JSX.Element | any,
};

const DetailsCard: React.FC<DetailsCardProps> = (props: DetailsCardProps) => {
  const classname = classnames(styles.card, props.className);

  return (
    <div className={classname}>
      <h3>{props.title}</h3>
      {props.children}
    </div>
  );
};

export default DetailsCard;
