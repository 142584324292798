import React from 'react';
import { faPlayCircle } from '@fortawesome/free-solid-svg-icons';

import GenericButton from '../generic/GenericButton';
import styles from './ReplayButton.module.css';

type ReplayButtonProps = {
    value?: string,
    label?: string,
    title?: string,
    onClick?: (event: any) => void | undefined
};

const ReplayButton: React.FC<ReplayButtonProps> = (props: ReplayButtonProps) => (
  <GenericButton
    className={styles.button}
    icon={faPlayCircle}
    label={props.label}
    title={props.title}
    onClick={props.onClick}
    value={props.value}
  />
);

export default ReplayButton;
