import React from 'react';
import { Auth0ContextInterface, useAuth0 } from '@auth0/auth0-react';
import { useQuery } from '@apollo/client';
import { GET_USER_ROLES } from '../../../client/queries/users';
import { Page } from '../../../pages';
import AdminAccess from '../../AdminAccess';
import ReportingAccess from '../../ReportingAccess';
import { MenuLink, MenuLinkProps } from '../../widgets/links/MenuLink';
import logo from '../../../logo.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle, faPhone, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { SidebarCategory, SidebarContainer, SidebarExternalLinks, SidebarMenu, SidebarTitle } from './Sidebar.styles'

type SidebarProps = {
    pages: Page[]
};

export type AuthRoleType = {
  id: string,
  name: string,
  description: string
}

const Sidebar: React.FC<SidebarProps> = ({ pages }: SidebarProps) => {
  const { user, logout }: Auth0ContextInterface = useAuth0();
  const user_id = user?.sub;
  const [roles, setRoles ] = React.useState<AuthRoleType[]>([]);

  const { data } = useQuery<{ customerRoles: AuthRoleType[] }>(GET_USER_ROLES, {
    variables: { id: user_id },
    onCompleted: (data) => {
      if (data)
        setRoles(data.customerRoles)
    }
  });

  const getMenuLink = (link: MenuLinkProps, index: number) => (
    <MenuLink
      key={index}
      linkName={link.linkName}
      path={link.path}
      icon={link.icon}
      activeClassName={link.activeClassName}
      isExact={link.isExact}
      iconClassName={link.iconClassName}
      access={link.access}
    />
  );

  const getAdminLinks = () => {
    const adminLinks = pages.filter((page) => page.menuLink?.access === 'admin');
    return adminLinks.map((page, index) => (page.menuLink ? getMenuLink(page.menuLink, index) : null));
  };

  const getReportingLinks = () => {
    const reportingLinks = pages.filter((page) => page.menuLink?.access === 'reporting');
    return reportingLinks.map((page, index) => (page.menuLink ? getMenuLink(page.menuLink, index) : null));
  };

  const getAnonymousLinks = () => {
    const anonmyousLinks = pages.filter((page) => page.menuLink?.access === 'all');
    return anonmyousLinks.map((page, index) => (page.menuLink ? getMenuLink(page.menuLink, index) : null));
  };

  function isRole(name: string): boolean {
    let itIs = false;
    if (data) {
      roles.forEach(role => {
        if (role.name === name)
          itIs = true
      })
    }
    return itIs;
  }

  return (
    <SidebarContainer>
      <a className='logo' href="/">
        <img src={logo} id="logo" alt="" />
      </a>
      <SidebarMenu>
        {isRole('admin') && data && (
          <SidebarCategory>
            <SidebarTitle>Admin</SidebarTitle>
            <AdminAccess
              role="admin"
              data={data.customerRoles}
              yes={() => (
                getAdminLinks()
              )}
              no={() => (
                <></>
              )}
            />
          </SidebarCategory>
        )}
        {isRole('reporting') && data && (
          <SidebarCategory>
            <SidebarTitle>Reporting</SidebarTitle>
            <ReportingAccess
              data={data.customerRoles}
              yes={() => (
                getReportingLinks()
              )}
              no={() => (
                <></>
              )}
            />
          </SidebarCategory>
        )}
        {data && (
          <SidebarCategory>
            <SidebarTitle>User</SidebarTitle>
            {getAnonymousLinks()}
            {user && (
              <button onClick={() => logout({ returnTo: window.location.origin })}>
                <span>
                  <FontAwesomeIcon className='icon' icon={faSignOutAlt} />
                  Logout
                </span>
              </button>
            )}
          </SidebarCategory>
        )}
      </SidebarMenu>
      <SidebarExternalLinks>
        <SidebarCategory>
          <a href="https://docs.tasktop.com">
            <span>
              <FontAwesomeIcon className='icon' icon={faQuestionCircle}/>
              Documentation
            </span>
          </a>
          <a href="https://support.tasktop.com">
            <span>
              <FontAwesomeIcon className='icon' icon={faPhone}/>
              Support
            </span>
          </a>
        </SidebarCategory>
      </SidebarExternalLinks>
    </SidebarContainer>
  );
};

export default Sidebar;
