import React from 'react';
import { TableRow, TableCell } from '@mui/material';
import TableWrapper from '../../../layout/table/TableWrapper';
import { TasktopSwitch } from '../../../widgets/switches/Switch.styles';
import styles from './ReleaseCustomersTable.module.css';
import { ReleaseCustomerType } from '../../types';

type ReleaseCustomersTableProps = {
  releaseCustomers: ReleaseCustomerType[],
  handleChangePage: (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => void,
  toggleAccess: (event: React.ChangeEvent<HTMLInputElement>) => void,
  page: number,
  rowsPerPage: number,
  total: number,
};

const headColumns = (
  <>
    <TableCell>Name</TableCell>
    <TableCell>Email</TableCell>
    <TableCell>License Acceptance Date</TableCell>
    <TableCell>Enabled</TableCell>
    <TableCell>Notified</TableCell>
  </>
);

const ReleaseCustomersTable: React.FC<ReleaseCustomersTableProps> = ({
  releaseCustomers, toggleAccess, handleChangePage, page, rowsPerPage, total
}: ReleaseCustomersTableProps) => {

  const tableContents = releaseCustomers.map((releaseCustomer: ReleaseCustomerType) => (
    <TableRow key={releaseCustomer.customer.custId}>
      <TableCell
        component="th"
        scope="row"
      >
        {releaseCustomer.customer.name}
      </TableCell>
      <TableCell>
        {releaseCustomer.customer.email == null ? '' : releaseCustomer.customer.email}
      </TableCell>
      <TableCell>
        {releaseCustomer.licenseAcceptanceDate == null ? ''
          : releaseCustomer.licenseAcceptanceDate.substring(0, releaseCustomer.licenseAcceptanceDate.indexOf('T'))}
      </TableCell>
      <TableCell>
        <TasktopSwitch
          checked={!releaseCustomer.disabled}
          value={releaseCustomer.customer.custId}
          name="isEnable"
          onChange={toggleAccess}
        />
      </TableCell>
      <TableCell>
        {releaseCustomer.notified == null || releaseCustomer.notified === 0 ? 'No' : 'Yes'}
      </TableCell>
    </TableRow>
  ));

  return (
    <div className={styles.release_customers_table}>
      <TableWrapper
        headColumns={headColumns}
        tableContents={tableContents}
        page={page}
        handleChangePage={handleChangePage}
        rowsPerPage={rowsPerPage}
        total={total}
      />
    </div>
  );
};

export default ReleaseCustomersTable;
