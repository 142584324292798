import React from 'react';
import { TextField } from "@mui/material";

type TextFieldProps = {
    name: string,
    label: string,
    disabled?: boolean,
    multiline?: boolean,
    required: boolean,
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
};

const GenericTextField: React.FC<TextFieldProps> = (props: TextFieldProps) => (
  <TextField
    id={"outlined-basic-" + props.label.toLowerCase().replace(/ /g , '-' )}
    variant="outlined"
    label={props.label}
    value={props.name}
    disabled={props.disabled ? props.disabled : false}
    multiline={props.multiline}
    required={props.required}
    onChange={props.handleChange}
  />
)

export default GenericTextField;
