import React from 'react';

import TasktopTable from '../../layout/table/tasktopTable/TasktopTable';
import { HubLicenseDataType } from '../types';

type HubLicenseDataTableProps = {
    hubLicenseData: HubLicenseDataType[],
};

const HubLicenseDataTable: React.FC<HubLicenseDataTableProps> = (props: HubLicenseDataTableProps) => (
  <TasktopTable
    title="Hub License Data"
    columns={[
      { title: 'License ID', field: 'licenseId', defaultSort: 'desc' },
      { title: 'Name', field: 'name' },
      { title: 'Description', field: 'description' },
      { title: 'Edition', field: 'edition' },
      { title: 'License Version', field: 'licenseVersion' },
      { title: 'License Type', field: 'licenseType' },
      { title: 'Expiry Date', field: 'expiryDate' },
      { title: 'Support Date', field: 'supportDate' },
      { title: 'Created At', field: 'createdAt' },
    ]}
    data={
      props.hubLicenseData.map((item) => ({
        id: item.licenseId,
        licenseId: item.licenseId,
        name: item.name,
        description: item.description,
        edition: item.edition,
        licenseType: item.licenseType,
        licenseVersion: item.licenseVersion,
        expiryDate: item.expiryDate,
        supportDate: item.supportDate,
        createdAt: item.createdAt
        }))
      }
    export
  />
);

export default HubLicenseDataTable;
