import React from 'react';
import {AuthRoleType} from "./layout/sidebar/Sidebar";

type AdminAccessProps = {
  role: string,
  data: Array<AuthRoleType> | undefined,
  yes: () => any,
  no: () => any,
}

let isAdmin = false;

const check = (roleName: string, data: Array<AuthRoleType>) => {
  data.forEach((role) => {
    if (role.name === roleName) {
      isAdmin = true;
    }
  });
};

const AdminAccess: React.FC<AdminAccessProps> = ({
  role, data, yes, no,
}: AdminAccessProps) => {
  if (data)
    check(role, data);
  return isAdmin
    ? yes()
    : no();
};

AdminAccess.defaultProps = {
  yes: () => <></>,
  no: () => <></>,
};

export default AdminAccess;
