import React from 'react';

import styles from './ReleasesPageTitle.module.css';

type ReleasesPageTitleProps = {
    productName: string,
    releaseName: string,
};

const ReleasesPageTitle: React.FC<ReleasesPageTitleProps> = (props: ReleasesPageTitleProps) => (
  <div className={styles.release_title}>
    <p>{props.productName}</p>
    <h2>{props.releaseName}</h2>
  </div>
);

export default ReleasesPageTitle;
