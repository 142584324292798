import React from 'react';
import { Auth0ContextInterface, useAuth0 } from '@auth0/auth0-react';
import { useMutation, useQuery } from '@apollo/client';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import { TextField } from "@mui/material";
import { ADD_PRODUCT, SEARCH_PRODUCTS, TOGGLE_PRODUCT_STATUS, UPDATE_PRODUCT } from '../../client/queries/products';

import { GET_USER_ROLES } from '../../client/queries/users';
import GenericDrawer from '../layout/drawers/GenericDrawer';
import ProductsTable from './elements/productsTable/ProductsTable';
import PrimaryButton from '../widgets/buttons/primary/PrimaryButton';
import BarLoader from '../widgets/loaders/barLoader/BarLoader';
import InfoBar from '../widgets/infobar/InfoBar';
import NoMatch from '../layout/nomatch/NoMatch';
import AdminAccess from '../AdminAccess';
import SecondaryButton from '../widgets/buttons/secondary/SecondaryButton';
import { FormField } from '../../types/types';
import {AuthRoleType} from "../layout/sidebar/Sidebar";
import { defaultSnackbarState, SnackbarType } from '../widgets/infobar/types';

type NewProductState = {
    name: string,
    description: string,
};

type UpdateProductState = {
    id: string,
    name: string,
    description: string,
};

const Products: React.FC = () => {
  const { isAuthenticated, user }: Auth0ContextInterface = useAuth0();
  const user_id = user?.sub;
  const { loading, error, data } = useQuery<{ customerRoles: AuthRoleType[] }>(GET_USER_ROLES, {
    variables: { id: user_id },
    notifyOnNetworkStatusChange: true,
  });

  // State definitions
  const [newProduct, setNewProduct] = React.useState<NewProductState>({ name: '', description: '' });
  const [existingProduct, setExistingProduct] = React.useState<UpdateProductState>({
    id: '',
    name: '',
    description: '',
  });
  const [snackbar, setSnackbar] = React.useState<SnackbarType>(defaultSnackbarState);
  const [isAddProductDrawerOpen, setIsAddProductDrawerOpen] = React.useState<boolean>(false);
  const [isUpdateProductDrawerOpen, setIsUpdateProductDrawerOpen] = React.useState<boolean>(false);
  const [inputString, setInputString] = React.useState<string>('');

  // Mutations
  const [toggleProductStatus] = useMutation(TOGGLE_PRODUCT_STATUS);
  const [addProduct] = useMutation(ADD_PRODUCT, {
    refetchQueries: [{
      query: SEARCH_PRODUCTS,
      variables: { search: '' }
    }],
  });
  const [updateExistingProduct] = useMutation(UPDATE_PRODUCT);

  const openAddProductDrawer = () => {
    setIsAddProductDrawerOpen(true);
  };

  const openUpdateProductDrawer = (event: React.MouseEvent<HTMLButtonElement>) => {
    searchProducts.searchProducts.forEach((product: any) => {
      if (product.id === event.currentTarget.value) {
        setExistingProduct({ id: product.id, name: product.name, description: product.description });
      }
    });
    setIsUpdateProductDrawerOpen(true);
  };

  const handleNewProductNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewProduct({ ...newProduct, name: event.currentTarget.value });
  };

  const handleNewProductDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewProduct({ ...newProduct, description: event.currentTarget.value });
  };

  const handleUpdateProductNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setExistingProduct({ ...existingProduct, name: event.currentTarget.value });
  };

  const handleUpdateProductDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setExistingProduct({ ...existingProduct, description: event.currentTarget.value });
  };

  const closeSnackbar = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbar({ ...snackbar, isOpen: false });
  };

  const saveProduct = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      await addProduct({
        variables: {
          name: newProduct.name,
          description: newProduct.description,
        },
      });
      setNewProduct({ name: '', description: '' });
      setSnackbar({ isOpen: true, message: 'Product saved!', severity: 'success' });
      setIsAddProductDrawerOpen(false);
    } catch (e) {
      setSnackbar({ isOpen: true, message: 'Problem saving product. Please try again.', severity: 'error' });
    }
  };

  const updateProduct = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      await updateExistingProduct({
        variables: {
          id: existingProduct.id,
          name: existingProduct.name,
          description: existingProduct.description,
        },
      });
      setSnackbar({ isOpen: true, message: 'Product updated!', severity: 'success' });
      setIsUpdateProductDrawerOpen(false);
    } catch (e) {
      setSnackbar({ isOpen: true, message: 'Problem updating product. Please try again.', severity: 'error' });
    }
  };

  const {
    loading: searchProductsLoading,
    error: searchProductsError,
    data: searchProducts,
    refetch,
  } = useQuery(SEARCH_PRODUCTS, {
    variables: { search: '' },
  });

  if (loading || searchProductsLoading) return (<BarLoader />);
  if (error || searchProductsError) return (<NoMatch />);

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setInputString(event.currentTarget.value);
  };

  const handleFilterSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    refetch({ search: inputString });
  };

  const resetTable = async () => {
    setInputString('');
    refetch({ search: '' });
  };

  const toggleStatus = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const data = await toggleProductStatus({
        variables: {
          id: event.target.value,
          isarchived: !event.target.checked,
        },
      });
      const isArchived = data.data.updateProduct.product.isarchived;
      const message = (isArchived) ? 'Product disabled!' : 'Product enabled!';
      const severity = (isArchived) ? 'warning' : 'success';
      setSnackbar({ isOpen: true, message, severity });
    } catch (e) {
      setSnackbar({ isOpen: true, message: 'Problem updating product. Please try again.', severity: 'error' });
    }
  };

  // Populate forms
  const newProductFields: FormField[] = [
    {
      value: newProduct.name,
      label: 'Product Name',
      fieldType: 'textField',
      required: true,
      handleChange: handleNewProductNameChange,
    },
    {
      value: newProduct.description,
      label: 'Description',
      fieldType: 'textArea',
      required: true,
      handleChange: handleNewProductDescriptionChange,
    },
  ];

  const existingProductFields: FormField[] = [
    {
      value: existingProduct.name,
      label: 'Product Name',
      fieldType: 'textField',
      required: true,
      handleChange: handleUpdateProductNameChange,
    },
    {
      value: existingProduct.description,
      label: 'Description',
      fieldType: 'textArea',
      required: true,
      handleChange: handleUpdateProductDescriptionChange,
    },
  ];

  return <>
    {isAuthenticated
      ? (
        <AdminAccess
          role="admin"
          data={data?.customerRoles}
          yes={() => (
            <div>
              <InfoBar
                isOpen={snackbar.isOpen}
                message={snackbar.message}
                closeSnackbar={closeSnackbar}
                severity={snackbar.severity}
              />
              <GenericDrawer
                formFields={newProductFields}
                drawerTitle="Add Product"
                isDrawerOpen={isAddProductDrawerOpen}
                closeDrawerCallback={setIsAddProductDrawerOpen}
                submitForm={saveProduct}
              />
              <GenericDrawer
                formFields={existingProductFields}
                drawerTitle="Update Product"
                isDrawerOpen={isUpdateProductDrawerOpen}
                closeDrawerCallback={setIsUpdateProductDrawerOpen}
                submitForm={updateProduct}
              />
              <PrimaryButton
                icon={faPlus}
                label="Add Product"
                onClick={openAddProductDrawer}
                type="button"
              />
              <form onSubmit={handleFilterSubmit} autoComplete="off">
                <TextField
                  variant="standard"
                  id="standard-search"
                  label="Search for..."
                  type="search"
                  value={inputString}
                  onChange={handleFilterChange} />
                <PrimaryButton label="Search" type="submit" />
                <SecondaryButton label="Reset" type="reset" onClick={resetTable} />
              </form>
              <ProductsTable
                products={searchProducts.searchProducts}
                toggleStatus={toggleStatus}
                editProduct={openUpdateProductDrawer}
              />
            </div>
          )}
          no={() => <NoMatch />}
        />
      )
      : null}
  </>;
};

export default Products;
