import React, { useEffect } from 'react';

import { TableRow, TableCell, TableSortLabel } from '@mui/material';
import EditButton from '../../../widgets/buttons/edit/EditButton';
import { TasktopSwitch } from '../../../widgets/switches/Switch.styles';
import TableWrapper from '../../../layout/table/TableWrapper';

export type ProductType = {
  id: string,
  name: string,
  description: string,
  isarchived: boolean,
}
type ProductTableProps = {
  products: ProductType[],
  toggleStatus: (event: React.ChangeEvent<HTMLInputElement>) => void,
  editProduct: (event: React.MouseEvent<HTMLButtonElement>) => void,
};

type Order = 'asc' | 'desc';

const ProductsTable: React.FC<ProductTableProps> = ({ products, toggleStatus, editProduct }: ProductTableProps) => {
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof ProductType>('name');

  const [page, setPage] = React.useState(0);
  const rowsPerPage = 10;

  useEffect(() => {
    setPage(0)
  }, [products])

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
    setPage(newPage);
  };

  function getComparator(order: Order, orderBy: keyof ProductType): (a: ProductType, b: ProductType) => number {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  const descendingComparator = (a: ProductType, b: ProductType, orderBy: keyof ProductType) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  const productSort = (array: ProductType[], comparator: (a: ProductType, b: ProductType) => number) => {
    const stabilizedThis = array.map((el, index) => [el, index] as [ProductType, number]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  const sortProducts = (sortBy: keyof ProductType) => () => {
    const isAsc = orderBy === sortBy && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(sortBy);
  };

  const headColumns = (
    <>
      <TableCell sortDirection={orderBy === 'name' ? order : false}>
        <TableSortLabel
          active={orderBy === 'name'}
          direction={orderBy === 'name' ? order : 'asc'}
          onClick={sortProducts('name')}
        >
          Product
        </TableSortLabel>
      </TableCell>
      <TableCell>Description</TableCell>
      <TableCell>Enabled</TableCell>
      <TableCell align="right" />
    </>
  );

  const tableContents = productSort(products, getComparator(order, orderBy))
    .map((product: ProductType) => (
      <TableRow key={product.id}>
        <TableCell
          component="td"
          scope="row"
        >
          {product.name}
        </TableCell>
        <TableCell
        >
          {product.description}
        </TableCell>
        <TableCell>
          <TasktopSwitch
            checked={!product.isarchived}
            value={product.id}
            name="status"
            onChange={toggleStatus}
          />
        </TableCell>
        <TableCell>
          <EditButton onClick={editProduct} value={product.id} />
        </TableCell>
      </TableRow>
    ));

  return (
    <TableWrapper
      headColumns={headColumns}
      tableContents={tableContents}
      page={page}
      handleChangePage={handleChangePage}
      rowsPerPage={rowsPerPage}
    />
  );
};

export default ProductsTable;
