import React from 'react'
import { TableRow, TableCell } from '@mui/material';
import TableWrapper from '../../../layout/table/TableWrapper'
import { CustomerReleaseType } from '../../../releases/types'
import { TasktopSwitch } from '../../../widgets/switches/Switch.styles';

type CustomerReleasesTableProps = {
    customerReleases: CustomerReleaseType[],
    total: number,
    page: number,
    perPage: number,
    handleChangePage: (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => void,
    onEnabledToggleChanged: (eveny: React.ChangeEvent<HTMLInputElement>) => void
}

const CustomerReleasesTable: React.FC<CustomerReleasesTableProps> = (props: CustomerReleasesTableProps) => {

    const { customerReleases, total, page, perPage, handleChangePage, onEnabledToggleChanged } = props;

    const headColumns = (
        <>
            <TableCell>Name</TableCell>
            <TableCell>Description</TableCell>
            <TableCell align="center">Enabled</TableCell>
        </>
    );

    const tableContents = customerReleases.map((customerRelease: CustomerReleaseType) => (
        <TableRow
            key={customerRelease.release.id} >
            <TableCell
                component="th"
                scope="row"
            >
                {customerRelease.release.name}
            </TableCell>
            <TableCell
            >
                {customerRelease.release.description}
            </TableCell>
            <TableCell align="center">
                <TasktopSwitch
                    data-testid={`${customerRelease.release.id}-switch`}
                    checked={!customerRelease.disabled}
                    value={JSON.stringify(customerRelease)}
                    name="Enabled"
                    onChange={onEnabledToggleChanged}
                />
            </TableCell>
        </TableRow>
    )
    );

    return (
        <TableWrapper
            headColumns={headColumns}
            tableContents={tableContents}
            page={page}
            handleChangePage={handleChangePage}
            rowsPerPage={perPage}
            total={total}
        />
    );
}


export default CustomerReleasesTable
