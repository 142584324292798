import React, { useEffect } from 'react';
import { Auth0ContextInterface, useAuth0 } from '@auth0/auth0-react';
import _ from 'lodash';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import {
  CREATE_CUSTOMER,
  DELETE_CUSTOMER,
  SEND_PASSWORD_RESET_EMAIL,
  SEND_VERIFICATION_EMAIL,
  PAGINATED_SEARCH_CUSTOMERS,
} from '../../client/queries/customers';
import { GET_USER_ROLES } from '../../client/queries/users';
import AdminAccess from '../AdminAccess';
import CustomersTable, { NewCustomerType } from './elements/customersTable/CustomersTable';
import PrimaryButton from '../widgets/buttons/primary/PrimaryButton';
import NoMatch from '../layout/nomatch/NoMatch';
import BarLoader from '../widgets/loaders/barLoader/BarLoader';
import ConfirmDialog from '../widgets/confirmDialog/ConfirmDialog';
import ConfirmDeleteDialog from '../widgets/confirmDeleteDialog/ConfirmDeleteDialog';
import InfoBar from '../widgets/infobar/InfoBar';
import GenericDrawer from '../layout/drawers/GenericDrawer';
import { DetailsField, FormField } from '../../types/types';
import { SecureArea } from './elements/customersDetails/CustomersDetails';
import DetailsDrawer from '../layout/drawers/DetailsDrawer';
import {AuthRoleType} from "../layout/sidebar/Sidebar";
import CustomerSearchReleasesDrawer from '../layout/drawers/searchReleases/CustomersSearchReleasesDrawer';
import SearchForm, { SearchVariables } from '../widgets/search/SearchForm';
import { defaultSnackbarState, SnackbarType } from '../widgets/infobar/types';

type CustomerState = {
    userId: string,
    custId: number,
};

type NewCustomerState = {
    name: string,
    email: string,
};

export type Organization = {
  name: string,
  salesforceId: string
};

export type Account = {
    accountId: number,
    secureAreas: SecureArea[]
};

export type CustomerAccount = {
    id: string,
    accounts: Account[],
    organizationId: Organization
};

export type Customer = {
    custId: number,
    userId: string,
    email: string,
    name: string,
    emailVerified: boolean,
    customerAccounts: CustomerAccount[]
};

const Customers: React.FC = () => {
  const [confirmDialog, setConfirmDialog] = React.useState<boolean>(false);
  const [customerToDelete, setCustomerToDelete] = React.useState<CustomerState>({ userId: '', custId: 0 });
  const [verifyConfirmDialog, setVerifyConfirmDialog] = React.useState<boolean>(false);
  const [customerToVerify, setCustomerToVerify] = React.useState<CustomerState>({ userId: '', custId: 0 });
  const [snackbar, setSnackbar] = React.useState<SnackbarType>(defaultSnackbarState);
  const [newCustomer, setNewCustomer] = React.useState<NewCustomerState>({ name: '', email: '' });
  const [isAddCustomerDrawerOpen, setIsAddCustomerDrawerOpen] = React.useState<boolean>(false);
  const [isCustomerDetailsOpen, setIsCustomerDetailsOpen] = React.useState<boolean>(false);
  const [isEnableCustomerReleaseDrawerOpen, setIsEnableCustomerReleaseDrawerOpen] = React.useState<boolean>(false);
  const [secureAreas, setSecureAreas] = React.useState<SecureArea[]>([]);
  const lastClickedManageReleasesCustomerId = React.useRef<string>('');
  const lastClickedManageReleasesCustomerName = React.useRef<string>('');

  const { isAuthenticated, user }: Auth0ContextInterface = useAuth0();
  const user_id = user?.sub;

  const { loading: adminLoading, error: adminError, data } = useQuery<{ customerRoles: AuthRoleType[] }>(GET_USER_ROLES, {
    variables: { id: user_id },
  });

  const [page, setPage] = React.useState<number>(0);
  const [searchVars, setSearchVars] = React.useState<SearchVariables>({
    search: '',
    searchType: 'name',
    page: page
  });

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
    setPage(newPage)
    setSearchVars(searchVars => ({
      ...searchVars,
      page: newPage
    }));
  }

  const {
    loading: searchCustomerLoading,
    error: searchCustomerError,
    refetch: searchCustomerRefetch,
    data: searchCustomer,
  } = useQuery(PAGINATED_SEARCH_CUSTOMERS, {
    variables: { search: '', searchType: 'name', page: page },
    onError: error => {
      setSnackbar({
        isOpen: true, message: 'There has been an error retrieving customers. Error: ' +
          error.message, severity: 'error'
      });
    },
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    searchCustomerRefetch(searchVars)
  }, [searchCustomerRefetch, searchVars])

  const [sendPassResetEmail] = useLazyQuery(SEND_PASSWORD_RESET_EMAIL);

  // Mutations
  const [addCustomer] = useMutation(CREATE_CUSTOMER, {
    refetchQueries: [{
      query: PAGINATED_SEARCH_CUSTOMERS,
      variables: { search: '',  searchType: 'name', page: page },
    }],
  });

  const [deleteCustomer] = useMutation(DELETE_CUSTOMER, {
    refetchQueries: [{
      query: PAGINATED_SEARCH_CUSTOMERS,
      variables: { search: '', searchType: 'name', page: page },
    }],
  });

  const [sendVerificationEmail] = useMutation(SEND_VERIFICATION_EMAIL);

  if (adminLoading || searchCustomerLoading) return (<BarLoader />);
  if (adminError) return (<NoMatch />);

  // Helpers
  const openDeleteCustomerDialog = (event: React.MouseEvent<HTMLButtonElement>) => {
    searchCustomer.paginatedSearchCustomers.customers.forEach((customer: Customer) => {
      if (customer.custId === parseInt(event.currentTarget.value)) {
        setCustomerToDelete({ userId: customer.userId, custId: customer.custId });
      }
    });
    setConfirmDialog(true );
  };

  const openCustomerDetailsDrawer = (event: React.MouseEvent<HTMLButtonElement>) => {
    setIsCustomerDetailsOpen(true);
    customerDetailsOnClick(event);
  };

  const openManageCustomerReleasesDrawer = (event: React.MouseEvent<HTMLButtonElement>) => {
    const customer: NewCustomerType = JSON.parse(event.currentTarget.value);
    lastClickedManageReleasesCustomerId.current = customer.custId;
    lastClickedManageReleasesCustomerName.current = customer.name;

    setIsEnableCustomerReleaseDrawerOpen(true);
  }

  const handleConfirmDialogCancel = () => {
    setConfirmDialog(false);
  };

  const handleConfirmDialogOk = async () => {
    try {
      await deleteCustomer({
        variables: {
          id: customerToDelete.custId,
        },
      });
      setCustomerToDelete({ userId: '', custId: 0 });
      setConfirmDialog(false );
      setSnackbar({ isOpen: true, message: 'Delete successful!', severity: 'success' });
    } catch (e) {
      setConfirmDialog(false);
      setSnackbar({ isOpen: true, message: 'Unable to delete customer.', severity: 'error' });
    }
  };

  const closeSnackbar = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbar({ ...snackbar, isOpen: false });
  };

  const openAddCustomerDrawer = () => {
    setIsAddCustomerDrawerOpen(true);
  };

  const handleNewCustomerNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewCustomer({ ...newCustomer, name: event.currentTarget.value });
  };

  const handleNewCustomerEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewCustomer({ ...newCustomer, email: event.currentTarget.value });
  };

  const customerDetailsOnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    const secureAreasSet: SecureArea[] = searchCustomer.paginatedSearchCustomers.customers
      .filter((customer: any) => customer.custId === parseInt(event.currentTarget.value))
      .flatMap((customer: any) => customer.customerAccounts)
      .flatMap((customerAccount: any) => customerAccount.accounts)
      .flatMap((account: Account) => account.secureAreas)
      .flatMap((secureArea: SecureArea) => ({ ...secureArea, id: secureArea.name + secureArea.path }));

    setSecureAreas(_.uniqBy(secureAreasSet, 'path'));
  };

  const saveCustomer = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      await addCustomer({
        variables: {
          name: newCustomer.name,
          email: newCustomer.email,
        },
      });
      sendPassResetEmail({ variables: { email: newCustomer.email } });
      setNewCustomer({ name: '', email: '' });
      setSnackbar({ isOpen: true, message: 'Customer created successfully!', severity: 'success' });
      setIsAddCustomerDrawerOpen(false);
    } catch (e) {
      setSnackbar({ isOpen: true, message: 'Problem creating customer. Please try again.', severity: 'error' });
    }
  };

  const openVerifyCustomerDialog = (event: React.MouseEvent<HTMLButtonElement>) => {
    searchCustomer.paginatedSearchCustomers.customers.forEach((customer: Customer) => {
      if (customer.custId === parseInt(event.currentTarget.value)) {
        setCustomerToVerify({ userId: customer.userId, custId: customer.custId });
      }
    });
    setVerifyConfirmDialog(true);
  };

  const handleVerifyConfirmDialogOk = async () => {
    try {
      await sendVerificationEmail({
        variables: {
          id: customerToVerify.custId,
        },
      });
      setCustomerToVerify({ userId: '', custId: 0 });
      setVerifyConfirmDialog(false);
      setSnackbar({ isOpen: true, message: 'Email sent!', severity: 'success' });
    } catch (e) {
      setVerifyConfirmDialog(false);
      setSnackbar({ isOpen: true, message: 'Unable to send email.', severity: 'error' });
    }
  };

  const handleVerifyConfirmDialogCancel = () => {
    setVerifyConfirmDialog(false);
  };

  // Populate forms
  const newCustomerFields: FormField[] = [
    {
      value: newCustomer.name,
      label: 'Customer Name',
      fieldType: 'textField',
      required: true,
      handleChange: handleNewCustomerNameChange,
    },
    {
      value: newCustomer.email,
      label: 'Email Address',
      fieldType: 'textField',
      required: true,
      handleChange: handleNewCustomerEmailChange,
    },
  ];

  const customerDetails: DetailsField[] = [
    {
      title: 'Content Spaces',
      secureAreas,
      label: 'Customer Content Spaces',
    },
  ];

  return (
    <>
      {isAuthenticated
        ? (
          <AdminAccess
            role="admin"
            data={data?.customerRoles}
            yes={() => (
              <div>
                <InfoBar
                  isOpen={snackbar.isOpen}
                  message={snackbar.message}
                  closeSnackbar={closeSnackbar}
                  severity={snackbar.severity}
                />
                <ConfirmDeleteDialog
                  isOpen={confirmDialog}
                  setOpen={setConfirmDialog}
                  title="Delete this customer?"
                  message="Once deleted, this customer will no longer be able to access the customer portal."
                  handleCancel={handleConfirmDialogCancel}
                  handleOk={handleConfirmDialogOk}
                />
                <ConfirmDialog
                  isOpen={verifyConfirmDialog}
                  setOpen={setVerifyConfirmDialog}
                  title="Send a new account verification email?"
                  message="This will send an email to the customer requesting them to verify their account"
                  handleCancel={handleVerifyConfirmDialogCancel}
                  handleOk={handleVerifyConfirmDialogOk}
                />
                <GenericDrawer
                  formFields={newCustomerFields}
                  drawerTitle="Add Customer"
                  isDrawerOpen={isAddCustomerDrawerOpen}
                  closeDrawerCallback={setIsAddCustomerDrawerOpen}
                  submitForm={saveCustomer}
                />
                <DetailsDrawer
                  detailsFields={customerDetails}
                  drawerTitle="Customer Details"
                  isDrawerOpen={isCustomerDetailsOpen}
                  closeDrawerCallback={setIsCustomerDetailsOpen}
                />
                <CustomerSearchReleasesDrawer
                  drawerTitle="Manage Releases"
                  isDrawerOpen={isEnableCustomerReleaseDrawerOpen}
                  closeDrawerCallback={setIsEnableCustomerReleaseDrawerOpen}
                  customerId={lastClickedManageReleasesCustomerId.current}
                  customerName={lastClickedManageReleasesCustomerName.current}
                />
                <PrimaryButton
                  icon={faPlus}
                  label="Add Customer"
                  className="add_button"
                  onClick={openAddCustomerDrawer}
                  type="button"
                />
                <SearchForm
                  error={searchCustomerError}
                  setPage={setPage}
                  setSearchVars={setSearchVars}
                />
                {searchCustomer ? (
                  <CustomersTable
                    customers={searchCustomer.paginatedSearchCustomers.customers}
                    verifyCustomer={openVerifyCustomerDialog}
                    customerDetails={openCustomerDetailsDrawer}
                    manageCustomerReleases={openManageCustomerReleasesDrawer}
                    deleteCustomer={openDeleteCustomerDialog}
                    totalCustomers={searchCustomer.paginatedSearchCustomers.total}
                    page={page}
                    perPage={searchCustomer.paginatedSearchCustomers.perPage}
                    changePage={handleChangePage}
                  />
                ) : null}
              </div>
            )}
            no={() => <NoMatch />}
          />
        )
        : null}
    </>
  );
};
export default Customers;
