import React from 'react';

import IndividualReleaseTable from '../individualReleaseTable/IndividualReleaseTable';
import MajorVersionTable from '../majorVersionTable/MajorVersionTable';
import TotalDownloads from '../totalDownloads/TotalDownloads';
import { DownloadCountsByReleaseType, DownloadCountsByVersionType } from '../types';

import styles from './ProductDownloads.module.css';

type ProductDownloadProps = {
    title: string,
    downloadCount: number,
    downloadCountsByVersion: DownloadCountsByVersionType[],
    downloadCountsByRelease: DownloadCountsByReleaseType[]
};

const ProductDownloads: React.FC<ProductDownloadProps> = (props: ProductDownloadProps) => (
  <div className={styles.downloads_container}>
    <h2>{props.title}</h2>
    <TotalDownloads downloadCount={props.downloadCount} />
    <MajorVersionTable downloadCountsByVersion={props.downloadCountsByVersion} />
    <IndividualReleaseTable downloadCountsByRelease={props.downloadCountsByRelease} />
  </div>
);

export default ProductDownloads;
