import { gql } from '@apollo/client';

export const DELETE_CUSTOMER = gql`
  mutation DeleteCustomer($id: Int) {
    deleteCustomer(id: $id) {
     code
     message
   }
  }
`;

export const CUSTOMER = gql`
  query GetCustomer($customerId: Int) {
    customer(id: $customerId) {
      custId
      userId
      email
      name
      emailVerified
      customerAccounts {
        id
        accounts {
          secureAreas {
            name
            path
          }
        }
        organizationId {
          name
        }
      }
    }
  }
`

export const CUSTOMER_BY_USER_ID = gql`
  query CustomerByUserId($userId: String) {
    customerByUserId(userId: $userId) {
      custId
      userId
      email
      name
      userMetadata {
        newsletter
        releaseUpdates
      }
      customerAccounts {
        id
        accounts {
          secureAreas {
            name
            path
          }
        }
      }
    }
  }
`;

export const SEARCH_CUSTOMER = gql`
  query SearchCustomers($search: String) {
    searchCustomers(search: $search) {
      custId
      userId
      email
      name
      emailVerified
      userMetadata {
        newsletter
        releaseUpdates
      }
      customerAccounts {
        id
        accounts {
          secureAreas {
            name
            path
          }
        }
      }
    }
  }
`;

export const PAGINATED_SEARCH_CUSTOMERS = gql`
  query PaginatedSearchCustomers($page: Int, $perPage: Int, $search: String, $searchType: String) {
    paginatedSearchCustomers(page: $page, perPage: $perPage, search: $search, searchType: $searchType) {
      page
      perPage
      total
      customers {
        custId
        userId
        email
        name
        emailVerified
        userMetadata {
          newsletter
          releaseUpdates
        }
        customerAccounts {
          id
          accounts {
            secureAreas {
              name
              path
            }
          }
        }
      }
    }
  }
`

export const SEARCH_CUSTOMER_NAME_EMAIL_CUSTID = gql`
  query PaginatedSearchCustomers($page: Int, $perPage: Int, $search: String, $searchType: String) {
    paginatedSearchCustomers(page: $page, perPage: $perPage, search: $search, searchType: $searchType) {
      page
      perPage
      total
      customers {
        custId
        userId
        email
        name
        emailVerified
        userMetadata {
          newsletter
          releaseUpdates
        }
        customerAccounts {
          id
          accounts {
            secureAreas {
              name
              path
            }
          }
        }
      }
    }
  }
`;

export const CREATE_CUSTOMER = gql`
  mutation CreateCustomer($name: String, $email: String) {
    createNewUser(name: $name, email: $email) {
      user {
        name
        email
      }
    }
  }
`;

export const SEND_PASSWORD_RESET_EMAIL = gql`
  query SendPasswordResetEmail($email: String) {
    sendPasswordResetEmail(email: $email)
  }
`;

export const SEND_VERIFICATION_EMAIL = gql`
  mutation SendVerificationEmail($id: Int) {
    sendVerificationEmail(id: $id) {
      message
      code
    }
  }
`;

export const SIGN_UP = gql`
  mutation SignUp($userId: String) {
    signUp(userId: $userId) {
      custId
    }
  }
`;


export const UPDATE_CUSTOMER = gql`
  mutation UpdateCustomer($userId: String, $name: String, $newsletter: String, $releaseUpdates: String) {
    updateCustomer(userId: $userId, name: $name, newsletter: $newsletter, releaseUpdates: $releaseUpdates) {
      customer {
        userId
        name
        userMetadata {
          newsletter
          releaseUpdates
        }
      }
    }
  }
`;


export const SECURE_AREAS_BY_CUSTOMER_ID = gql`
  query SecureAreasByCustomerId($userId: String, $id: Int) {
    secureAreasByCustomerId(userId: $userId, id: $id) {
      secureAreaId
      name
      path
    }
  }
`;

export const PAGINATED_CUSTOMERS_BY_RELEASE_ID = gql`
  query PaginatedCustomersByReleaseId($releaseId: String, $page: Int, $perPage: Int) {
    paginatedCustomersByReleaseId(releaseId: $releaseId, page: $page, perPage: $perPage) {
      page
      perPage
      total
      customers {
        id
        customer {
          custId
          email
          name
        }
        disabled
        notified
        licenseAcceptanceDate
      }
    }
  }
`;

export const SEARCH_PAGINATED_CUSTOMERS_BY_RELEASE_ID = gql`
  query SearchPaginatedCustomersByReleaseId($releaseId: String, $search: String, $page: Int, $perPage: Int) {
    searchPaginatedCustomersByReleaseId(releaseId: $releaseId, search: $search, page: $page, perPage: $perPage) {
      page
      perPage
      total
      customers {
        id
        customer {
          custId
          email
          name
        }
        disabled
        notified
        licenseAcceptanceDate
      }
    }
  }
`;
