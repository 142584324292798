import React from 'react';
import { TextField, Autocomplete } from '@mui/material';

type AutocompleteFieldProps = {
  name: string,
  value: string,
  label: string,
  options: any[],
  optionLabel: any,
  required: boolean,
  selectedOption: any,
  handleChange: (event: React.ChangeEvent<any>) => void,
  handleAutocomplete: (event: React.ChangeEvent<any>, value: any) => void
};

const GenericAutoCompleteField: React.FC<AutocompleteFieldProps> = (props: AutocompleteFieldProps) => (
  <Autocomplete
    id={"combo-box-" + props.label.replace(/ /g, '')}
    options={props.options}
    isOptionEqualToValue={(option, value) => (option.custid === value.custid)}
    value={props.selectedOption}
    getOptionLabel={props.optionLabel ? props.optionLabel : (option) => `${option.name} ${option.id}`}
    onChange={props.handleAutocomplete}
    renderInput={(params) => <TextField {...params} label={props.label} variant="outlined" />}
  />
)

export default GenericAutoCompleteField;
