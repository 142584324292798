import { gql } from '@apollo/client';

export const GET_SALESFORCE_ID = gql`
  query SearchCustomerId($search: String) {
    searchCustomerId(search: $search){
        id
        name
        link
    }
  }
`;
