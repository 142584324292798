import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

export const CssTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: '#000',
  },
  '& .MuiOutlinedInput-root': {
    '&:hover fieldset': {
      borderColor: 'rgba(130, 188, 0, 0.2)',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#82bc00',
    },
  },
});
