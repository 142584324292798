import { TableRow } from '@mui/material';
import { styled } from '@mui/material/styles';

export const AccountsTableRow = styled(TableRow)`
  a {
    color: #82bc00;
    text-decoration: none;
    cursor: pointer;

    :hover {
      color: #9bca37;
    }
  }
`
