import React from 'react';
import { Auth0ContextInterface, useAuth0 } from '@auth0/auth0-react';

import { useMutation, useQuery } from '@apollo/client';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import { CREATE_CONTENT_SPACE, SEARCH_CONTENT_SPACES, UPDATE_CONTENT_SPACE } from '../../client/queries/contentSpaces';

import { GET_USER_ROLES } from '../../client/queries/users';
import AdminAccess from '../AdminAccess';
import ContentSpacesTable from './elements/contentSpacesTable/ContentSpacesTable';
import InfoBar from '../widgets/infobar/InfoBar';
import PrimaryButton from '../widgets/buttons/primary/PrimaryButton';
import NoMatch from '../layout/nomatch/NoMatch';
import BarLoader from '../widgets/loaders/barLoader/BarLoader';
import GenericDrawer from '../layout/drawers/GenericDrawer';
import { defaultSnackbarState, SnackbarType } from '../widgets/infobar/types';
import { FormField } from '../../types/types';
import { ContentSpaceType, defaultContentSpace } from './types';

import styles from './ContentSpaces.module.css';
import {AuthRoleType} from "../layout/sidebar/Sidebar";

const ContentSpaces: React.FC = () => {
  const { isAuthenticated, user }: Auth0ContextInterface = useAuth0();
  const [roles, setRoles] = React.useState<AuthRoleType[]>([]);
  const [isAddSpaceDrawerOpen, setIsAddSpaceDrawerOpen] = React.useState<boolean>(false);
  const [isUpdateSpaceDrawerOpen, setIsUpdateSpaceDrawerOpen] = React.useState<boolean>(false);
  const [newSpace, setNewSpace] = React.useState<ContentSpaceType>(defaultContentSpace);
  const [updatedSpace, setUpdatedSpace] = React.useState<ContentSpaceType>(defaultContentSpace);
  const [snackbar, setSnackbar] = React.useState<SnackbarType>(defaultSnackbarState);
  const user_id = user?.sub;

  // Queries

  const { loading: adminLoading, error: adminError, data } = useQuery<{ customerRoles: AuthRoleType[] }>(GET_USER_ROLES, {
    variables: { id: user_id },
    onCompleted: (data) => {
      if (data) {
        setRoles(data.customerRoles)
      }
    },
    notifyOnNetworkStatusChange: true
  });

  const {
    loading: searchSpacesLoading,
    error: searchSpacesError,
    data: contentSpaces,
  } = useQuery(SEARCH_CONTENT_SPACES, {
    variables: { search: '' },
  });

  // Mutations
  const [addContentSpace] = useMutation(CREATE_CONTENT_SPACE, {
    refetchQueries: [{
      query: SEARCH_CONTENT_SPACES,
      variables: { search: '' },
    }],
  });

  const [updateExistingContentSpace] = useMutation(UPDATE_CONTENT_SPACE, {
    refetchQueries: [{
      query: SEARCH_CONTENT_SPACES,
      variables: { search: '' },
    }],
  });

  // Helpers
  const closeSnackbar = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') return;
    setSnackbar({ ...snackbar, isOpen: false });
  };

  const openAddSpaceDrawer = () => {
    setIsAddSpaceDrawerOpen(true);
  };

  const handleNewSpaceNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewSpace({ ...newSpace, name: event.currentTarget.value });
  };

  const handleNewSpacePathChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewSpace({ ...newSpace, path: event.currentTarget.value });
  };

  const saveContentSpace = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      await addContentSpace({
        variables: {
          name: newSpace.name,
          path: newSpace.path,
        },
      });
      setNewSpace(defaultContentSpace);
      setSnackbar({ isOpen: true, message: 'Content space created successfully!', severity: 'success' });
    } catch (e) {
      setSnackbar({
        isOpen: true,
        message: 'Problem creating content space. Please try again.',
        severity: 'error',
      });
    }
    setIsAddSpaceDrawerOpen(false);
  };

  const openUpdateSpaceDrawer = (event: React.MouseEvent<HTMLButtonElement>) => {
    contentSpaces.searchSecureAreas.forEach((space: ContentSpaceType) => {
      if (space.secureAreaId === event.currentTarget.value) {
        setUpdatedSpace(space);
      }
    });
    setIsUpdateSpaceDrawerOpen(true);
  };

  const handleUpdatedSpaceNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUpdatedSpace({ ...updatedSpace, name: event.currentTarget.value });
  };

  const handleUpdatedSpacePathChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUpdatedSpace({ ...updatedSpace, path: event.currentTarget.value });
  };

  const updateContentSpace = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      await updateExistingContentSpace({
        variables: {
          secureAreaId: parseInt(updatedSpace.secureAreaId),
          name: updatedSpace.name,
          path: updatedSpace.path,
        },
      });
      setUpdatedSpace(defaultContentSpace);
      setSnackbar({ isOpen: true, message: 'Content space updated!', severity: 'success' });
    } catch (e) {
      setSnackbar({
        isOpen: true,
        message: 'Problem updating content space. Please try again.',
        severity: 'error',
      });
    }
    setIsUpdateSpaceDrawerOpen(false);
  };

  if (adminLoading || searchSpacesLoading) return (<BarLoader />);
  if (adminError || searchSpacesError) return (<NoMatch />);

  // Populate forms
  const newContentSpaceFields: FormField[] = [
    {
      value: newSpace.name,
      label: 'Content Space Name',
      fieldType: 'textField',
      required: true,
      handleChange: handleNewSpaceNameChange,
    },
    {
      value: newSpace.path,
      label: 'Path',
      fieldType: 'textField',
      required: true,
      handleChange: handleNewSpacePathChange,
    },
  ];

  const updateContentSpaceFields = () => {
    const fields: FormField[] = [
      {
        value: updatedSpace.name,
        label: 'Content Space Name',
        fieldType: 'textField',
        required: true,
        handleChange: handleUpdatedSpaceNameChange,
      },
      {
        value: updatedSpace.path,
        label: 'Path',
        fieldType: 'textField',
        required: true,
        handleChange: handleUpdatedSpacePathChange,
      },
    ];
    return fields;
  };

  return (
    <div>
      {isAuthenticated
        ? (
          <AdminAccess
            role="admin"
            data={roles}
            yes={() => (
              <div className={styles.content_spaces}>
                <InfoBar
                  isOpen={snackbar.isOpen}
                  message={snackbar.message}
                  closeSnackbar={closeSnackbar}
                  severity={snackbar.severity}
                />
                <GenericDrawer
                  formFields={newContentSpaceFields}
                  drawerTitle="Add Content Space"
                  isDrawerOpen={isAddSpaceDrawerOpen}
                  closeDrawerCallback={setIsAddSpaceDrawerOpen}
                  submitForm={saveContentSpace}
                />
                <GenericDrawer
                  formFields={updateContentSpaceFields()}
                  drawerTitle="Update Release"
                  isDrawerOpen={isUpdateSpaceDrawerOpen}
                  closeDrawerCallback={setIsUpdateSpaceDrawerOpen}
                  submitForm={updateContentSpace}
                />
                <PrimaryButton
                  icon={faPlus}
                  label="Add Content Space"
                  className={styles.add_button}
                  onClick={openAddSpaceDrawer}
                  type="button"
                />
                <ContentSpacesTable
                  spaces={contentSpaces.searchSecureAreas}
                  updateSpace={openUpdateSpaceDrawer}
                />
              </div>
            )}
            no={() => <NoMatch />}
          />
        )
        : null}
    </div>
  );
};

export default ContentSpaces;
