import React from 'react';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

import styles from './DeleteButton.module.css';
import GenericButton from '../generic/GenericButton';

type DeleteButtonProps = {
    label?: string,
    onClick?(event: any): void
    value?: string,
    icon: IconDefinition,
    title?: string
};

const DeleteButton: React.FC<DeleteButtonProps> = (props: DeleteButtonProps) => (
  <GenericButton
    className={styles.button}
    icon={props.icon}
    label={props.label}
    onClick={props.onClick}
    value={props.value}
    title={props.title}
  />

);

export default DeleteButton;
