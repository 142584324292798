import React, { useEffect } from 'react';
import { TableRow, TableCell } from '@mui/material';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import TableWrapper from '../../../layout/table/TableWrapper';
import EditButton from '../../../widgets/buttons/edit/EditButton';
import DeleteButton from '../../../widgets/buttons/delete/DeleteButton';
import { ReleaseFileType } from '../../types';

type ReleaseFilesTableProps = {
  releaseFiles: ReleaseFileType[],
  editFile: (event: React.MouseEvent<HTMLButtonElement>) => void,
  deleteFile: (event: React.MouseEvent<HTMLButtonElement>) => void,
};

const ReleaseFilesTable: React.FC<ReleaseFilesTableProps> = ({
  releaseFiles, editFile, deleteFile
}: ReleaseFilesTableProps) => {
  const [page, setPage] = React.useState(0);
  const rowsPerPage = 10;

  useEffect(() => {
    setPage(0)
  }, [releaseFiles])

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
    setPage(newPage);
  };

  const headColumns = (
    <>
      <TableCell>Checksum</TableCell>
      <TableCell>Description</TableCell>
      <TableCell>File Path</TableCell>
      <TableCell align="right" />
    </>
  );

  const tableContents = releaseFiles.map((file: ReleaseFileType) => (
    <TableRow key={file.id}>
      <TableCell>
        {file.checksum}
      </TableCell>
      <TableCell>
        {file.description}
      </TableCell>
      <TableCell>
        {file.filePath}
      </TableCell>
      <TableCell align="right">
        <EditButton label="Update File" onClick={editFile} value={file.id} />
        <DeleteButton label="Delete File" onClick={deleteFile} value={file.id} icon={faTrash} />
      </TableCell>
    </TableRow>
  ));

  return (
    <TableWrapper
      headColumns={headColumns}
      tableContents={tableContents}
      page={page}
      handleChangePage={handleChangePage}
      rowsPerPage={rowsPerPage}

    />
  );
};

export default ReleaseFilesTable;
