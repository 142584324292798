import React, { useEffect } from 'react';

import { TableCell } from '@mui/material';
import { Link } from 'react-router-dom';
import TableWrapper from '../../../layout/table/TableWrapper';
import { AccountsTableRow } from './accountsTable.styles';

export type AccountType = {
  organizationid: number,
  name: string,
  accesscode: string,
  salesforceid: string,
}


type AccountTableProps = {
  accounts: AccountType[],
};

const AccountsTable: React.FC<AccountTableProps> = ({ accounts }: AccountTableProps) => {

  const [page, setPage] = React.useState(0);
  const rowsPerPage = 10;

  useEffect(() => {
    setPage(0)
  }, [accounts])

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
    setPage(newPage);
  };

  const headColumns = (
    <>
      <TableCell>Name</TableCell>
      <TableCell>Access Code</TableCell>
      <TableCell>Salesforce ID</TableCell>
      <TableCell align="right" />
    </>
  );

  const findLink = (account: AccountType) => {
    let link = '';
    if (account.salesforceid && account.salesforceid !== '') {
      link = process.env.REACT_APP_SALESFORCE_URL+account.salesforceid;
    }
    return link;
  };

  const tableContents = accounts.map((account: AccountType) => (
    <AccountsTableRow key={account.organizationid}>
      <TableCell  component="th" scope="row">
        <Link to={{ pathname: `/accounts/${account.organizationid}` }}>
          {account.name}
        </Link>
      </TableCell>
      <TableCell>
        {account.accesscode}
      </TableCell>
      <TableCell>
        {(findLink(account))
          ? <a href={findLink(account)}>{account.salesforceid}</a> : 'N/A'}
      </TableCell>
    </AccountsTableRow>
  ));

  return (
    <TableWrapper
      headColumns={headColumns}
      tableContents={tableContents}
      page={page}
      handleChangePage={handleChangePage}
      rowsPerPage={rowsPerPage}
    />
  );
};

export default AccountsTable;
