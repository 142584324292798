import React from 'react';
import { TableCell } from '@mui/material';
import TableWrapper from '../table/TableWrapper';
import AccountsTableRow from './AccountsTableRow'
import { CustomerAssociationsType, CustomerRoleAssociations } from '../drawers/searchCustomers/AccountsSearchCustomerDrawer';
import { RoleType } from '../../accounts/elements/accountsPage/AccountsPage';

interface AccountsSearchResultsTableProps {
  searchResults: CustomerAssociationsType[],
  rolesDB: RoleType[],
  collectObjects: (customer: CustomerRoleAssociations) => void,
  totalCustomers: number,
  page: number,
  changePage: any
}

const AccountsSearchResultsTable: React.FC<AccountsSearchResultsTableProps> = (props: AccountsSearchResultsTableProps) => {
  const { searchResults, collectObjects, rolesDB, totalCustomers, page, changePage } = props

  const headColumns = (
    <>
      <TableCell>Customer</TableCell>
      <TableCell>Roles</TableCell>
    </>
  );

  const tableContents = searchResults.map((searchResult: CustomerAssociationsType) => {
    return (
        <AccountsTableRow
          key={searchResult.custId}
          customerID={searchResult.custId}
          name={searchResult.name}
          email={searchResult.email}
          rolesDB={rolesDB}
          collectObjects={collectObjects}
        />
    )
  }
  );

  return (
    <TableWrapper
      headColumns={headColumns}
      tableContents={tableContents}
      total={totalCustomers}
      page={page}
      handleChangePage={changePage}
      rowsPerPage={10}
    />
  );
}

export default AccountsSearchResultsTable
