import React, { useEffect } from 'react';
import { Drawer } from "@mui/material";
import { useMutation, useQuery } from '@apollo/client';
import { PAGINATED_SEARCH_CUSTOMERS } from '../../../../client/queries/customers';
import { CREATE_ASSOCIATION_ROLES } from "../../../../client/queries/accounts";
import { BarLoader } from 'react-spinners';
import PrimaryButton from '../../../widgets/buttons/primary/PrimaryButton';
import AccountsSearchResultsTable from '../../searchResultsTable/AccountsSearchResultsTable'
import { SnackbarType } from '../../../widgets/infobar/types';
import { RoleType } from '../../../accounts/elements/accountsPage/AccountsPage';
import SearchForm, { SearchVariables } from '../../../widgets/search/SearchForm';
import { SearchCustomerDrawerStyles } from './SearchCustomerDrawer.styles';

interface AccountsSearchCustomerDrawerProps {
  userId?: string,
  setSnackbar: (snackbar: SnackbarType) => void,
  refetchCustomersPage: () => void,
  accountID: string,
  drawerTitle: string,
  isDrawerOpen: boolean,
  rolesDB: RoleType[],
  closeDrawerCallback: (action: React.SetStateAction<boolean>) => void,
}

export type CustomerAssociationsType = {
  custId: number,
  name: string,
  email: string,
}

export type CustomerRoleAssociations = {
  custId: number,
  roles: number[]
}

const AccountsSearchCustomerDrawer: React.FC<AccountsSearchCustomerDrawerProps> = (props: AccountsSearchCustomerDrawerProps) => {
  const { userId, setSnackbar, refetchCustomersPage, accountID, drawerTitle, isDrawerOpen, closeDrawerCallback, rolesDB } = props

  const closeDrawer = (event: any, callback: (action: React.SetStateAction<boolean>) => void) => {
    if (
      event.type === 'keydown'
      && ((event as React.KeyboardEvent).key === 'Tab'
        || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    callback(false);
  };

  // Search form query ************************
  const [page, setPage] = React.useState<number>(0)
  const [searchVars, setSearchVars] = React.useState<SearchVariables>({
    search: '',
    searchType: 'name',
    page: 0
  })

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
    setPage(newPage)
    setSearchVars(searchVars => ({
      ...searchVars,
      page: newPage
    }));
  }

  const {
    loading: searchCustomerLoading,
    error: searchCustomerError,
    refetch: searchCustomerRefetch,
    data: searchCustomer,
  } = useQuery(PAGINATED_SEARCH_CUSTOMERS, {
    variables: { search: '', searchType: 'name', page: page },
    onError: error => {
      setSnackbar({
        isOpen: true, message: 'There has been an error retrieving customers. Error: ' +
          error.message, severity: 'error'
      });
    },
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    searchCustomerRefetch(searchVars)
  }, [searchCustomerRefetch, searchVars])

  // End search form query ************************

  // Add customer roles functionality ************************

  const customersList: CustomerRoleAssociations[] = []

  const collectObjects = (customer: CustomerRoleAssociations) => {
    const i = customersList.findIndex((c) => c.custId === customer.custId);

    if (i === -1 && customer.roles.length > 0) {
      customersList.push(customer);
    } else {
      if (customer.roles.length > 0) {
        customersList[i] = customer
      }
    }
  };

  const [createAssociationRoles] = useMutation < { createCustomerAccountRoles:{ ok: boolean, message: string }}>(CREATE_ASSOCIATION_ROLES, {
    variables: {
      userId: userId,
      customerRoles: customersList,
      accessCode: accountID
    },
    onCompleted: () => {
      refetchCustomersPage()
    },
  });

  const handleCreateAssociation = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      if (customersList.length > 0) {
        const result = await createAssociationRoles({
          variables: {
            userId: userId,
            accessCode: accountID,
            customerRoles: customersList
          }
        });
        closeDrawerCallback(false);
        if (result && result.data) {
          if (result.data.createCustomerAccountRoles.ok) {
            setSnackbar({isOpen: true, message: 'Roles have been added to the customers!', severity: 'success'});
          } else {
            setSnackbar({isOpen: true, message: result.data?.createCustomerAccountRoles.message, severity: 'error'});
          }
        }
      } else {
        closeDrawerCallback(false);
        setSnackbar({ isOpen: true, message: 'No new roles have been assigned.', severity: 'info' });
      }
    } catch (e) {
      closeDrawerCallback(false);
      setSnackbar({ isOpen: true, message: 'Unable to assign roles. Please try again later!', severity: 'error' });
    }
  }

  // End add customer roles functionality ************************

  return (
    <Drawer
      anchor="right"
      open={isDrawerOpen}
      onClose={(event) => closeDrawer(event, closeDrawerCallback)}
    >
      <SearchCustomerDrawerStyles>
        <h2 className='bar'>
          {drawerTitle}
        </h2>
        <SearchForm
          error={searchCustomerError}
          setPage={setPage}
          setSearchVars={setSearchVars}
        />
        {searchCustomerLoading ? (<BarLoader />) : (
          <form onSubmit={handleCreateAssociation} className="tableForm">
            {!searchCustomerError && (<PrimaryButton label="Save" type="submit" className='saveButton'/>)}
            <AccountsSearchResultsTable
              searchResults={searchCustomer.paginatedSearchCustomers.customers}
              rolesDB={rolesDB}
              collectObjects={collectObjects}
              totalCustomers={searchCustomer.paginatedSearchCustomers.total}
              page={page}
              changePage={handleChangePage}
            />
          </form>
        )}
      </SearchCustomerDrawerStyles>
    </Drawer>
  );
};

export default AccountsSearchCustomerDrawer;
