import React from 'react';

import styles from './NoDownloads.module.css';

const NoDownloads: React.FC = () => (
  <div className={styles.no_downloads}>
    <h1>You do not seem to have any downloads...</h1>
  </div>
);

export default NoDownloads;
