import React from 'react';

import styles from './TotalDownloads.module.css';

type TotalDownloadsProps = {
    downloadCount: number,
}
const TotalDownloads: React.FC<TotalDownloadsProps> = (props: TotalDownloadsProps) => (
  <div className={styles.total_downloads}>
    <p className={styles.total_downloads_count}>{props.downloadCount}</p>
    <h3 className={styles.total_downloads_title}>Total Downloads</h3>
  </div>
);

export default TotalDownloads;
