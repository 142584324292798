import React from 'react';

import TasktopTable from '../../layout/table/tasktopTable/TasktopTable';
import { DownloadCountsByVersionType } from '../types';

type MajorVersionTableProps = {
    downloadCountsByVersion: DownloadCountsByVersionType[],
};

const MajorVersionTable: React.FC<MajorVersionTableProps> = (props: MajorVersionTableProps) => (
  <TasktopTable
    title="Downloads by Major Version"
    columns={[
      { title: 'Version', field: 'version', defaultSort: 'desc' },
      { title: 'Count', field: 'count' },
    ]}
    data={
      props.downloadCountsByVersion.map((item) => ({
        id: item.version,
        version: item.version,
        count: item.versionCount
      }))
    }
    export
  />
);

export default MajorVersionTable;
