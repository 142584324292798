import { styled } from '@mui/material/styles';

export const PageLayout = styled('div')`
  display: grid;
  grid-template-rows: auto 1fr;
  box-sizing: border-box;
  height: 100vh;
`

export const ContentContainer = styled('div')`
  display: flex;
  box-sizing: border-box;
  height: 100%;
  overflow: scroll;
  padding: 20px;

  > div {
    width: 100%;
    height: 100%;
  }
`
