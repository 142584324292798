import React from 'react';
import { TableContainer, TableRow, TableFooter, TablePagination, Paper, Box } from '@mui/material';
import TablePaginationActions from '../tablePagination/TablePaginationActions';
import { StyledTable, StyledTableBody, StyledTableHead } from './TableWrapper.styles';

type TableWrapperProps = {
  headColumns: JSX.Element,
  tableContents: JSX.Element[],
  page: number,
  rowsPerPage: number,
  handleChangePage: (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => void,
  total?: number
};

const TableWrapper: React.FC<TableWrapperProps> = ({ headColumns, tableContents, page, rowsPerPage, handleChangePage, total }: TableWrapperProps) => {

  let totalRows = 0;
  if (total !== undefined) {
    totalRows = total;
  } else {
    totalRows = tableContents.length;
  }

  return (
    <Box sx={{ width: '100%', pb: 2 }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <TableContainer>
          <StyledTable
            role="table"
            aria-label="simple table"
            stickyHeader
          >
            <StyledTableHead>
              <TableRow>
                {headColumns}
              </TableRow>
            </StyledTableHead>
            <StyledTableBody>
              {(rowsPerPage > 0 && total === undefined
                ? tableContents.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                : tableContents
              )}
            </StyledTableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[]}
                  count={totalRows}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </StyledTable>
        </TableContainer>
      </Paper>
    </Box>
  );
};

export default TableWrapper;
