import React from 'react';
import { List, ListItem, ListItemText } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBalanceScale } from '@fortawesome/free-solid-svg-icons';

import Card from '../../../widgets/cards/Card';
import styles from './ReleaseLicenseInfo.module.css';

type ReleaseLicenseInfoProps = {
    licenseTitle: string,
    licenseText: string,
    onClick: () => void,
};

const ReleaseLicenseInfo: React.FC<ReleaseLicenseInfoProps> = (props: ReleaseLicenseInfoProps) => (
  <Card
    className={styles.release_license_info}
    cardTitle="License Information"
    onClick={props.onClick}
  >
    <List>
      <ListItem>
        <FontAwesomeIcon icon={faBalanceScale} />
        <ListItemText primary={props.licenseTitle} />
      </ListItem>
      <ListItem>
        <p className={styles.release_license_text}>{props.licenseText}</p>
      </ListItem>
    </List>
  </Card>
);

export default ReleaseLicenseInfo;
