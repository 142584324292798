import { styled } from '@mui/material/styles';

export const HeaderContainer = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-sizing: border-box;
  border-bottom: 1px solid #e6e6e6;
  height: 90px;
  padding: 0 20px;
`

export const PageTitle = styled('div')`
    display: flex;
    flex-direction: row;
    align-items: center;

    span {
      font-size: 20px;
      font-weight: 400;
      text-transform: capitalize;
      border-left: 5px solid #82bc00;
      padding-left: 10px;
      line-height: 1.2;
    }
`

export const Authentication = styled('div')`
  display: flex;
  align-items: center;
  padding-right: 20px;
`
