import React, { useEffect } from 'react';
import { Drawer } from "@mui/material";
import { useQuery } from '@apollo/client';
import { PAGINATED_SEARCH_CUSTOMERS } from '../../../../client/queries/customers';
import { BarLoader } from 'react-spinners';
import PrimaryButton from '../../../widgets/buttons/primary/PrimaryButton';
import ReleasesSearchResultsTable from '../../searchResultsTable/ReleasesSearchResultsTable';
import SearchForm, { SearchVariables } from '../../../widgets/search/SearchForm';
import InfoBar from '../../../widgets/infobar/InfoBar';
import { defaultSnackbarState, SnackbarType } from '../../../widgets/infobar/types';
import { SearchCustomerDrawerStyles } from './SearchCustomerDrawer.styles';

interface ReleasesSearchCustomerDrawerProps {
  drawerTitle: string,
  isDrawerOpen: boolean,
  handleCustomerImport: (customerIdArray: number[]) => void,
  closeDrawerCallback: (action: React.SetStateAction<boolean>) => void,
}

export type CustomerAssociationsType = {
  custId: number,
  name: string,
  email: string,
}

export type EnabledCustomers = {
  custId: number,
  enabled: boolean
}

const ReleasesSearchCustomerDrawer: React.FC<ReleasesSearchCustomerDrawerProps> = (props: ReleasesSearchCustomerDrawerProps) => {
  const { drawerTitle, isDrawerOpen, closeDrawerCallback, handleCustomerImport } = props
  const [snackbar, setSnackbar] = React.useState<SnackbarType>(defaultSnackbarState);

  const closeDrawer = (event: any, callback: (action: React.SetStateAction<boolean>) => void) => {
    if (
      event.type === 'keydown'
      && ((event as React.KeyboardEvent).key === 'Tab'
        || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    callback(false);
  };

  // Search form query ************************

  const [page, setPage] = React.useState<number>(0)
  const [searchVars, setSearchVars] = React.useState<SearchVariables>({
    search: '',
    searchType: 'name',
    page: 0
  })

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
    setPage(newPage)
    setSearchVars(searchVars => ({
      ...searchVars,
      page: newPage
    }));
  }

  const {
    loading: searchCustomerLoading,
    error: searchCustomerError,
    refetch: searchCustomerRefetch,
    data: searchCustomer
  } = useQuery(PAGINATED_SEARCH_CUSTOMERS, {
    variables: { search: '', searchType: 'name', page: page },
    onError: error => {
      setSnackbar({
        isOpen: true, message: 'There has been an error retrieving customers. Error: ' +
          error.message, severity: 'error'
      });
    },
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    searchCustomerRefetch(searchVars)
  }, [searchCustomerRefetch, searchVars])

  // End search form query ************************

  // Customers for Release functionality ************************
  const customersToEnableArr: number[] = []

  const collectObjects = (customer: EnabledCustomers) => {
    const index = customersToEnableArr.indexOf(customer.custId);

    if (customer.enabled && (index === -1)) {
      customersToEnableArr.push(customer.custId);
    } else {
      if (index > -1) {
        customersToEnableArr.splice(index, 1);
      }
    }
  }

  const handleTheHandler = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    handleCustomerImport(customersToEnableArr)
  }

  const closeSnackbar = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') return;
    setSnackbar({ ...snackbar, isOpen: false });
  };

  // End Customers for Release functionality ************************

  return (
    <Drawer
      anchor="right"
      open={isDrawerOpen}
      onClose={(event) => closeDrawer(event, closeDrawerCallback)}
    >
      <SearchCustomerDrawerStyles>
        <h2 className='bar'>
          {drawerTitle}
        </h2>
        <InfoBar
          isOpen={snackbar.isOpen}
          message={snackbar.message}
          closeSnackbar={closeSnackbar}
          severity={snackbar.severity}
        />
        <SearchForm
          error={searchCustomerError}
          setPage={setPage}
          setSearchVars={setSearchVars}
        />
        {searchCustomerLoading ? (<BarLoader />) : (
          <form onSubmit={handleTheHandler} className="tableForm">
            <PrimaryButton label="Save" type="submit" className='saveButton'/>
            <ReleasesSearchResultsTable searchResults={searchCustomer.paginatedSearchCustomers.customers} collectObjects={collectObjects} totalCustomers={searchCustomer.paginatedSearchCustomers.total} page={page} changePage={handleChangePage} />
          </form>
        )}
      </SearchCustomerDrawerStyles>
    </Drawer>
  );
};

export default ReleasesSearchCustomerDrawer;
