import React from 'react';

import MaterialTable from '@material-table/core';
import ReplayButton from '../../../widgets/buttons/replay/ReplayButton';
import DownloadButton from '../../../widgets/buttons/download/DownloadButton';
import { TasktopTableWrapper } from '../../../layout/table/tasktopTable/TasktopTable.styles';

type LicenseTableProps = {
    licenses: any,
    accountName: string,
    onCreateLicense: () => void
};

const LicensesTable: React.FC<LicenseTableProps> = (props: LicenseTableProps) => {
  const replayLicensePage = (licenceId: number) => {
    window.location.assign(`https://license.tasktop.com/licenseReplay/${licenceId}`);
  };

  const downloadLicense = (license: any, accountName: string) => {
    const a = document.createElement('a');
    a.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(wrapString(license.license)));
    a.setAttribute('download',  generateFilename(accountName, license));

    a.style.display = 'none';
    document.body.appendChild(a);

    a.click();

    document.body.removeChild(a);
  };

  const wrapString = (license: string) => {
    return license.replace(/(?![^\n]{1,80}$)([^\n]{1,80})/g, '$1\n');
  }

  return (
    <TasktopTableWrapper>
      <MaterialTable
        title="Licenses"
        columns={[
          { title: 'Edition', field: 'edition' },
          { title: 'Version', field: 'version' },
          { title: 'Type', field: 'type' },
          { title: 'Created', field: 'created', defaultSort: "desc" },
          { title: 'Expiry', field: 'expiry' },
          { title: 'Connectors', field: 'connectors' },
          { title: 'Addons', field: 'addons' },
          { title: 'Replay', field: 'replay' },
          { title: 'Download', field: 'download' }
        ]}
        data={props.licenses.map((license: any) => ({
          key: license.licenseId,
          id: license.licenseId,
          edition: license.edition,
          version: license.licenseVersion,
          type: license.licenseType,
          created: license.createdAt,
          expiry: license.expiryDate,
          connectors: license.connectors,
          addons: license.addons,
          replay: <ReplayButton label="replay" onClick={() => replayLicensePage(license.licenseId)} />,
          download: <DownloadButton label="download" onClick={() => downloadLicense(license, props.accountName)} />
        }))}
        actions={[{
          icon: 'add',
          tooltip: 'Create New',
          position: 'toolbar',
          onClick: () => {
            props.onCreateLicense();
          },
        }]}
      />
    </TasktopTableWrapper>
  );
};

export const generateFilename = (accountName: string, license: any) => {
  const filename = replaceWhitespace(accountName) + '-' + license.edition + '-' + license.licenseType + '-' + license.createdAt + '.txt'
  return filename
}

const replaceWhitespace = (phrase: string) => {
  return phrase.replace(/\s/g, '+');
}

export default LicensesTable;
