import React from 'react';
import { TableRow, TableCell } from '@mui/material';
import { faEllipsisH, faUserSlash, faMailBulk, faBook } from '@fortawesome/free-solid-svg-icons';
import TableWrapper from '../../../layout/table/TableWrapper';
import DeleteButton from '../../../widgets/buttons/delete/DeleteButton';
import GenericButton from '../../../widgets/buttons/generic/GenericButton';
import { Link } from 'react-router-dom';

export type NewCustomerType = {
  userId: string,
  custId: string,
  name: string,
  email: string,
  emailVerified: boolean,
}

type CustomerTableProps = {
  customers: NewCustomerType[],
  verifyCustomer: (event: React.MouseEvent<HTMLButtonElement>) => void,
  manageCustomerReleases: (event: React.MouseEvent<HTMLButtonElement>) => void,
  customerDetails: (event: React.MouseEvent<HTMLButtonElement>) => void,
  deleteCustomer: (event: React.MouseEvent<HTMLButtonElement>) => void,
  totalCustomers: number,
  page: number,
  perPage: number,
  changePage: (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => void
};

const CustomersTable: React.FC<CustomerTableProps> = ({
  customers, verifyCustomer, customerDetails, manageCustomerReleases, deleteCustomer, totalCustomers, page, perPage, changePage
}: CustomerTableProps) => {

  const headColumns = (
    <>
      <TableCell>Name</TableCell>
      <TableCell>Email</TableCell>
      <TableCell align="center">Email Verified</TableCell>
      <TableCell align="center">Actions</TableCell>
    </>
  );
  const tableContents = customers.map((customer: NewCustomerType) => (
    <TableRow key={customer.custId}>
      <TableCell
        component="th"
        scope="row"
      >
        <Link to={{ pathname: `/customers/${customer.custId}` }}>
          {customer.name}
        </Link>
      </TableCell>
      <TableCell>
        {customer.email}
      </TableCell>
      <TableCell align="center">
        {customer.emailVerified ? 'Yes' : 'No'}
      </TableCell>
      <TableCell align="center" width="200px">
        <GenericButton onClick={verifyCustomer} title="Send Email" value={customer.custId} icon={faMailBulk} />
        <GenericButton onClick={customerDetails} title="Details" value={customer.custId} icon={faEllipsisH} />
        <GenericButton onClick={manageCustomerReleases} title="Manage Releases" value={JSON.stringify(customer)} icon={faBook} />
        <DeleteButton onClick={deleteCustomer} title="Delete User" value={customer.custId} icon={faUserSlash} />
      </TableCell>
    </TableRow>
  ));

  return (
    <TableWrapper
      headColumns={headColumns}
      tableContents={tableContents}
      page={page}
      handleChangePage={changePage}
      rowsPerPage={perPage}
      total={totalCustomers}
    />
  );
};

export default CustomersTable;
