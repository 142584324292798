import React from 'react'
import _ from 'lodash';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { Auth0ContextInterface, useAuth0 } from '@auth0/auth0-react'
import { List, ListItem, ListItemText, ListItemIcon, TextField } from '@mui/material'
import { ChevronRight } from '@mui/icons-material';
import { faBuilding, faCheckCircle, faMailBulk, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { CUSTOMER } from '../../../../client/queries/customers';
import { GET_USER_ROLES } from '../../../../client/queries/users';
import { SEARCH_PAGINATED_RELEASES_BY_CUSTOMER_ID, TOGGLE_RELEASE_CUSTOMER_ACCESS } from '../../../../client/queries/releases';

import { PaginatedReleasesByCustomerIdQuery } from '../../../layout/drawers/searchReleases/CustomersSearchReleasesDrawer';
import { AuthRoleType } from '../../../layout/sidebar/Sidebar';
import AdminAccess from '../../../AdminAccess';
import NoMatch from '../../../layout/nomatch/NoMatch';
import PrimaryButton from '../../../widgets/buttons/primary/PrimaryButton';
import SecondaryButton from '../../../widgets/buttons/secondary/SecondaryButton';
import InfoBar from '../../../widgets/infobar/InfoBar';
import BarLoader from '../../../widgets/loaders/barLoader/BarLoader';
import CustomerReleasesTable from '../customerReleasesTable/CustomerReleasesTable';

import { CustomerReleasePageType, CustomerReleaseType, ReleaseCustomerType } from '../../../releases/types';
import { defaultSnackbarState, SnackbarType } from '../../../widgets/infobar/types';
import { Account, Customer, CustomerAccount, Organization } from '../../Customers';
import { SecureArea } from '../customersDetails/CustomersDetails';

import styles from './CustomerPage.module.css';

const CustomersPage: React.FC = () => {

  const { isAuthenticated, user }: Auth0ContextInterface = useAuth0();
  const user_id = user?.sub;

  const { id } = useParams();
  const perPage = 10;

  const [page, setPage] = React.useState<number>(0);
  const [inputString, setInputString] = React.useState<string>('');
  const [paginatedReleasesByCustomerIdQuery, setPaginatedReleasesByCustomerIdQuery] = React.useState<PaginatedReleasesByCustomerIdQuery>({
    page: 0,
    perPage: perPage,
    customerId: id,
    search: ''
  });
  const [snackbar, setSnackbar] = React.useState<SnackbarType>(defaultSnackbarState);


  const {
    loading: userRolesLoading,
    error: userRolesError,
    data: userRolesData
  } = useQuery<{ customerRoles: AuthRoleType[] }>(GET_USER_ROLES, {
    variables: { id: user_id }
  });

  const {
    loading: customerLoading,
    error: customerError,
    data: customerData
  } = useQuery<{ customer: Customer }>(CUSTOMER, {
    variables: { customerId: id }
  });

  const {
    loading: paginatedCustomerReleasesLoading,
    error: paginatedCustomerReleasesError,
    data: paginatedCustomerReleasesData
  } = useQuery<{
    searchPaginatedActiveReleasesByCustomerId: CustomerReleasePageType
  }>(SEARCH_PAGINATED_RELEASES_BY_CUSTOMER_ID, {
    variables: paginatedReleasesByCustomerIdQuery,
    notifyOnNetworkStatusChange: true
  });

  const [toggleReleaseAccessForCustomer] = useMutation<{
    toggleReleaseCustomer: { releaseCustomer: ReleaseCustomerType }
  }>(TOGGLE_RELEASE_CUSTOMER_ACCESS, {
    refetchQueries: [
      {
        query: SEARCH_PAGINATED_RELEASES_BY_CUSTOMER_ID,
        variables: {
          customerId: id,
          page: page,
          perPage: perPage,
          search: inputString,
        }
      }
    ]
  });

  const handlePageChange = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
    setPage(newPage);
    setPaginatedReleasesByCustomerIdQuery((prev) => ({ ...prev, page: newPage }));
  }

  const toggleStatus = async (customerId: string, releaseId: string) => {
    try {
      const { data } = await toggleReleaseAccessForCustomer({
        variables: {
          customerId: customerId,
          releaseId: releaseId
        }
      });

      if (data) {
        const disabled: boolean = data.toggleReleaseCustomer.releaseCustomer.disabled;
        const message = (disabled) ? 'Release Customer Access Disabled!' : 'Release Customer Access Enabled!';
        const severity = (disabled) ? 'warning' : 'success';
        setSnackbar({ isOpen: true, message, severity });
      }
    } catch (e) {
      setSnackbar({ isOpen: true, message: 'Problem enabling release. Please try again.', severity: 'error' });
    }
  }

  const onEnabledToggleChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    const customerRelease: CustomerReleaseType = JSON.parse(event.currentTarget.value);
    toggleStatus(id!, customerRelease.release.id);
  }

  const closeSnackbar = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') return;
    setSnackbar({ ...snackbar, isOpen: false });
  };

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputString(event.currentTarget.value);
  }

  const handleFilterSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setPage(0);
    setPaginatedReleasesByCustomerIdQuery((prev) => ({ ...prev, search: inputString, page: 0 }));
  }

  const resetCustomerReleaseTable = () => {
    setInputString('');
    setPage(0);
    setPaginatedReleasesByCustomerIdQuery((prev) => ({ ...prev, search: '', page: 0 }));
  }


  if (userRolesLoading || customerLoading) return (<BarLoader />);
  if (userRolesError || customerError) return (<NoMatch />);

  const customer: Customer | null = customerData === undefined ? null : customerData.customer;
  const secureAreas: SecureArea[] = customer ? _.uniqBy(
    customer.customerAccounts
      .flatMap((customerAccount: CustomerAccount) => customerAccount.accounts)
      .flatMap((account: Account) => account.secureAreas)
      .flatMap((secureArea: SecureArea) => ({ ...secureArea, id: secureArea.name + secureArea.path })), 'path')
    : [];

  const organizations: Organization[] = customer ? _.uniqBy(
    customer.customerAccounts
      .flatMap((customerAccount: CustomerAccount) => customerAccount.organizationId), 'name')
    : [];

  return <>
    {
      isAuthenticated
        ? (
          <AdminAccess
            role='admin'
            data={userRolesData?.customerRoles}
            yes={() => (
              <div className={styles.customer_page_container}>

                <h2>
                  {customer?.name}
                </h2>

                <InfoBar
                  isOpen={snackbar.isOpen}
                  message={snackbar.message}
                  severity={snackbar.severity}
                  closeSnackbar={closeSnackbar} />

                <div className={styles.customer_page_details_container}>
                  {/* CustomerDetails */}
                  <div className={styles.customer_info_container}>
                    <div className={styles.container_title_wrapper}>
                      <h3>Customer Details</h3>
                    </div>

                    <List>
                      <ListItem>
                        <FontAwesomeIcon fixedWidth icon={faMailBulk} />
                        <ListItemText primary={customer?.email} />
                      </ListItem>
                      {organizations.map((organization: Organization) => (
                        <ListItem
                          key={organization.name}>
                          <FontAwesomeIcon fixedWidth icon={faBuilding} />
                          <ListItemText primary={organization.name}/>
                        </ListItem>))}
                      <ListItem>
                        {
                          customer?.emailVerified ?
                            <FontAwesomeIcon fixedWidth color="#82bc00" icon={faCheckCircle} />
                            : <FontAwesomeIcon fixedWidth color="#d36161" icon={faTimesCircle} />
                        }
                        <ListItemText primary={
                          customer?.emailVerified ?
                            'Email verification completed' : 'Email has not been verified'
                        } />
                      </ListItem>
                    </List>
                  </div>

                  {/* Customer content space  */}
                  <div className={styles.cusotmer_content_space_container}>
                    <div className={styles.container_title_wrapper}>
                      <h3>Content Spaces</h3>
                    </div>
                    <List>
                      {secureAreas.map((secureArea: SecureArea) => (
                        <ListItem key={secureArea.id}>
                          <ListItemIcon>
                            <ChevronRight />
                          </ListItemIcon>
                          <ListItemText
                            primary={secureArea.name}
                            secondary={secureArea.path}
                          />
                        </ListItem>
                      ))}
                    </List>
                  </div>
                </div>

                {/* license table */}
                <form onSubmit={handleFilterSubmit} autoComplete="off">
                  <TextField
                    variant="standard"
                    id="standard-search"
                    label="Search for..."
                    type="search"
                    value={inputString}
                    onChange={handleFilterChange} />
                  <PrimaryButton label="Search" type="submit" />
                  <SecondaryButton label="Reset" type="reset" onClick={resetCustomerReleaseTable} />
                </form>
                <div>
                  {(paginatedCustomerReleasesLoading
                    ? <BarLoader />
                    : paginatedCustomerReleasesError
                      ? <NoMatch />
                      : <CustomerReleasesTable
                        customerReleases={paginatedCustomerReleasesData === undefined
                          ? []
                          : paginatedCustomerReleasesData.searchPaginatedActiveReleasesByCustomerId.customerReleases}
                        total={
                          paginatedCustomerReleasesData === undefined
                            ? 0
                            : paginatedCustomerReleasesData.searchPaginatedActiveReleasesByCustomerId.total
                        }
                        page={page}
                        perPage={perPage}
                        handleChangePage={handlePageChange}
                        onEnabledToggleChanged={onEnabledToggleChanged} />)}
                </div>

              </div>
            )
            }
            no={() => <NoMatch />}
          />
        )
        : <p>Not Authenticated</p>
    }
  </>;
}

export default CustomersPage
