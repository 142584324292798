import { ApolloError } from '@apollo/client'
import { TextField } from '@mui/material'
import React from 'react'
import GenericRadioGroup from '../../layout/radioFields/GenericRadioGroup'
import PrimaryButton from '../buttons/primary/PrimaryButton'
import SecondaryButton from '../buttons/secondary/SecondaryButton'
import { ErrorContainer, FormContainer } from './SearchForm.styles'

export type SearchVariables = {
  search: string,
  searchType: string,
  page: number
}
interface SearchFormProps {
  error?: ApolloError,
  setSearchVars: React.Dispatch<React.SetStateAction<SearchVariables>>,
  setPage: (page: number) => void,
}

const SearchForm: React.FC<SearchFormProps> = (props: SearchFormProps) => {
  const {setSearchVars, setPage, error } = props
  const [inputString, setInputString] = React.useState<string>('');
  const [inputErrorMessage, setInputErrorMessage] = React.useState<string>('');

  const spacesEscapedString = (iS: string) => {
    return iS.replace(/\s/g, String.raw`\ `);
  }

  const [radioValue, setRadioValue] = React.useState('name');

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRadioValue((event.target as HTMLInputElement).value);
  };

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setInputString(event.currentTarget.value);
  };

  const searchVars = {
    search: spacesEscapedString(inputString),
    searchType: radioValue,
    page: 0
  }

  const resetVars = {
    search: '',
    searchType: '',
    page: 0
  }

  const searchFields = [
    {
      value: 'name',
      label: "Name"
    },
    {
      value: 'email',
      label: "Email"
    }
  ]

  const resetTable = () => {
    setInputString('');
    setInputErrorMessage('');
    setPage(0)
    setRadioValue('name')
    setSearchVars(resetVars)
  };

  const handleFilterSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    if (inputString.length > 0 && inputString.length < 3) {
      event.preventDefault();
      setInputErrorMessage('Type at least 3 characters to continue the search.');
    } else {
      event.preventDefault();
      setInputErrorMessage('');
      setPage(0)
      setSearchVars(searchVars)
    }
  };

  return (
    <FormContainer>
      {error ? (
        <ErrorContainer>
          <h2>An error has occurred!</h2>
          <p>Give it another shot.</p>
          <SecondaryButton label="Reset" type="reset" onClick={resetTable} />
        </ErrorContainer>
      ) : (
        <form onSubmit={handleFilterSubmit} autoComplete="off">
          <TextField
            variant="standard"
            id="standard-search"
            label="Search for..."
            type="search"
            value={inputString}
            onChange={handleFilterChange} />
            <GenericRadioGroup
              value={radioValue}
              label=''
              required
              row
              fields={searchFields}
              handleChange={handleInputChange}
            />
          <PrimaryButton label="Search" type="submit" className='searchButton'/>
          <SecondaryButton label="Reset" type="reset" onClick={resetTable} />
          {inputErrorMessage}
        </form>
      )}
    </FormContainer>
  );
}

export default SearchForm
