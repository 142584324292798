import React from 'react';
import { Auth0ContextInterface, useAuth0 } from '@auth0/auth0-react';

import User from '../user/User';
import Login from '../login/Login';

import { Authentication, HeaderContainer, PageTitle } from './Header.styles';

type HeaderProps = {
    pageTitle: string,
};

const Header: React.FC<HeaderProps> = ({ pageTitle }: HeaderProps) => {
  const { isAuthenticated }: Auth0ContextInterface = useAuth0();
  return (
    <HeaderContainer>
      <PageTitle>
        <div />
        <span>{pageTitle}</span>
      </PageTitle>
      <Authentication>
        {isAuthenticated ? <User /> : <Login />}
      </Authentication>
    </HeaderContainer>
  );
};

export default Header;
