import React, {useState} from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { ApolloClient, ApolloProvider, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

import App from './App';
import BarLoader from './components/widgets/loaders/barLoader/BarLoader';
import './App.css';
import { getCookieConsentValue } from "react-cookie-consent";

const ApolloWrapper: React.FC = () => {
  const { isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [ hasCsrf, setHasCsrf ] = useState<boolean>(false);

  if (!isLoading && isAuthenticated ) {
    getAccessTokenSilently({audience: process.env.REACT_APP_API_URI})
      .then((apiToken) => {
        if (process.env.REACT_APP_API_CSRF) {
          fetch(process.env.REACT_APP_API_CSRF, {
            credentials: 'include',
            headers: {
              'Authorization': `Bearer ${apiToken}`
            }
          }).then(() => setHasCsrf(true));
        }
      })
  }

  const httpLink = createHttpLink({
    uri: process.env.REACT_APP_API_URI,
    credentials: 'include',
  });

  const authLink = setContext(async (_, { headers }) => {
    const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_API_URI });
    const csrfToken = getCookieConsentValue('csrftoken');

    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
        'X-CSRFToken': csrfToken
      }
    }
  });

  const client = new ApolloClient({
    cache: new InMemoryCache(),
    connectToDevTools: process.env.NODE_ENV === 'development',
    link: authLink.concat(httpLink),
    credentials: 'include',
  });

  if ( isLoading ) {
    return (
      <div className="app_loading">
        <BarLoader/>
      </div>
    );
  }

  return (
    <ApolloProvider client={client}>
      { isAuthenticated && !hasCsrf ?
        <div className="app_loading">
          <BarLoader/>
        </div> :
        <App />
      }
    </ApolloProvider>
  );

};

export default ApolloWrapper;
