import React from 'react';
import { FormControl, FormGroup, FormControlLabel } from '@mui/material';
import { TasktopSwitch } from './Switch.styles';
import { RoleType } from '../../accounts/elements/accountsPage/AccountsPage';

interface RolesSwitchProps {
  roles: RoleType[],
  handleChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void,
}

const SwitchesGroupRoles: React.FC<RolesSwitchProps> = (props: RolesSwitchProps) => {
  const { handleChange, roles } = props;

  return (
    <FormControl variant="standard" component="fieldset">
      <FormGroup>
        {roles.map((role: RoleType) => (
          <FormControlLabel
            key={role.roleId}
            control={
              <TasktopSwitch onChange={handleChange} name={String(role.roleId)} />
            }
            label={role.name}
          />
        ))}
      </FormGroup>
    </FormControl>
  );
}

export default SwitchesGroupRoles
