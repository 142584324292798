import React, { useEffect } from 'react';

import { TableRow, TableCell } from '@mui/material';
import TableWrapper from '../../../layout/table/TableWrapper';
import EditButton from '../../../widgets/buttons/edit/EditButton';
import { ContentSpaceType } from '../../types';

type ContentSpacesTableProps = {
  spaces: ContentSpaceType[],
  updateSpace: (event: React.MouseEvent<HTMLButtonElement>) => void,
};

const ContentSpacesTable: React.FC<ContentSpacesTableProps> = ({ spaces, updateSpace }: ContentSpacesTableProps) => {
  const [page, setPage] = React.useState(0);
  const rowsPerPage = 10;

  useEffect(() => {
    setPage(0)
  }, [spaces])

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
    setPage(newPage);
  };

  const headColumns = (
    <>
      <TableCell>Name</TableCell>
      <TableCell>Path</TableCell>
      <TableCell align="right" />
    </>
  );
  const tableContents = spaces.map((space: ContentSpaceType) => (
    <TableRow key={space.secureAreaId}>
      <TableCell
        component="th"
        scope="row"
      >
        {space.name}
      </TableCell>
      <TableCell>
        {space.path}
      </TableCell>
      <TableCell align="right">
        <EditButton title="Update Content Space" onClick={updateSpace} value={space.secureAreaId} />
      </TableCell>
    </TableRow>
  ));

  return (
    <TableWrapper
      headColumns={headColumns}
      tableContents={tableContents}
      page={page}
      handleChangePage={handleChangePage}
      rowsPerPage={rowsPerPage}
    />
  );
};

export default ContentSpacesTable;
