import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import SecondaryButton from '../secondary/SecondaryButton';

const SignUpButton: React.FC = () => {
  const { loginWithRedirect } = useAuth0();

  const signUp = 'Sign Up';

  return (
    <SecondaryButton
      label={signUp}
      onClick={() => loginWithRedirect({ mode: 'signUp' })}
    />
  );
};

export default SignUpButton;
