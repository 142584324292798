import { gql } from '@apollo/client';

export const SEARCH_CUSTOMER_RELEASES = gql`
  query SearchCustomerReleases($userId: String, $customerId: Int, $search: String, $initialDate: DateTime, $endDate: DateTime,
  $product: String, $version: String, $securityFixesOnly: Boolean) {
    searchCustomerReleases(userId: $userId, id: $customerId, search: $search, initialDate: $initialDate, endDate: $endDate,
    product: $product, version: $version, securityFixesOnly: $securityFixesOnly){
    id
    licenseAcceptanceDate
    release {
      id
      name
      licenseTitle
      licenseText
      releaseDate
      security
      releaseFiles {
        id
        description
        filePath
        checksum
      }
      productId {
        id
        name
      }
    }
  }
}
`;

export const PRODUCTS_BY_CUSTOMER = gql`
  query ProductsByCustomer($userId: String, $id: Int){
  productsByCustomer(userId: $userId, id: $id) {
    name
  }
}
`;

export const VERSIONS_BY_CUSTOMER_BY_PRODUCT = gql`
  query VersionByCustomerByProduct($userId: String, $id: Int, $product: String, $top: Int) {
    versionsByCustomerByProduct(userId: $userId, id: $id, product: $product, top: $top)
  }
`;

export const ACCEPT_LICENSE = gql`
  mutation AcceptLicense ($customerId: Int, $userId: String, $releaseId: Int) {
    acceptLicense(customerId: $customerId, userId: $userId, releaseId: $releaseId) {
      releaseCustomer {
        id
        licenseAcceptanceDate
        release {
          name
        }
      }
    }
  }
`;
