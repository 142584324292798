import React from 'react';
import { TableRow, TableCell } from '@mui/material';
import SwitchesGroupRoles from '../../widgets/switches/SearchResultsRolesGroup';
import { CustomerRoleAssociations } from '../drawers/searchCustomers/AccountsSearchCustomerDrawer';
import { RoleType } from '../../accounts/elements/accountsPage/AccountsPage';

interface AccountsTableRowProps {
  customerID: number,
  name: string,
  email: string,
  rolesDB: RoleType[],
  collectObjects: (customer: CustomerRoleAssociations) => void,
}

const AccountsTableRow: React.FC<AccountsTableRowProps> = (props: AccountsTableRowProps) => {
  const { customerID, name, email, collectObjects, rolesDB } = props
  const [custRoles, setCustRoles] = React.useState<number[]>([]);

  React.useEffect(() => {
    const customer: { custId: number, roles: number[] } = {
      custId: customerID,
      roles: custRoles
    }

    collectObjects(customer)
  }, [collectObjects, custRoles, customerID])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    const targetInt = parseInt(event.target.name)

    if (checked) {
      setCustRoles([...custRoles, targetInt]);
    }

    if (!checked) {
      if (custRoles.includes(targetInt)) {
        const filteredArr = custRoles.filter(n => n !== targetInt)
        setCustRoles(filteredArr)
      }
    }
  };

  return (
    <TableRow key={customerID}>
      <TableCell
        component="th"
        scope="row"
      >
        <p>{name}</p>
        <p>{email}</p>
      </TableCell>
      <TableCell>
        <SwitchesGroupRoles roles={rolesDB} handleChange={handleChange} />
      </TableCell>
    </TableRow>
  )
}

export default AccountsTableRow
