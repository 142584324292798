import React from 'react';
import { FormControlLabel, Radio } from '@mui/material';
import { RadioData } from './radioTypes'

const GenericRadioButton: React.FC<RadioData> = (props: RadioData) => (
  <FormControlLabel
    value={props.value}
    control={<Radio
      sx={{
        color: '#82bc00',
        '&.Mui-checked': {
          color: "#82bc00",
        },
      }}
    />}
    label={props.label}
  />
);

export default GenericRadioButton;
