import React from 'react';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';

import GenericButton from '../generic/GenericButton';
import styles from './DetailsButton.module.css';

type DetailsButtonProps = {
    label?: string
    onClick?: () => void,
    value?: string,
    title?: string
};

const DetailsButton: React.FC<DetailsButtonProps> = (props: DetailsButtonProps) => (
  <GenericButton
    className={styles.button}
    icon={faEllipsisH}
    label={props.label}
    onClick={props.onClick}
    value={props.value}
    title={props.title ? props.title : 'Details'}
  />
);

export default DetailsButton;
