import React from 'react';
import { Dialog, DialogContent, DialogActions, DialogTitle, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import PrimaryButton from '../buttons/primary/PrimaryButton';
import SecondaryButton from '../buttons/secondary/SecondaryButton';
import styles from './ConfirmWithOptionsDialog.module.css';

type RoleType = {
    roleId: number,
    name: string
}

type ConfirmWithOptionsDialogProps = {
    isOpen: boolean,
    setOpen: (isOpen: boolean) => void,
    title: string,
    message: string,
    data: RoleType[],
    role: string,
    handleRoleChange: (event: any) => void,
    handleCancel: () => void,
    handleOk: (event: any) => void
};

const ConfirmWithOptionsDialog: React.FC<ConfirmWithOptionsDialogProps> = (props: ConfirmWithOptionsDialogProps) => {

  return (
    <Dialog
      onClose={
        (event, reason) => {
          if(reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
            props.setOpen(false);
          }
        }
      }
      maxWidth="xs"
      aria-labelledby="confirmation-dialog-title"
      open={props.isOpen}
    >
      <DialogTitle id="confirmation-dialog-title">{props.title}</DialogTitle>
      <DialogContent dividers>
        {props.message}
        <form onSubmit={() => null} autoComplete="off">
          <div className={styles.form_drawer}>
            <p>Please select a role.</p>
            <FormControl variant="outlined" required>
              <InputLabel id="demo-simple-select-filled-label">Role</InputLabel>
              <Select
                variant="standard"
                value={props.role}
                label="Role"
                onChange={props.handleRoleChange}>
                {props.data.map((role: RoleType) => (
                  <MenuItem value={role.roleId} key={role.roleId}>{role.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </form>
      </DialogContent>
      <DialogActions>
        <SecondaryButton onClick={props.handleCancel} label="Cancel" />
        <PrimaryButton onClick={props.handleOk} label="Delete" />
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmWithOptionsDialog;
