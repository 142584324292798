import React from 'react';
import { Auth0ContextInterface, useAuth0 } from '@auth0/auth0-react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { CUSTOMER_BY_USER_ID, SEND_PASSWORD_RESET_EMAIL, UPDATE_CUSTOMER } from "../../client/queries/customers";
import styles from './AccountSettings.module.css';
import BarLoader from "../widgets/loaders/barLoader/BarLoader";
import NoMatch from "../layout/nomatch/NoMatch";
import GenericDrawer from "../layout/drawers/GenericDrawer";
import { FormField } from "../../types/types";
import SettingsDetails from "../accountSettings/elements/settingsDetails/settingsDetails";
import PasswordReset from "../accountSettings/elements/passwordReset/passwordReset";
import InfoBar from "../widgets/infobar/InfoBar";
import { defaultSnackbarState, SnackbarType } from '../widgets/infobar/types';

type UpdateUserState = {
  email?: string,
  name?: string,
  userMetadata: {
    newsletter: string,
    releaseUpdates: string,
  }
};

const AccountSettings: React.FC = () => {
  const { isAuthenticated, user }: Auth0ContextInterface = useAuth0();
  const userEmail = user?.email;
  const userId = user?.sub;
  const [sendPasswordEmail] = useLazyQuery(SEND_PASSWORD_RESET_EMAIL, { variables: { email: userEmail } })
  const [updateCustomer] = useMutation(UPDATE_CUSTOMER);
  const [isSettingsDrawerOpen, setIsSettingsDrawerOpen] = React.useState(false);
  const [snackbar, setSnackbar] = React.useState<SnackbarType>(defaultSnackbarState);
  const [customer, setCustomer] = React.useState<UpdateUserState>({
    email: '',
    name: '',
    userMetadata: {
      newsletter: '',
      releaseUpdates: 'noUpdates',
    },
  });

  const [updatingCustomer, setUpdatingCustomer] = React.useState<UpdateUserState>({
    email: '',
    name: '',
    userMetadata: {
      newsletter: '',
      releaseUpdates: 'noUpdates',
    },
  });

  const helperFunction = (user: UpdateUserState) => {
    setCustomer({
      name: user?.name,
      email: userEmail,
      userMetadata: {
        newsletter: user.userMetadata.newsletter,
        releaseUpdates: user.userMetadata.releaseUpdates,
      }
    })
  }

  const {
    loading: getCustomerLoading,
    error: getCustomerError,
    data: getCustomer,
  } = useQuery(CUSTOMER_BY_USER_ID, {
    variables: { userId: userId },
    onCompleted: (getCustomer) => {
      helperFunction(getCustomer.customerByUserId)
    }
  });

  const closeSnackbar = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') return;
    setSnackbar({ ...snackbar, isOpen: false });
  };

  if (getCustomerLoading) return (<BarLoader />);
  if (getCustomerError) return (<NoMatch />);

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUpdatingCustomer({ ...updatingCustomer, name: event.currentTarget.value });
  };

  const handleSettingsNewsletterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUpdatingCustomer({
      ...updatingCustomer,
      userMetadata: {
        newsletter: event.currentTarget.checked.toString(),
        releaseUpdates: updatingCustomer.userMetadata.releaseUpdates,
      }
    });
  };

  const handleSettingsReleaseUpdatesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUpdatingCustomer({
      ...updatingCustomer,
      userMetadata: {
        newsletter: updatingCustomer.userMetadata.newsletter,
        releaseUpdates: event.currentTarget.value,
      }
    });
  };

  const settingsFields: FormField[] = [
    {
      value: updatingCustomer.name ? updatingCustomer.name : '',
      label: 'Name',
      fieldType: 'textField',
      required: false,
      handleChange: handleNameChange
    },
    {
      value: updatingCustomer.userMetadata.newsletter,
      label: 'I would like to receive marketing emails',
      fieldType: 'checkboxField',
      required: false,
      checked: updatingCustomer.userMetadata.newsletter === "true",
      handleChange: handleSettingsNewsletterChange
    },
    {
      value: updatingCustomer.userMetadata.releaseUpdates,
      label: 'Release Updates Preferences',
      fieldType: 'radioGroup',
      handleChange: handleSettingsReleaseUpdatesChange,
      fields: [
        {
          value: "productUpdates",
          label: "I would like to receive all product updates",
        },
        {
          value: "securityUpdates",
          label: "I would only like to receive security-related product updates",
        },
        {
          value: "noUpdates",
          label: "I do not wish to receive product updates",
        },
      ],
      required: false,
    },
  ];

  const updateSettings = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      await updateCustomer({
        variables: {
          userId: userId,
          name: updatingCustomer.name,
          newsletter: updatingCustomer.userMetadata.newsletter,
          releaseUpdates: updatingCustomer.userMetadata.releaseUpdates,
        }
      });
      setIsSettingsDrawerOpen(false);
      setCustomer(updatingCustomer);

      setSnackbar({ isOpen: true, message: 'Settings updated successfully.', severity: 'success' });
    } catch (e) {
      setSnackbar({ isOpen: true, message: 'Problem updating settings. Please try again.', severity: 'error' });
    }
  };

  const openUpdateDrawer = () => {
    setUpdatingCustomer(customer);
    setIsSettingsDrawerOpen(true);
  };

  const sendPasswordReset = () => {
    try {
      sendPasswordEmail();
      setSnackbar({ isOpen: true, message: 'Email sent successfully to: ' + userEmail, severity: 'success' });
    } catch (e) {
      setSnackbar({ isOpen: true, message: 'Problem sending email. Please try again.', severity: 'error' });
    }
  };

  return (
    <>
      {isAuthenticated ?
        (<div className={styles.accountSettings}>
          <InfoBar
            isOpen={snackbar.isOpen}
            message={snackbar.message}
            closeSnackbar={closeSnackbar}
            severity={snackbar.severity}
          />
          <GenericDrawer
            formFields={settingsFields}
            drawerTitle="Update Account"
            isDrawerOpen={isSettingsDrawerOpen}
            closeDrawerCallback={setIsSettingsDrawerOpen}
            submitForm={updateSettings}
          />
          <div className={styles.account_container}>
            <h2 className={styles.account_title}>
              Account Settings
            </h2>
            <SettingsDetails
              name={customer.name}
              email={customer.email}
              newsletter={customer.userMetadata.newsletter}
              releaseUpdates={customer.userMetadata.releaseUpdates}
              onClick={openUpdateDrawer}
            />
            <PasswordReset
              userId={userId}
              onClick={sendPasswordReset}
            />
          </div>
        </div>)
        : null}
    </>
  );
}

export default AccountSettings;
