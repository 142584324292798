import React from 'react';
import { List, ListItem, ListItemText, ListItemIcon, ListItemSecondaryAction, Card, IconButton } from '@mui/material'
import { Lock, Send } from '@mui/icons-material'
import styles from './passwordReset.module.css';

type PasswordResetProps = {
    userId?: string,
    onClick: () => void,
};

const PasswordReset: React.FC<PasswordResetProps> = (props: PasswordResetProps) => {

    return (
        <Card elevation={0} className={styles.password_email}>
            <List>
                <ListItem>
                    <ListItemIcon>
                        <Lock />
                    </ListItemIcon>
                    <ListItemText secondary={"Send an email to reset your password"}
                    />
                    <ListItemSecondaryAction>
                        <IconButton edge="end" aria-label="send button" onClick={props.onClick} size="large">
                            <Send />
                        </IconButton>
                    </ListItemSecondaryAction>
                </ListItem>
            </List>
        </Card>
    );
};

export default PasswordReset;
