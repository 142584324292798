import React from 'react';
import { Auth0ContextInterface, useAuth0 } from '@auth0/auth0-react';

import { useLazyQuery, useMutation, useQuery } from '@apollo/client';

import { TextField } from '@mui/material';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { ADD_ACCOUNT, SEARCH_ACCOUNTS } from '../../client/queries/accounts';

import { GET_SALESFORCE_ID } from '../../client/queries/salesforce';
import { GET_USER_ROLES } from '../../client/queries/users';
import GenericDrawer from '../layout/drawers/GenericDrawer';
import AdminAccess from '../AdminAccess';
import AccountsTable from './elements/accountsTable/AccountsTable';
import PrimaryButton from '../widgets/buttons/primary/PrimaryButton';
import SecondaryButton from '../widgets/buttons/secondary/SecondaryButton';
import NoMatch from '../layout/nomatch/NoMatch';
import InfoBar from '../widgets/infobar/InfoBar';
import BarLoader from '../widgets/loaders/barLoader/BarLoader';
import { FormField } from '../../types/types';

import styles from './Accounts.module.css';
import {AuthRoleType} from "../layout/sidebar/Sidebar";
import { defaultSnackbarState, SnackbarType } from '../widgets/infobar/types';

type NewAccountState = {
    name: string,
    accesscode: string,
    salesforceid: string,
};

type SalesforceAccountType = {
    id: string,
    name: string,
    link: string,
}

const Accounts: React.FC = () => {
  const { isAuthenticated, user }: Auth0ContextInterface = useAuth0();
  const user_id = user ? user.sub : console.log("user is undefined!");

  const symbols: string[] = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'.split('');
  const code: string[] = [];

  const [newAccount, setNewAccount] = React.useState<NewAccountState>({ name: '', accesscode: '', salesforceid: '' });
  const [isAddDrawerOpen, setIsAddDrawerOpen] = React.useState<boolean>(false);

  const [inputString, setInputString] = React.useState<string>('');
  const [inputSalesForceId, setInputSalesForceId] = React.useState<string>('');
  const [snackbar, setSnackbar] = React.useState<SnackbarType>(defaultSnackbarState);

  // Queries
  const { loading: adminLoading, error: adminError, data } = useQuery<{ customerRoles: AuthRoleType[] }>(GET_USER_ROLES, {
    variables: { id: user_id },
  });

  const {
    loading: queryLoading, error: queryError, data: accounts, refetch,
  } = useQuery(SEARCH_ACCOUNTS, {
    variables: { search: '' },
  });

  const [ validateSalesforceId] = useLazyQuery<{ searchCustomerId: SalesforceAccountType }>(GET_SALESFORCE_ID, {
    onCompleted: () => {
      handleAddAccount();
    },
    onError: e => {
      setSnackbar({ isOpen: true, message: e.message, severity: 'error' });
      setIsAddDrawerOpen(false);
    }
  });

  // Mutations
  const [addAccount] = useMutation(ADD_ACCOUNT, {
    refetchQueries: [{
      query: SEARCH_ACCOUNTS,
      variables: { search: '' },
    }],
  });

  async function handleAddAccount() {
    try {
      await addAccount({
        variables: {
          name: newAccount.name,
          accesscode: newAccount.accesscode,
          salesforceid: inputSalesForceId,
        },
      });
      setNewAccount({ name: '', accesscode: '', salesforceid: '' });
      setInputSalesForceId('');
      setSnackbar({ isOpen: true, message: 'Account saved!', severity: 'success' });
      setIsAddDrawerOpen(false);
    } catch (e) {
      setSnackbar({ isOpen: true, message: 'Problem saving account. Please try again.', severity: 'error' });
    }
  }

  const saveAccount = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (inputSalesForceId !== '') {
        validateSalesforceId({variables: { search: inputSalesForceId}});
    } else {
      setNewAccount({ ...newAccount, salesforceid: '' });
      handleAddAccount();
    }
  };

  const openAddDrawer = () => {
    setNewAccount({ ...newAccount, accesscode: generate() });
    setIsAddDrawerOpen(true);
  };

  const generate = () => {
    for (let i = 0; i < 12; i++) {
      const idx = Math.floor(Math.random() * symbols.length);
      code[i] = symbols[idx];
    }
    return code.join('');
  };

  const handleNewAccountNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewAccount({ ...newAccount, name: event.currentTarget.value });
  };

  const handleNewAccountAccessCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewAccount({ ...newAccount, accesscode: event.currentTarget.value });
  };

  const handleNewAccountSalesforceIDChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputSalesForceId(event.currentTarget.value)
  };

  // Filter Accounts
  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setInputString(event.currentTarget.value);
  };

  const handleFilterSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    refetch({ search: inputString });
  };

  const resetTable = async () => {
    setInputString('');
    refetch({ search: '' });
  };

  // Helpers
  const closeSnackbar = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') return;
    setSnackbar({ ...snackbar, isOpen: false });
  };

  if (queryLoading || adminLoading) return (<BarLoader />);
  if (queryError || adminError) return (<NoMatch />);

  // Populate forms
  const newAccountFields: FormField[] = [
    {
      value: newAccount.name,
      label: 'Account Name',
      fieldType: 'textField',
      required: true,
      handleChange: handleNewAccountNameChange,
    },
    {
      value: newAccount.accesscode,
      label: 'Access Code',
      fieldType: 'textField',
      required: true,
      handleChange: handleNewAccountAccessCodeChange,
    },
    {
      value: inputSalesForceId,
      label: 'Salesforce ID',
      fieldType: 'textField',
      required: false,
      handleChange: handleNewAccountSalesforceIDChange,
    },
  ];

  return (
    <div>
      {isAuthenticated
        ? (
          <AdminAccess
            role="admin"
            data={data?.customerRoles}
            yes={() => (
              <div className={styles.accounts}>
                <InfoBar
                  isOpen={snackbar.isOpen}
                  message={snackbar.message}
                  closeSnackbar={closeSnackbar}
                  severity={snackbar.severity}
                />
                <GenericDrawer
                  formFields={newAccountFields}
                  drawerTitle="Add Account"
                  isDrawerOpen={isAddDrawerOpen}
                  closeDrawerCallback={setIsAddDrawerOpen}
                  submitForm={saveAccount}
                />
                <PrimaryButton
                  icon={faPlus}
                  label="Add Account"
                  className={styles.add_button}
                  onClick={openAddDrawer}
                  type="button"
                />
                <form onSubmit={handleFilterSubmit} autoComplete="off">
                  <TextField
                    variant="standard"
                    id="standard-search"
                    label="Search for..."
                    type="search"
                    value={inputString}
                    onChange={handleFilterChange} />
                  <PrimaryButton label="Search" type="submit" />
                  <SecondaryButton label="Reset" type="reset" onClick={resetTable} />
                </form>
                <AccountsTable
                  accounts={accounts.searchAccounts}
                />
              </div>
            )}
            no={() => <NoMatch />}
          />
        )
        : null}
    </div>
  );
};

export default Accounts;
