import { styled } from '@mui/material/styles';
import { DrawerStyles } from '../Drawers.styles';

export const SearchCustomerDrawerStyles = styled(DrawerStyles)`
    width: 50vw;

  .tableForm {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .MuiPaper-root {
      box-shadow: unset;
    }
  }

  .saveButton {
    width: 250px;
    border: unset;
  }
`
