import React from 'react';
import { Auth0ContextInterface, useAuth0 } from '@auth0/auth0-react';
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import BarLoader from "../widgets/loaders/barLoader/BarLoader";
import NoMatch from '../layout/nomatch/NoMatch';
import styles from './Resources.module.css';
import InfoBar from '../widgets/infobar/InfoBar';
import ResourcesTable from './elements/resourcesTable/ResourcesTable';
import {
  CUSTOMER_BY_USER_ID,
  SECURE_AREAS_BY_CUSTOMER_ID
} from '../../client/queries/customers';
import GenericDrawer from '../layout/drawers/GenericDrawer';
import { FormField } from '../../types/types';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import PrimaryButton from '../widgets/buttons/primary/PrimaryButton';
import { GET_ACCOUNTS_BY_ACCESS_CODE, CREATE_ASSOCIATION } from '../../client/queries/accounts';
import { ContentSpaceType } from '../contentSpaces/types';
import { useEffect } from 'react';
import ResourcesHelp from './elements/resourcesHelp/ResourcesHelp';
import { CustomerType } from '../releases/types';
import { defaultSnackbarState, SnackbarType } from '../widgets/infobar/types';

interface ErrorType {
  message: string,
}

const Resources: React.FC = () => {
  const { isAuthenticated, user }: Auth0ContextInterface = useAuth0();
  const userId = user?.sub;
  let userHasResources = false;
  const [snackbar, setSnackbar] = React.useState<SnackbarType>(defaultSnackbarState);
  const [isActivationDrawerOpen, setIsActivationDrawerOpen] = React.useState<boolean>(false);
  const [inputToken, setInputToken] = React.useState<string>('');
  const [activationToken, setActivationToken] = React.useState<string>('');
  const [userResources, setUserResources] = React.useState<ContentSpaceType[]>([]);
  const DEFAUT_ROLE_ID = 1;

  const openActivationDrawer = () => {
    setIsActivationDrawerOpen(true);
  };

  const [
    getUserSecureAreas,
    { data: getSecureAreas },
  ] = useLazyQuery(SECURE_AREAS_BY_CUSTOMER_ID, {
    errorPolicy: "ignore",
    fetchPolicy: 'network-only',
  });

  // During the React 18 upgrade, this function was implemented as a workaround because calling the individual functions within inside of the onCompleted block were causing the page to break (as of now, this is unresolved, and there have been others that have experienced this issue - https://github.com/apollographql/apollo-client/issues/9846).

  const getCustomerHelperFunction = (customer: CustomerType) => {
    getUserSecureAreas({
      variables: { userId: customer.userId, id: customer.custId }
    });
  }

  const {
    loading: getCustomerLoading,
    error: getCustomerError,
    data: getCustomer,
    refetch: getCustomerRefetch
  } = useQuery(CUSTOMER_BY_USER_ID, {
    variables: { userId: userId },
    onCompleted: (getCustomer) => {
      getCustomerHelperFunction(getCustomer.customerByUserId) // See note above
    },
    notifyOnNetworkStatusChange: true,
  });

  const [
    getAccountsByAccessCode,
    { data: accountData }
  ] = useLazyQuery(GET_ACCOUNTS_BY_ACCESS_CODE, {
    variables: { accesscode: activationToken },
    onCompleted: async () => {
      handleAssociationQuery();
    },
    errorPolicy: "ignore"
  });

  const [createAssociation] = useMutation(CREATE_ASSOCIATION);

  useEffect(() => {
    if (getSecureAreas) {
      const secureAreas: Array<ContentSpaceType> = [];
      getSecureAreas.secureAreasByCustomerId.forEach(function (areas: ContentSpaceType) {
        secureAreas.push(areas);
      })
      setUserResources(secureAreas);
    }
  }, [getSecureAreas]);

  const closeSnackbar = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') return;
    setSnackbar({ ...snackbar, isOpen: false });
  };

  if (getCustomerLoading) return (<BarLoader />);
  if (!isAuthenticated || getCustomerError) return (<NoMatch />);
  if (getCustomer.customerByUserId.customerAccounts.length > 0) userHasResources = true;

  async function handleAssociationQuery() {

    try {
      await createAssociation({
        variables: {
          userId: userId,
          custId: getCustomer.customerByUserId.custId,
          accessCode: activationToken,
          roleId: DEFAUT_ROLE_ID,
        },
      })

      setSnackbar({ isOpen: true, message: 'Activation token accepted!', severity: 'success' });
      getCustomerRefetch();
    } catch (e) {
      const err = e as ErrorType
      if (err.message.indexOf("already") !== -1) {
        setSnackbar({ isOpen: true, message: err.message, severity: 'success' });
      } else {
        setSnackbar({ isOpen: true, message: err.message, severity: 'error' });
      }
    }
  }

  const submitToken = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setActivationToken(inputToken);
    getAccountsByAccessCode();
    setIsActivationDrawerOpen(false);
  }

  const handleChangeActivationToken = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setInputToken(event.currentTarget.value);
  };

  const enterActivationCode: FormField[] = [
    {
      value: inputToken,
      label: 'Activation Code',
      fieldType: 'textField',
      required: true,
      handleChange: handleChangeActivationToken,
    }
  ];

  return (
    <>
      <div className={styles.resources}>
        <InfoBar
          isOpen={snackbar.isOpen}
          message={snackbar.message}
          closeSnackbar={closeSnackbar}
          severity={snackbar.severity}
        />
        <PrimaryButton
          icon={faPlus}
          label="Enter Activation Code"
          className={styles.add_button}
          onClick={openActivationDrawer}
          type="button"
        />
        <GenericDrawer
          formFields={enterActivationCode}
          drawerTitle="Enter Activation Code"
          isDrawerOpen={isActivationDrawerOpen}
          closeDrawerCallback={setIsActivationDrawerOpen}
          submitForm={submitToken}
        />
        {userHasResources ?
          <ResourcesTable
            resources={userResources}
          />
          : <ResourcesHelp />}
      </div>
    </>
  );
}

export default Resources;
