import { Switch } from '@mui/material';
import { styled } from '@mui/material/styles';

export const TasktopSwitch = styled(Switch)(() => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: '#82bc00',
    '&:hover': {
      backgroundColor: 'rgba(130, 188, 0, 0.2)'
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: 'rgba(130, 188, 0, 0.8)'
  },
}));
