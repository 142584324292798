import React from "react";
import { Drawer, TextField } from "@mui/material";
import styles from "./LicenseAcceptanceDrawer.module.css";
import PrimaryButton from "../../../widgets/buttons/primary/PrimaryButton";
import SecondaryButton from "../../../widgets/buttons/secondary/SecondaryButton";
import { CustomerReleaseType, LicenseContextType } from "../../DownloadReleases";
import { useMutation } from "@apollo/client";
import { ACCEPT_LICENSE } from "../../../../client/queries/downloadReleases";

type LicenseAcceptanceDrawerProps = {
  licenseContext: LicenseContextType,
  isDrawerOpen: boolean,
  userId?: string,
  refetchCustomerReleases: () => void,
  closeDrawerCallback: (action: React.SetStateAction<boolean>) => void,
  downloadFile: (releaseId: number, releaseFileId: number) => void
};

const LicenseAcceptanceDrawer: React.FC<LicenseAcceptanceDrawerProps> = (props: LicenseAcceptanceDrawerProps) => {
  const [ acceptLicense ] = useMutation<{
    acceptLicense: {
      releaseCustomer: CustomerReleaseType
    }
  }>(ACCEPT_LICENSE, {
    variables: {
      userId: props.userId,
      customerId: props.licenseContext.customerId,
      releaseId: props.licenseContext.releaseId
    },
    onCompleted: () => {
      props.closeDrawerCallback(false);
      props.refetchCustomerReleases();
      props.downloadFile(props.licenseContext.releaseId, props.licenseContext.releaseFileId);
    },
    onError: () => {
      props.licenseContext.setSnackbar({
        isOpen: true,
        severity: "error",
        message: 'There was a problem accepting the license, please try again.'
      });
    }
  });

  const closeDrawer = (event: any, callback: (action: React.SetStateAction<boolean>) => void) => {
    if (
      event.type === 'keydown'
      && ((event as React.KeyboardEvent).key === 'Tab'
        || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    callback(false);
  };

  return (
    <Drawer
      anchor="right"
      open={props.isDrawerOpen}
      onClose={(event) => closeDrawer(event, props.closeDrawerCallback)}
    >
      <form
        autoComplete="off"
      >
        <div className={styles.form_drawer}>
          <h2 className={styles.form_drawer_title}>
            {props.licenseContext.licenseTitle}
          </h2>
          <div className={styles.message}>
            <p>Please carefully read the license agreement below. Clicking on the Accept button indicates your acceptance of these terms and conditions and will start the product download.</p>
          </div>
          <TextField
            id="license-text"
            variant="outlined"
            value={props.licenseContext.licenseText}
            InputProps={{
              readOnly: true,
            }}
            multiline
            maxRows={30}
            onChange={() => null }
          />
          <div className={styles.buttons_area}>
            <PrimaryButton label="Accept" type="button" onClick={acceptLicense} />
            <SecondaryButton label="Reject" type="button" onClick={() => props.closeDrawerCallback(false)} />
          </div>
        </div>
      </form>
    </Drawer>
  );
}

export default LicenseAcceptanceDrawer;
