import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import PrimaryButton from '../primary/PrimaryButton';

const LoginButton: React.FC = () => {
  const { loginWithRedirect } = useAuth0();

  const login = 'Login';

  return (
    <PrimaryButton
      label={login}
      onClick={() => loginWithRedirect()}
    />
  );
};

export default LoginButton;
