import React from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

export type MenuLinkProps = {
    linkName: string
    path: string,
    icon: IconDefinition,
    activeClassName: string
    isExact: boolean,
    iconClassName: string,
    access: Access
};

export type Access = 'all' | 'reporting' | 'admin';

export const MenuLink: React.FC<MenuLinkProps> = (props: MenuLinkProps) => {
  const {
    isExact, path, activeClassName, iconClassName, icon, linkName,
  } = props;

  return (
    <NavLink
      end={isExact}
      to={path}
      className={({ isActive }) =>
        isActive ? activeClassName : undefined
      }
    >
      <span>
        <FontAwesomeIcon className={iconClassName} icon={icon} />
        {linkName}
      </span>
    </NavLink>
  );
};
