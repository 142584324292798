import React from 'react';

import BarLoader from 'react-spinners/BarLoader';
import { BarLoaderContainer } from './BarLoader.styles';

const Loader: React.FC = () => (
  <BarLoaderContainer>
    <h2>Loading...</h2>
    <BarLoader
      height={5}
      width={200}
      color="#82bc00"
      loading
    />
  </BarLoaderContainer>
);

export default Loader;
