import React from 'react';
import { Auth0ContextInterface, useAuth0 } from '@auth0/auth0-react';

import { useLazyQuery, useQuery } from '@apollo/client';

import { TextField, FormControlLabel, Checkbox } from '@mui/material';

import PrimaryButton from '../widgets/buttons/primary/PrimaryButton';
import SecondaryButton from '../widgets/buttons/secondary/SecondaryButton';
import NoMatch from '../layout/nomatch/NoMatch';
import NoDownloads from '../layout/noDownloads/NoDownloads'
import InfoBar from '../widgets/infobar/InfoBar';
import BarLoader from '../widgets/loaders/barLoader/BarLoader';

import styles from './DownloadReleases.module.css';
import { CUSTOMER_BY_USER_ID } from "../../client/queries/customers";
import { CustomerType, ReleaseFileType } from "../releases/types";
import {
  PRODUCTS_BY_CUSTOMER,
  SEARCH_CUSTOMER_RELEASES,
  VERSIONS_BY_CUSTOMER_BY_PRODUCT
} from "../../client/queries/downloadReleases";
import { GENERATE_PRESIGNED_URL } from "../../client/queries/userDownloads";
import CollapsibleReleasesTable from "./elements/collapsibleReleasesTable/CollapsibleReleasesTable";
import ReleaseFilter from "./elements/releaseFilter/ReleaseFilter";
import LicenseAcceptanceDrawer from "./elements/licenseAcceptanceDrawer/LicenseAcceptanceDrawer";
import { defaultSnackbarState, SnackbarType } from '../widgets/infobar/types';

export type ReleaseType = {
  id: number,
  productId: {
    id: string,
    name: string,
  },
  name: string,
  description: string,
  licenseTitle: string,
  licenseText: string,
  releaseDate: string,
  security: boolean,
  releaseFiles: ReleaseFileType[],
};

export type CustomerReleaseType = {
  id: string,
  licenseAcceptanceDate: string | null,
  release: ReleaseType
}

export type LicenseContextType = {
  customerId: number,
  releaseFileId: number,
  setSnackbar: (snackbar: SnackbarType) => void,
  releaseId: number,
  licenseTitle: string,
  licenseText: string,
  licenseAcceptanceDate: string | null,
}

const initialLicenseContext = {
  customerId: 0,
  releaseFileId: 0,
  setSnackbar: (snackbar: SnackbarType) => null,
  releaseId: 0,
  licenseTitle: '',
  licenseText: '',
  licenseAcceptanceDate: '',
};



const NUMBER_OF_RELEASES = 5;

const DownloadReleases: React.FC = () => {
  const { isAuthenticated, user }: Auth0ContextInterface = useAuth0();
  const userId: string | undefined = user?.sub;
  const [releaseSearch, setReleaseSearch] = React.useState<string>('');
  const [inputString, setInputString] = React.useState<string>('');
  const [securityFixesOnly, setSecurityFixesOnly] = React.useState<boolean>(false);
  const [isLicenseDrawerOpen, setIsLicenseDrawerOpen] = React.useState<boolean>(false);
  const [customerId, setCustomerId] = React.useState<number>(0);
  const [snackbar, setSnackbar] = React.useState<SnackbarType>(defaultSnackbarState);
  const [product, setProduct] = React.useState<string>('');
  const [version, setVersion] = React.useState<string>('');
  const [selectedInitialDate, setSelectedInitialDate] = React.useState<Date | null>(null);
  const [selectedEndDate, setSelectedEndDate] = React.useState<Date | null>(null);
  const [downloadContext, setDownloadContext] = React.useState<LicenseContextType>(initialLicenseContext);

  const [customerReleasesQuery, {
    loading: customerReleasesLoading,
    error: customerReleasesError,
    data: customerReleasesData,
    refetch: customerReleaseRefetch
  }] = useLazyQuery<{
    searchCustomerReleases: CustomerReleaseType[]
  }>(SEARCH_CUSTOMER_RELEASES, {
    variables: {
      userId: userId,
      customerId: customerId,
      search: releaseSearch,
      product: product,
      version: version,
      initialDate: selectedInitialDate,
      endDate: selectedEndDate,
      securityFixesOnly: securityFixesOnly
    },
  });

  const [customerProductsQuery, {
    error: customerProductsError,
    data: customerProductsData }] = useLazyQuery<{ productsByCustomer: { name: string }[] }>(PRODUCTS_BY_CUSTOMER, {
      variables: {
        userId: userId,
        id: customerId,
      },
    });

  const [versionsByCustomerByProductQuery, { data: versionsByCustomerByProductData }] =
    useLazyQuery<{ versionsByCustomerByProduct: string[] }>(VERSIONS_BY_CUSTOMER_BY_PRODUCT, {
      variables: {
        userId: userId,
        id: customerId,
        product: product,
        top: NUMBER_OF_RELEASES
      },
      onError: () => {
        setSnackbar({ isOpen: true, severity: "error", message: 'Not able to find any version for this product' });
      }
    });

  const { loading: customerLoading, error: customerError, data } =
    useQuery<{ customerByUserId: CustomerType }>(CUSTOMER_BY_USER_ID, {
      variables: {
        userId: userId
      },
      onCompleted: (cust) => {
        if (cust) {
          setCustomerId(cust.customerByUserId.custId);
          setDownloadContext({ ...downloadContext, customerId: cust.customerByUserId.custId });
          customerReleasesQuery();
          customerProductsQuery();
          versionsByCustomerByProductQuery();
        } else {
          setSnackbar({ isOpen: true, severity: "error", message: 'Customer not found' });
        }
      },
    });

  const [generatePresignedUrl, { data: presignedUrl }] =
    useLazyQuery(GENERATE_PRESIGNED_URL, {
      onCompleted: (presignedData) => {
        window.open(presignedData.presignedUrl, '_blank', 'noreferrer');
      },
      onError: (e) => {
        setSnackbar({ isOpen: true, severity: "error", message: e.message });
      }
    });

  const productsByCustomer: string[] = customerProductsData ? customerProductsData.productsByCustomer.map((product) => product.name) : [];
  const customerReleases: CustomerReleaseType[] = customerReleasesData ? customerReleasesData.searchCustomerReleases : [];
  const versionsByProduct: string[] = product !== '' && versionsByCustomerByProductData ? versionsByCustomerByProductData.versionsByCustomerByProduct : [];

  const downloadFile: (releaseId: number, releaseFileId: number) => void = (releaseId: number, releaseFileId: number) => {
    generatePresignedUrl({
      variables: {
        releaseId: releaseId,
        userId: userId,
        releaseFileId: releaseFileId
      }
    });
  }

  const closeSnackbar = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') return;
    setSnackbar({ ...snackbar, isOpen: false });
  };

  const handleReleaseFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setInputString(event.currentTarget.value);
  };

  const handleFilterSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setReleaseSearch(inputString);
    customerReleasesQuery();
  };

  const resetTable = async () => {
    setInputString('');
    setReleaseSearch('');
    setProduct('')
    setVersion('')
    setSelectedInitialDate(null);
    setSelectedEndDate(null);
    setSecurityFixesOnly(false);
    customerReleasesQuery();
  };

  const handleSecureCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSecurityFixesOnly(event.currentTarget.checked);
  };

  const handleProductFilter = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (event.currentTarget.textContent) {
      setProduct(event.currentTarget.textContent);
      setVersion('')
      customerReleasesQuery();
      versionsByCustomerByProductQuery();
    }
  }

  const handleVersionFilter = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (event.currentTarget.textContent) {
      setVersion(event.currentTarget.textContent);
      customerReleasesQuery();
    }
  };

  const handleInitialDateChange = (date: Date | null) => {
    let d = date
    if (date) {
      d = new Date(date)
    }
    setSelectedInitialDate(d);
    customerReleasesQuery();
  };

  const handleEndDateChange = (date: Date | null) => {
    let d = date
    if (date) {
      d = new Date(date)
    }
    setSelectedEndDate(d);
    customerReleasesQuery();
  };

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
    setSnackbar({ isOpen: true, severity: "info", message: 'Checksum copied to clipboard!' });
  };

  const openLicenseDrawer = (event: React.MouseEvent<HTMLButtonElement>, customerRelease: CustomerReleaseType, releaseFileId: number) => {
    if (!customerRelease.licenseAcceptanceDate) {
      setDownloadContext({
        ...downloadContext,
        customerId: customerId,
        releaseId: customerRelease.release.id,
        licenseAcceptanceDate: customerRelease.licenseAcceptanceDate,
        licenseTitle: customerRelease.release.licenseTitle,
        licenseText: customerRelease.release.licenseText,
        releaseFileId: releaseFileId
      });
      setIsLicenseDrawerOpen(true);
    }
  };

  if (!isAuthenticated || customerError || customerReleasesError || customerProductsError) return (<NoMatch />);
  if (customerLoading || !customerProductsData) return (<BarLoader />);

  return <>
    {productsByCustomer.length > 0 ? (
      <div className={styles.download_releases}>
        <ReleaseFilter
          styleClass=''
          selectedProduct={product}
          selectedVersion={version}
          customerProducts={productsByCustomer}
          customerVersions={versionsByProduct}
          productFilter={handleProductFilter}
          versionFilter={handleVersionFilter}
          releaseDateFilter={{
            handleInitialDateChange: handleInitialDateChange,
            handleEndDateChange: handleEndDateChange,
            selectedInitialDate: selectedInitialDate,
            selectedEndDate: selectedEndDate
          }}
        />
        <div className={styles.download_table}>
          <InfoBar
            isOpen={snackbar.isOpen}
            message={snackbar.message}
            closeSnackbar={closeSnackbar}
            severity={snackbar.severity}
          />
          <LicenseAcceptanceDrawer
            licenseContext={downloadContext}
            userId={userId}
            refetchCustomerReleases={customerReleaseRefetch}
            isDrawerOpen={isLicenseDrawerOpen}
            closeDrawerCallback={setIsLicenseDrawerOpen}
            downloadFile={downloadFile}
          />
          <form onSubmit={handleFilterSubmit} autoComplete="off">
            <TextField
              variant="standard"
              id="standard-search"
              label="Search for..."
              type="search"
              value={inputString}
              onChange={handleReleaseFilterChange} />
            <PrimaryButton label="Search" type="submit" />
            <SecondaryButton label="Reset" type="reset" onClick={resetTable} />
            <FormControlLabel control={(
              <Checkbox
                color="default"
                value="security"
                checked={securityFixesOnly}
                required={false}
                name="checkbox-secure-only"
                onChange={handleSecureCheckbox}
              />)}
              label="Security Fixes Only"
            />
          </form>
          <CollapsibleReleasesTable
            customerReleases={customerReleases}
            copyToClipboard={copyToClipboard}
            loading={customerReleasesLoading}
            openLicense={openLicenseDrawer}
            downloadFile={downloadFile}
          />
        </div>
      </div>)
      : (<NoDownloads />)}
  </>;
  }

  export default DownloadReleases;
