import { gql } from '@apollo/client';

// TODO - deprecate
export const SEARCH_TOTAL_DOWNLOAD_COUNT = gql`
  query SearchDownloadCount($search: String) {
    searchDownloadCount(search: $search)
  }
`;

// TODO - deprecate
export const SEARCH_DOWNLOAD_COUNT_BY_VERSION = gql`
  query SearchDownloadCountByVersion($search: String) {
    searchDownloadCountByVersion(search: $search) {
      version
      versionCount
    }
  }
`;

// TODO - deprecate
export const SEARCH_DOWNLOAD_COUNT_BY_RELEASE = gql`
  query SearchDownloadCountByRelease($search: String) {
    searchDownloadCountByRelease(search: $search) {
      release
      count
    }
  }
`;

export const SEARCH_ORACLE_TOTAL_DOWNLOAD_COUNT = gql`
  query SearchOracleDownloadCount($search: String) {
    searchOracleDownloadCount(search: $search)
  }
`;

export const SEARCH_ORACLE_DOWNLOAD_COUNT_BY_VERSION = gql`
  query SearchOracleDownloadCountByVersion($search: String) {
    searchOracleDownloadCountByVersion(search: $search) {
      version
      versionCount
    }
  }
`;


export const SEARCH_ORACLE_DOWNLOAD_COUNT_BY_RELEASE = gql`
  query SearchOracleDownloadCountByRelease($search: String) {
    searchOracleDownloadCountByRelease(search: $search) {
      release
      count
    }
  }
`;
