import React from 'react'
import Header from '../layout/header/Header'
import { ContentContainer, PageLayout } from './PageTemplate.styles'

interface PageTemplateProps {
  name: string,
  component: React.ElementType,
}

const PageTemplate: React.FC<PageTemplateProps> = ({ name, component }: PageTemplateProps) => {
  const {component: Component} = {component}

  return (
    <PageLayout>
      <Header pageTitle={name} />
      <ContentContainer>
        <Component />
      </ContentContainer>
    </PageLayout>
  )
}

export default PageTemplate
