import { styled } from '@mui/material/styles';

export const DrawerStyles = styled('div')`
    color: #666;
    display: flex;
    flex-direction: column;
    padding: 30px;
    width: 30vw;

    .MuiFormControl-root {
      margin: 0 0 30px 0;

      .MuiInputBase-root {
        input {
          color: #666;
        }

        :after {
          border-bottom: 2px solid #000;
        }
      }

      .Mui-focused {
        color: #82bc00;

        fieldset,
        textarea,
        input {
          border-color: #82bc00;
        }
      }

      .MuiOutlinedInput-root {
        textarea,
        input {
          color: #666;
        }
      }
    }

  .MuiFormControl-root.MuiTextField-root {
    margin-bottom: 30px;
  }

  .bar {
    background-image: linear-gradient(to right, #82bc00 0, #82bc00 5px, #fff 5px);
    font-weight: 400;
    padding-left: 15px;
    margin-bottom: 30px;
  }
`
