import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Avatar, UserContainer, UserInfo } from './User.styles';
import LogoutButton from '../../widgets/buttons/logout/LogoutButton';

const User: React.FC = () => {
    const { user } = useAuth0();

    return (
        <UserContainer id="user">
            <Avatar>
                <img src={user?.picture} alt=""/>
                <LogoutButton />
            </Avatar>
            <UserInfo>
                <h2>{user?.name}</h2>
                <p>{user?.email}</p>
            </UserInfo>
        </UserContainer>
    );
}

export default User;
