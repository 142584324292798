import { gql } from '@apollo/client';

export const GET_ACCOUNTS_BY_NAME = gql`
  query GetAccountsByName($name: String) {
    accountByName(name:$name) {
      organizationid
      name
      accesscode
      salesforceid
    }
  }
`;

export const SEARCH_ACCOUNTS = gql`
  query SearchAccounts($search: String) {
    searchAccounts(search:$search) {
      organizationid
      name
      accesscode
      salesforceid
    }
  }
`;

export const GET_ACCOUNTS_BY_ACCESS_CODE = gql`
  query GetAccountsByAccessCode($accesscode: String) {
    accountByAccessCode(accesscode:$accesscode) {
      organizationid
      name
      accesscode
      salesforceid
    }
  }
`;

export const GET_ACCOUNT_BY_ID = gql`
  query GetAccountById($organizationid: Int) {
    accountById(organizationid:$organizationid) {
      organizationid
      name
      accesscode
      salesforceid
      secureAreas {
        secureAreaId
        name
      }
    }
    searchSecureAreas {
      secureAreaId
      name
      path
    }
  }
`;

export const GET_CUSTOMERS_BY_ACCOUNT_BY_ID = gql`
  query GetAccountById($organizationid: Int, $customerPage: Int) {
    accountById(organizationid:$organizationid, customerPage: $customerPage) {
      customersPage {
        page
        perPage
        total
        customers {
          custId
          name
          roles {
            roleId
            name
          }
        }
      }
    }
  }
`;

export const ADD_ACCOUNT = gql`
  mutation CreateAccount($name: String, $accesscode: String, $salesforceid: String) {
    createAccount(name:$name, accesscode:$accesscode, salesforceid:$salesforceid) {
      account {
        organizationid
        name
        accesscode
        salesforceid
      }
    }
  }
`;

export const UPDATE_ACCOUNT = gql`
  mutation UpdateAccount($organizationid: Int, $name: String, $accesscode: String, $salesforceid: String) {
    updateAccount(organizationid:$organizationid, name:$name, accesscode:$accesscode, salesforceid:$salesforceid) {
      account {
        organizationid
        name
        accesscode
        salesforceid
      }
    }
  }
`;

export const CREATE_ASSOCIATION = gql`
  mutation CreateCustomerAccount($userId: String, $custId: Int, $accessCode: String, $roleId: Int) {
    createCustomerAccount(userId: $userId, custId: $custId, accessCode: $accessCode, roleId: $roleId) {
      customerAccount {
        accounts {
          organizationid
          name
        }
        roleId {
          roleId
          name
        }
      }
    }
  }
`;

export const CREATE_ASSOCIATION_ROLES = gql`
  mutation CreateCustomerAccountRoles($userId: String, $accessCode: String, $customerRoles: [CustomerRoles]) {
    createCustomerAccountRoles(userId: $userId, accessCode: $accessCode, customerRoles: $customerRoles) {
      ok
      message
    }
  }
`;

export const DELETE_ASSOCIATION = gql`
  mutation DeleteCustomerAccount($custId: Int, $orgId: Int, $roleId: Int) {
    deleteCustomerAccount(custId: $custId, orgId: $orgId, roleId: $roleId) {
      ok
      message
    }
  }
`;

export const ADD_SECURE_AREA = gql`
  mutation CreateAccountSecureArea($organizationId: Int, $secureAreaId: Int) {
    createAccountSecureArea(organizationId: $organizationId, secureAreaId: $secureAreaId) {
      accountSecureArea {
        secureAreaId {
          name
        }
        organizationId {
          name
        }
      }
    }
  }
`;

export const DELETE_SECURE_AREA = gql`
  mutation DeleteSecureArea($organizationId: Int, $secureAreaId: Int) {
    deleteAccountSecureArea(organizationId: $organizationId, secureAreaId: $secureAreaId) {
      ok
      message
    }
  }
`;
