import React from 'react';
import { TableCell } from '@mui/material';
import TableWrapper from '../table/TableWrapper';
import ReleasesTableRow from './ReleasesTableRow'
import { CustomerAssociationsType } from '../drawers/searchCustomers/AccountsSearchCustomerDrawer';
import { EnabledCustomers } from '../drawers/searchCustomers/ReleasesSearchCustomerDrawer';

interface ReleasesSearchResultsTableProps {
  searchResults: CustomerAssociationsType[],
  collectObjects: (customer: EnabledCustomers) => void,
  totalCustomers: number,
  page: number,
  changePage: any
}

const ReleasesSearchResultsTable: React.FC<ReleasesSearchResultsTableProps> = (props: ReleasesSearchResultsTableProps) => {
  const { searchResults, collectObjects, totalCustomers, page, changePage } = props

  const headColumns = (
    <>
      <TableCell>Customer</TableCell>
      <TableCell>Enable</TableCell>
    </>
  );

  const tableContents = searchResults.map((searchResult: CustomerAssociationsType) => {
    return (
      <React.Fragment key={searchResult.custId}>
        <ReleasesTableRow customerID={searchResult.custId} name={searchResult.name} email={searchResult.email} collectObjects={collectObjects} />
      </React.Fragment>
    )
  }
  );

  return (
    <TableWrapper
      headColumns={headColumns}
      tableContents={tableContents}
      total={totalCustomers}
      page={page}
      handleChangePage={changePage}
      rowsPerPage={10}
    />
  );
}

export default ReleasesSearchResultsTable
