import { gql } from '@apollo/client';

export const ADD_PRODUCT = gql`
  mutation AddProduct($name: String!, $description: String!) {
    createProduct(name: $name, description: $description) {
      product {
        id
        name
        description
        isarchived
      }
    }
  }
`;

export const TOGGLE_PRODUCT_STATUS = gql`
  mutation ToggleProductStatus($id: String, $name: String, $description: String, $isarchived: Boolean) {
    updateProduct(id: $id, name: $name, description: $description, isarchived: $isarchived) {
      product {
        id
        isarchived
      }
    }
  }
`;

export const UPDATE_PRODUCT = gql`
  mutation UpdateProduct($id: String, $name: String, $description: String, $isarchived: Boolean) {
    updateProduct(id: $id, name: $name, description: $description, isarchived: $isarchived) {
      product {
        id
        name
        description
      }
    }
  }
`;

export const SEARCH_PRODUCTS = gql`
  query SearchProducts($search: String) {
    searchProducts(search: $search){
        id
        name
        description
        isarchived
    }
  }
`;

export const SEARCH_PRODUCTS_FOR_RELEASES = gql`
query SearchProducts($search: String) {
  searchProducts(search: $search){
      id
      name
      isarchived
  }
}
`;

export const SEARCH_ENABLED_PRODUCTS_FOR_RELEASES = gql`
query SearchProducts($search: String, $isarchived: Boolean) {
  searchProducts(search: $search, isarchived: $isarchived){
      id
      name
  }
}
`;