import React from 'react';

import LoginButton from '../../widgets/buttons/login/LoginButton';
import SignUpButton from '../../widgets/buttons/signup/SignUpButton';

import styles from './Login.module.css';

const Login: React.FC = () => (
  <div className={styles.login}>
    <LoginButton />
    <SignUpButton />
  </div>
);

export default Login;
