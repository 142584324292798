import { styled } from '@mui/material/styles';

export const FormContainer = styled('div')`
    form {
      display: grid;
      grid-template-rows: 50px 50px;
      grid-template-columns: 150px 150px 1fr;
      gap: 10px;
      width: 100%;
      margin: 30px 0;

      .Mui-focused {
        color: #82bc00;

        input {
          color: #666;
        }
      }

      .MuiInputBase-root.MuiInput-underline {
        margin-bottom: unset;

        :after {
          border-bottom: 2px solid #000;
        }
      }

      .MuiTextField-root {
        grid-column: 1 / span 2;
        height: fit-content;
      }

      fieldset {
        margin: unset;

        div[role="radiogroup"] {
          grid-column: 3;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          height: 100%;

          .MuiFormControlLabel-label.MuiTypography-body1 {
            font-size: 14px;
          }
        }
      };

      .searchButton {
        grid-row: 2;
        grid-column: 1;
        background-color: transparent;
        color: #82bc00;
        border: 2px solid #82bc00;

        &:hover {
          background-color: #82bc00;
          color: #fff;
        }
      }

      button[type="reset"] {
        margin-right: unset;
      }
    }
`

export const ErrorContainer = styled('div')`
  display: flex;
  flex-direction: column;
`
