import { styled } from '@mui/material/styles';

export const SidebarContainer = styled('div')`
    background-color: #333;
    box-shadow: 0px 2px 5px #666;
    display: grid;
    grid-area: header / sidebar / main-row-end / sidebar;
    grid-template-rows: inherit;
    z-index: 1;

    .icon {
      padding: 0 10px 0 0;
    }

    .logo {
      grid-row-start: header;
      grid-row-end: header-end;
      place-self: center;
      padding-right: 0.4vw;
    }

    button {
      all: unset;
      cursor: pointer;
    }

    a,
    button {
      color: #fff;
      font-weight: 700;
      padding: 10px 0 10px 35px;
      text-decoration: none;

      svg {
        padding-right: 10px;
      }

      :hover {
        background-color: #444;
      }
    }

    a.selected {
      background-image: linear-gradient(to right, #82bc00, #82bc00 3px, #333 3px,#333);
      color: #82bc00;
    }
`

export const SidebarMenu = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 30px 0 0 0;
`

export const SidebarTitle = styled('div')`
  background-image: linear-gradient(to right, #444, #444 50%, #333);
  color: #f6f6f6;
  font-size: 14px;
  font-weight: 300;
  padding: 10px;
  margin: 0;
  text-transform: uppercase;
`

export const SidebarCategory = styled('div')`
  display: flex;
  flex-direction: column;
`

export const SidebarExternalLinks = styled('div')`
  display: flex;
  flex-direction: column;
  margin: auto 0 30px 0;
`
