import React from 'react';
import Header from '../header/Header';

import styles from './NoMatch.module.css';

const NoMatch: React.FC = () => (
  <>
    <Header pageTitle='The sadness!'  />
    <div className={styles.no_match}>
      <h1>Page Not Found</h1>
      <p>This is not the page you were looking for...</p>
    </div>
  </>
);

export default NoMatch;
