import React from 'react';
import { List, ListItem, ListItemText, ListItemIcon } from '@mui/material'
import { VpnKey, Cloud } from '@mui/icons-material';

import Card from '../../../widgets/cards/Card';
import styles from './AccountDetails.module.css';

type AccountDetailsProps = {
    accesscode: string,
    salesforceid: string,
    onClick: () => void,
};

const AccountDetails: React.FC<AccountDetailsProps> = (props: AccountDetailsProps) => (
  <Card
    className={styles.account_details}
    cardTitle="Details"
    onClick={props.onClick}
  >
    <List>
      <ListItem>
        <ListItemIcon>
          <VpnKey />
        </ListItemIcon>
        <ListItemText
          primary={props.accesscode}
          secondary="Access Code"
        />
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <Cloud />
        </ListItemIcon>
        <ListItemText
          primary={props.salesforceid}
          secondary="Salesforce ID"
        />
      </ListItem>
    </List>
  </Card>
);

export default AccountDetails;
