import React from 'react';
import { List, ListItem, ListItemText, ListItemIcon } from '@mui/material'
import { ChevronRight } from '@mui/icons-material';

import styles from './CustomersDetails.module.css';
import DetailsCard from '../../../widgets/detailsCard/DetailsCard';

export type SecureArea = {
    id: string,
    name: string,
    path: string
};

type CustomersDetailsProps = {
    title: string,
    secureAreas: SecureArea[]
};

const CustomersDetails: React.FC<CustomersDetailsProps> = (props: CustomersDetailsProps) => (
  <DetailsCard
    className={styles.customers_details}
    title={props.title}
  >
    <List>
      {props.secureAreas.map((secureArea: SecureArea) => (
        <ListItem key={secureArea.id}>
          <ListItemIcon>
            <ChevronRight />
          </ListItemIcon>
          <ListItemText
            primary={secureArea.name}
            secondary={secureArea.path}
          />
        </ListItem>
      ))}
    </List>
  </DetailsCard>
);

export default CustomersDetails;
