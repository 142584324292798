import { styled } from '@mui/material/styles';

export const UserContainer = styled('div')`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;

  `

export const Avatar = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  position: relative;

  img {
    width: 60px;
    border-radius: 50%;
  }

  div {
    position: absolute;
    bottom: -5px;
    right: 0;
  }
`

export const UserInfo = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0 10px;
  color: #666;

  h2, p {
    margin: unset;
    font-weight: 200;
  }

  h2 {
    font-size: 20px;
    line-height: 1.2;
  }

  p {
    font-size: 14px;
  }

`
