import { styled } from '@mui/material/styles';

export const TasktopTableWrapper = styled('div')`
  background-color: #fff;
  margin-bottom: 30px;
  height: fit-content;

  tr {
    th, td {
      font-size: 15px;
      color: #666;

      .MuiTableSortLabel-active {
        color: #666;
      }
    }

    button:hover {
      color: #82bc00;
    }
  }
`
