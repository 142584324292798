import React from 'react';

import { TableRow, TableCell } from '@mui/material';
import { faUserSlash } from '@fortawesome/free-solid-svg-icons';
import DeleteButton from '../../../widgets/buttons/delete/DeleteButton';

import TableWrapper from '../../../layout/table/TableWrapper';
import { Link } from 'react-router-dom';

type RoleType = {
  roleId: number,
  name: string
}

export type CustomerPageType = {
  page: number,
  perPage: number,
  total: number,
  customers: CustomerAccountType[]
}

export type CustomerAccountType = {
  custId: number,
  name: string,
  roles: RoleType[],
}

type CustomerAccountTableProps = {
  customerPage: CustomerPageType,
  handleChangePage: (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => void,
  deleteCustomer: (event: React.MouseEvent<HTMLButtonElement>) => void,
};

const CustomerAccountTable: React.FC<CustomerAccountTableProps> = ({
  customerPage, handleChangePage, deleteCustomer
}: CustomerAccountTableProps) => {
  const rowsPerPage = customerPage.perPage ? customerPage.perPage : 10;

  const outputRoles = (association: CustomerAccountType) => {
    let roles = '';
    for (let i = 0; i < association.roles.length; i++) {
      roles += association.roles[i].name;
      if (i !== association.roles.length - 1) roles += ', ';
    }
    return roles;
  };

  const headColumns = (
    <>
      <TableCell>Name</TableCell>
      <TableCell>Roles</TableCell>
      <TableCell align="right" />
    </>
  );

  const tableContents = customerPage.customers.map((association: CustomerAccountType) => (
    <TableRow key={association.custId}>
      <TableCell
        component="th"
        scope="row"
      >
        <Link to={{ pathname: `/customers/${association.custId}` }}>
          {association.name}
        </Link>
      </TableCell>
      <TableCell
      >
        {outputRoles(association)}
      </TableCell>
      <TableCell align="right">
        <DeleteButton
          label="Delete Role"
          onClick={deleteCustomer}
          value={association.custId.toString()}
          icon={faUserSlash}
        />
      </TableCell>
    </TableRow>
  ));

  return (
    <TableWrapper
      headColumns={headColumns}
      tableContents={tableContents}
      total={customerPage.total}
      page={customerPage.page}
      handleChangePage={handleChangePage}
      rowsPerPage={rowsPerPage}
    />
  );
};

export default CustomerAccountTable;
