import React from 'react';
import { TableRow, TableCell } from '@mui/material';
import SwitchesGroupReleases from '../../widgets/switches/SearchResultsReleasesGroup';
import { EnabledCustomers } from '../drawers/searchCustomers/ReleasesSearchCustomerDrawer';

interface ReleasesTableRowProps {
  customerID: number,
  name: string,
  email: string,
  collectObjects: (customer: EnabledCustomers) => void,
}

const ReleasesTableRow: React.FC<ReleasesTableRowProps> = (props: ReleasesTableRowProps) => {
  const { customerID, name, email, collectObjects } = props

  const [enableCustomer, setEnableCustomer] = React.useState({
    enableCustomer: false,
  });

  React.useEffect(() => {
    const customer: { custId: number, enabled: boolean } = {
      custId: customerID,
      enabled: enableCustomer.enableCustomer
    }

    collectObjects(customer)
  }, [collectObjects, customerID, enableCustomer])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (checked) {
      setEnableCustomer({ enableCustomer: event.target.checked })
    }

    if (!checked) {
      setEnableCustomer({ enableCustomer: false })
    }
  };

  return (
    <TableRow key={customerID}>
      <TableCell
        component="th"
        scope="row"
      >
        <p>{name}</p>
        <p>{email}</p>
      </TableCell>
      <TableCell>
        <SwitchesGroupReleases handleChange={handleChange} />
      </TableCell>
    </TableRow>
  )
}

export default ReleasesTableRow
