export type ContentSpaceType = {
    secureAreaId: string,
    name: string,
    path: string,
};

export const defaultContentSpace = {
  secureAreaId: '',
  name: '',
  path: '',
};
