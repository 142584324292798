import React from 'react';

import { Drawer } from "@mui/material";

import GenericAutoCompleteField from '../fields/GenericAutoCompleteField';
import GenericTextField from '../fields/GenericTextField';
import GenericSelectField from '../fields/GenericSelectField';
import GenericCheckboxField from '../fields/GenericCheckboxField';
import GenericRadioGroup from '../radioFields/GenericRadioGroup';
import PrimaryButton from '../../widgets/buttons/primary/PrimaryButton';
import { FormField } from '../../../types/types';
import { DrawerStyles } from './Drawers.styles'

type DrawerProps = {
  formFields: FormField[],
  drawerTitle: string,
  isDrawerOpen: boolean,
  closeDrawerCallback: (action: React.SetStateAction<boolean>) => void,
  submitForm: (event: React.FormEvent<HTMLFormElement>) => void,
};

const GenericDrawer: React.FC<DrawerProps> = (props: DrawerProps) => {
  const closeDrawer = (event: any, callback: (action: React.SetStateAction<boolean>) => void) => {
    if (
      event.type === 'keydown'
      && ((event as React.KeyboardEvent).key === 'Tab'
        || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    callback(false);
  };

  return (
    <Drawer
      anchor="right"
      open={props.isDrawerOpen}
      onClose={(event) => closeDrawer(event, props.closeDrawerCallback)}
    >
      <form onSubmit={props.submitForm} autoComplete="off">
        <DrawerStyles>
          <h2 className='bar'>
            {props.drawerTitle}
          </h2>
          {props.formFields.map((formField, index) => {
            if (formField.fieldType === 'textField') {
              return (
                <GenericTextField
                  key={index}
                  name={formField.value}
                  label={formField.label}
                  handleChange={formField.handleChange}
                  required={formField.required}
                />
              );
            } if (formField.fieldType === 'textArea') {
              return (
                <GenericTextField
                  key={index}
                  name={formField.value}
                  label={formField.label}
                  multiline
                  handleChange={formField.handleChange}
                  required={formField.required}
                />
              );
            } if (formField.fieldType === 'selectField') {
              return (
                <GenericSelectField
                  key={index}
                  name={formField.value}
                  label={formField.label}
                  options={formField.options}
                  required={formField.required}
                  handleChange={formField.handleChange}
                />
              );
            } if (formField.fieldType === 'checkboxField') {
              return (
                <GenericCheckboxField
                  key={index}
                  value={formField.value}
                  checked={formField.checked === undefined ? !formField.checked : formField.checked}
                  label={formField.label}
                  required={formField.required}
                  handleChange={formField.handleChange}
                />
              )
            } if (formField.fieldType === 'radioGroup') {
              return (
                <GenericRadioGroup
                  key={index}
                  value={formField.value}
                  label={formField.label}
                  fields={formField.fields}
                  required={formField.required}
                  row={formField.row ? formField.row : false}
                  handleChange={formField.handleChange}
                />
              );
            }
            if (formField.fieldType === 'autoCompleteField') {
              if (formField.autoCompleteOptions && formField.selectedOption && formField.handleAutocomplete) {
                return (
                  <GenericAutoCompleteField
                    key={index}
                    name={formField.value}
                    value={formField.value}
                    options={formField.autoCompleteOptions}
                    label={formField.label}
                    optionLabel={formField.optionLabel}
                    required={formField.required}
                    selectedOption={formField.selectedOption}
                    handleChange={formField.handleChange}
                    handleAutocomplete={formField.handleAutocomplete}
                  />
                );
              }
            }
            return <div key={index} />;
          })}
          <PrimaryButton label="Save" type="submit" />
        </DrawerStyles>
      </form>
    </Drawer>
  );
};

export default GenericDrawer;
