import { useLazyQuery, useMutation } from "@apollo/client";
import { Drawer, TextField } from "@mui/material";
import React, { useEffect } from "react";
import { SEARCH_PAGINATED_RELEASES_BY_CUSTOMER_ID, TOGGLE_RELEASE_CUSTOMER_ACCESS } from "../../../../client/queries/releases";
import CustomerReleasesTable from "../../../customers/elements/customerReleasesTable/CustomerReleasesTable";
import { CustomerReleasePageType, CustomerReleaseType, ReleaseCustomerType } from "../../../releases/types";
import PrimaryButton from "../../../widgets/buttons/primary/PrimaryButton";
import SecondaryButton from "../../../widgets/buttons/secondary/SecondaryButton";
import InfoBar from "../../../widgets/infobar/InfoBar";
import { defaultSnackbarState, SnackbarType } from "../../../widgets/infobar/types";
import BarLoader from "../../../widgets/loaders/barLoader/BarLoader";
import NoMatch from "../../nomatch/NoMatch";
import { SearchCustomerDrawerStyles } from "../searchCustomers/SearchCustomerDrawer.styles";

interface CustomerSearchReleasesDrawerProp {
    drawerTitle: string,
    isDrawerOpen: boolean,
    closeDrawerCallback: (action: React.SetStateAction<boolean>) => void,
    customerId: string,
    customerName: string
}

// types
export type PaginatedReleasesByCustomerIdQuery = {
    customerId: any,
    search: string,
    page: number,
    perPage: number
}

const CustomerSearchReleasesDrawer: React.FC<CustomerSearchReleasesDrawerProp> = (props: CustomerSearchReleasesDrawerProp) => {
    const { drawerTitle, isDrawerOpen, closeDrawerCallback, customerId, customerName } = props;
    const [snackbar, setSnackbar] = React.useState<SnackbarType>(defaultSnackbarState);
    const [page, setPage] = React.useState<number>(0);
    const perPage = 10;
    const [inputString, setInputString] = React.useState<string>('');
    const [paginatedReleasesByCustomerIdQuery, setPaginatedReleasesByCustomerIdQuery] = React.useState<PaginatedReleasesByCustomerIdQuery>({
        customerId: customerId,
        page: 0,
        perPage: 10,
        search: '',
    });

    const [toggleReleaseAccessForCustomer] = useMutation<{
        toggleReleaseCustomer: { releaseCustomer: ReleaseCustomerType }
    }>(TOGGLE_RELEASE_CUSTOMER_ACCESS, {
        refetchQueries: [
            {
                query: SEARCH_PAGINATED_RELEASES_BY_CUSTOMER_ID,
                variables: {
                    customerId: customerId,
                    page: page,
                    perPage: perPage,
                    search: inputString,
                },
            }
        ]
    });

    const [
        searchPaginatedReleasesByCustomerId,
        {
            data: paginatedCustomerReleasesData,
            loading: paginatedCustomerReleaseLoading,
            error: paginayedCustomerReleaseError,
        }]
        = useLazyQuery<{
            searchPaginatedActiveReleasesByCustomerId: CustomerReleasePageType
        }>(SEARCH_PAGINATED_RELEASES_BY_CUSTOMER_ID,
            {
                notifyOnNetworkStatusChange: true,
            }
        )

    useEffect(() => {
        // prevent sending a request when a drawer is not open
        if (customerId !== '') {
            searchPaginatedReleasesByCustomerId({
                variables: { ...paginatedReleasesByCustomerIdQuery, customerId: customerId }
            })
        }
    }, [searchPaginatedReleasesByCustomerId, paginatedReleasesByCustomerIdQuery, customerId]);

    useEffect(() => {
        if (!isDrawerOpen) {
            setPage(0);
            setPaginatedReleasesByCustomerIdQuery((prev) => ({ ...prev, search: '', page: 0 }));
        }
    }, [isDrawerOpen])

    const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputString(event.currentTarget.value);
    }

    const handleFilterSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setPage(0);
        setPaginatedReleasesByCustomerIdQuery((prev) => ({ ...prev, search: inputString, page: 0 }));
    }

    const resetCustomerReleaseTable = () => {
        setInputString('');
        setPage(0);
        setPaginatedReleasesByCustomerIdQuery((prev) => ({ ...prev, search: '', page: 0 }));
    }

    const toggleStatus = async (customerId: string, releaseId: string) => {
        try {
            const { data } = await toggleReleaseAccessForCustomer({
                variables: {
                    customerId: customerId,
                    releaseId: releaseId
                },
            });

            if (data) {
                const disabled: boolean = data.toggleReleaseCustomer.releaseCustomer.disabled;
                const message = (disabled) ? 'Release Customer Access Disabled!' : 'Release Customer Access Enabled!';
                const severity = (disabled) ? 'warning' : 'success';
                setSnackbar({ isOpen: true, message, severity });
            }
        } catch (e) {
            setSnackbar({ isOpen: true, message: 'Problem enabling release. Please try again.', severity: 'error' });
        }
    }

    const handlePageChange = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
        setPage(newPage);
        setPaginatedReleasesByCustomerIdQuery((prev) => ({ ...prev, page: newPage }))
    }

    const onEnabledToggleChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        const customerRelease: CustomerReleaseType = JSON.parse(event.currentTarget.value);
        toggleStatus(customerId, customerRelease.release.id);
    }

    const closeDrawer = (event: any, callback: (action: React.SetStateAction<boolean>) => void) => {
        if (
            event.type === 'keydown'
            && ((event as React.KeyboardEvent).key === 'Tab'
                || (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }
        callback(false);
    };

    const closeSnackbar = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') return;
        setSnackbar({ ...snackbar, isOpen: false });
    };

    return (
        <Drawer
            anchor="right"
            open={isDrawerOpen}
            onClose={(event) => closeDrawer(event, closeDrawerCallback)}
        >
            <SearchCustomerDrawerStyles>
                <h2 className='bar'>
                    {`${drawerTitle} For ${customerName}`}
                </h2>
                <InfoBar
                    isOpen={snackbar.isOpen}
                    message={snackbar.message}
                    severity={snackbar.severity}
                    closeSnackbar={closeSnackbar} />
                <form onSubmit={handleFilterSubmit} autoComplete="off">
                    <TextField
                        variant="standard"
                        id="standard-search"
                        label="Search for..."
                        type="search"
                        value={inputString}
                        onChange={handleFilterChange} />
                    <PrimaryButton label="Search" type="submit" />
                    <SecondaryButton label="Reset" type="reset" onClick={resetCustomerReleaseTable} />
                </form>
                {(paginatedCustomerReleaseLoading
                    ? <BarLoader />
                    : paginayedCustomerReleaseError
                        ? <NoMatch />
                        : <CustomerReleasesTable
                            customerReleases={paginatedCustomerReleasesData === undefined
                                ? []
                                : paginatedCustomerReleasesData.searchPaginatedActiveReleasesByCustomerId.customerReleases}
                            total={
                                paginatedCustomerReleasesData === undefined
                                    ? 0
                                    : paginatedCustomerReleasesData.searchPaginatedActiveReleasesByCustomerId.total
                            }
                            page={page}
                            perPage={perPage}
                            handleChangePage={handlePageChange}
                            onEnabledToggleChanged={onEnabledToggleChanged} />)}
            </SearchCustomerDrawerStyles>
        </Drawer>
    );
}

export default CustomerSearchReleasesDrawer;
