import React from 'react';
import { Auth0ContextInterface, useAuth0 } from '@auth0/auth0-react';

import styles from './Landing.module.css';
import Header from '../layout/header/Header';

const Landing: React.FC = () => {
  const { isAuthenticated }: Auth0ContextInterface = useAuth0();

  return (
    <>
      <Header pageTitle='Welcome!' />
      <div className={styles.landing}>
        <h1>Welcome to the Tasktop Customer Portal!</h1>
        {isAuthenticated
          ? (
            <p>Please use the sidebar on the left to navigate the site.</p>
          )
          : (
            <p>Please Login or Sign-Up at the top right of the page.</p>
          )}
      </div>
    </>
  );
};

export default Landing;
