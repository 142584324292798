import { gql } from '@apollo/client';

export const SEARCH_RELEASES = gql`
  query SearchReleases($search: String) {
    searchReleases (search: $search) {
      id
      productId {
        id
        name
      }
      name
      description
      releaseDate
      isArchived
    }
  }
`;

export const SEARCH_FILTERED_RELEASES = gql`
  query SearchFilteredReleases($search: String, $initialDate: DateTime, $endDate: DateTime,
  $product: String, $version: String) {
    searchFilteredReleases(search: $search, initialDate: $initialDate, endDate: $endDate,
    product: $product, version: $version){
      id
      productId {
        id
        name
      }
      name
      description
      releaseDate
      isArchived
    }
  }
`;

export const VERSIONS_BY_PRODUCT = gql`
  query VersionsByProduct($product: String, $top: Int, $initialDate: DateTime, $endDate: DateTime) {
    versionsByProduct(product: $product, top: $top, initialDate: $initialDate, endDate: $endDate)
  }
`;

export const GET_RELEASE_BY_ID = gql`
  query GetReleaseById($id: String) {
    releaseById(id: $id) {
      id
      name
      description
      releaseDate
      licenseTitle
      licenseText
      isArchived
      security
      productId {
        name
      }
    }
  }
`;

export const GET_RELEASE_OF_SAME_PRODUCT = gql`
  query GetReleaseOfSameProduct($product: String, $excludeId: String) {
    searchReleases(product: $product, excludeId: $excludeId) {
      id
      name
      productId {
        name
      }
    }
  }
`;

export const GET_CUSTOMERS_OF_RELEASE = gql`
  query GetCustomersOfRelease($id: String) {
    releaseById(id: $id) {
      releaseCustomers{
        customer{
          custId
        }
      }
    }
  }
`;

export const CREATE_RELEASE = gql`
  mutation CreateRelease(
    $productId: Int,
    $name: String,
    $description: String,
    $releaseDate: String,
    $licenseTitle: String,
    $licenseText: String,
    $security: Boolean
  ) {
      createRelease(
        productId: $productId,
        name: $name,
        description: $description,
        releaseDate: $releaseDate,
        licenseTitle: $licenseTitle,
        licenseText: $licenseText,
        security: $security
      ) {
        release {
        id
        productId {
          id
          name
        }
        name
        description
        releaseDate
        licenseTitle
        licenseText
        isArchived
        security
      }
    }
  }
`;

export const TOGGLE_RELEASE_STATUS = gql`
  mutation ToggleReleaseStatus($id: Int, $isArchived: Boolean) {
    updateRelease(id: $id, isArchived: $isArchived) {
      release {
        id
        isArchived
      }
    }
  }
`;

export const UPDATE_RELEASE = gql`
  mutation UpdateRelease(
    $id: Int,
    $productId: Int,
    $name: String,
    $description: String,
    $releaseDate: String,
    $licenseTitle: String,
    $licenseText: String,
    $isArchived: Boolean,
    $security: Boolean
  ) {
    updateRelease(
      id: $id,
      productId: $productId,
      name: $name,
      description: $description,
      releaseDate: $releaseDate,
      licenseTitle: $licenseTitle,
      licenseText: $licenseText,
      isArchived: $isArchived,
      security: $security
    ) {
      release {
        id
      }
    }
  }
`;

export const GET_FILES_BY_RELEASE_ID = gql`
  query GetFilesByReleaseId($releaseId: Int) {
    filesByReleaseId (releaseId: $releaseId) {
      id
      checksum
      releaseId {
        id
      }
      description
      filePath
    }
  }
`;

export const CREATE_RELEASE_FILE = gql`
  mutation CreateReleaseFile($checksum: String, $description: String, $releaseId: Int, $filePath: String) {
    createReleaseFiles (checksum: $checksum, description: $description, filePath: $filePath, releaseId: $releaseId) {
      releaseFiles { checksum }
    }
  }
`;

export const UPDATE_RELEASE_FILE = gql`
  mutation UpdateReleaseFile($id: Int, $checksum: String, $releaseId: Int, $description: String, $filePath: String) {
    updateReleaseFile (id: $id, checksum: $checksum, releaseId: $releaseId, description: $description, filePath: $filePath) {
      releaseFile { id }
    }
  }
`;

export const DELETE_RELEASE_FILE = gql`
  mutation DeleteReleaseFile($id: Int) {
    deleteReleaseFile (id: $id) {
      ok
      message
    }
  }
`;

export const TOGGLE_RELEASE_CUSTOMER_ACCESS = gql`
  mutation ToggleReleaseCustomerAccess($customerId: Int, $releaseId: Int){
    toggleReleaseCustomer(customerId:$customerId, releaseId:$releaseId){
      releaseCustomer{
        id
        disabled
        notified
        release{
          id
          name
        }
        customer{
          custId
          email
          name
        }
      }
    }
  }
`;

export const ENABLE_RELEASE_FOR_CUSTOMER = gql`
  mutation EnableReleaseForCustomer($customerId: Int, $releaseId: Int){
    enableReleaseForCustomer(customerId: $customerId, releaseId: $releaseId) {
      releaseCustomer{
        id
        release{
          id
        }
        disabled
        customer{
          custId
        }
      }
    }
  }
`;

export const ENABLE_RELEASE_FOR_MANY_CUSTOMERS = gql`
  mutation EnableReleaseForManyCustomers($customerId: [Int], $releaseId: Int){
    enableReleaseForManyCustomers(customerIdArray: $customerId, releaseId: $releaseId) {
      releaseCustomer{
        id
        release{
          id
        }
        disabled
        customer{
          custId
        }
      }
    }
  }
`;

export const SEARCH_PAGINATED_RELEASES_BY_CUSTOMER_ID = gql`
  query SearchPaginatedActiveReleasesByCustomerId($customerId: String, $page: Int, $perPage: Int, $search: String) {
    searchPaginatedActiveReleasesByCustomerId(customerId: $customerId, page: $page, perPage: $perPage, search: $search) {
      page
      perPage
      total
      customerReleases {
        release {
          id
          name
          description
        }
        disabled
      }
    }
  }
`;