import React from 'react';

import TasktopTable from '../../layout/table/tasktopTable/TasktopTable';
import { DownloadCountsByReleaseType } from '../types';

type IndividualReleaseTableType = {
    downloadCountsByRelease: DownloadCountsByReleaseType[],
};

const IndividualReleaseTable: React.FC<IndividualReleaseTableType> = (props: IndividualReleaseTableType) => (
  <TasktopTable
    title="Downloads by Individual Release"
    columns={[
      { title: 'Release', field: 'release', defaultSort: 'desc' },
      { title: 'Count', field: 'count' },
    ]}
    data={
      props.downloadCountsByRelease.map((item) => ({
        id: item.release,
        release: item.release,
        count: item.count
      }))
    }
    export
  />
);

export default IndividualReleaseTable;
