import React from 'react';

import {
  faBookOpen, faBoxOpen, faChartLine, faCube, faDownload, faFile, faNewspaper, faUser, faUserCog,
} from '@fortawesome/free-solid-svg-icons';

import Customers from './components/customers/Customers';
import Products from './components/products/Products';
import Accounts from './components/accounts/Accounts';
import Releases from './components/releases/Releases';
import ContentSpaces from './components/contentSpaces/ContentSpaces';
import HubDownloadReport from './components/reporting/HubDownloadReport/HubDownloadReport';
import HubLicenseReport from './components/reporting/HubLicenseReport/HubLicenseReport';

import { MenuLinkProps } from './components/widgets/links/MenuLink';
import AccountsPage from './components/accounts/elements/accountsPage/AccountsPage';
import ReleasesPage from './components/releases/elements/releasesPage/ReleasesPage';
import AccountSettings from "./components/accountSettings/AccountSettings";
import Resources from "./components/resources/Resources";
import DownloadReleases from "./components/downloadReleases/DownloadReleases";
import CustomersPage from './components/customers/elements/customersPage/CustomersPage';

export type Page = {
    name: string,
    component: React.FC,
    exact: boolean,
    path: string,
    menuLink?: MenuLinkProps,
};

export const pages: Page[] = [
  {
    name: 'Downloads',
    component: DownloadReleases,
    exact: false,
    path: '/downloads',
    menuLink: {
      linkName: 'Downloads',
      path: '/downloads/',
      icon: faDownload,
      activeClassName: 'selected',
      isExact: true,
      iconClassName: 'icon',
      access: 'all',
    },
  },
  {
    name: 'Resources',
    component: Resources,
    exact: true,
    path: '/resources',
    menuLink: {
        linkName: 'Resources',
        path: '/resources/',
        icon: faFile,
        activeClassName: 'selected',
        isExact: true,
        iconClassName: 'icon',
        access: 'all'
    },
  },
  {
    name: 'Settings',
    component: AccountSettings,
    exact: true,
    path: '/settings',
    menuLink: {
      linkName: 'Settings',
      path: '/settings/',
      icon: faUserCog,
      activeClassName: 'selected',
      isExact: true,
      iconClassName: 'icon',
      access: 'all'
    },
  },
  {
    name: 'Customers',
    component: Customers,
    exact: true,
    path: '/customers',
    menuLink: {
      linkName: 'Customers',
      path: '/customers',
      icon: faUser,
      activeClassName: 'selected',
      isExact: true,
      iconClassName: 'icon',
      access: 'admin',
    },
  },
  {
    name: 'Products',
    component: Products,
    exact: true,
    path: '/products',
    menuLink: {
      linkName: 'Products',
      path: '/products',
      icon: faCube,
      activeClassName: 'selected',
      isExact: true,
      iconClassName: 'icon',
      access: 'admin',
    },
  },
  {
    name: 'Accounts',
    component: Accounts,
    exact: true,
    path: '/accounts',
    menuLink: {
      linkName: 'Accounts',
      path: '/accounts',
      icon: faBookOpen,
      activeClassName: 'selected',
      isExact: true,
      iconClassName: 'icon',
      access: 'admin',
    },
  },
  {
    name: 'Releases',
    component: Releases,
    exact: true,
    path: '/releases',
    menuLink: {
      linkName: 'Releases',
      path: '/releases/',
      icon: faBoxOpen,
      activeClassName: 'selected',
      isExact: true,
      iconClassName: 'icon',
      access: 'admin',
    },
  },
  {
    name: 'Content Spaces',
    component: ContentSpaces,
    exact: true,
    path: '/contentSpaces',
    menuLink: {
      linkName: 'Content Spaces',
      path: '/contentSpaces/',
      icon: faNewspaper,
      activeClassName: 'selected',
      isExact: true,
      iconClassName: 'icon',
      access: 'admin',
    },
  },
  {
    name: 'Hub Download Report',
    component: HubDownloadReport,
    exact: true,
    path: '/hubDownloadReport',
    menuLink: {
      linkName: 'Hub Download',
      path: '/hubDownloadReport/',
      icon: faChartLine,
      activeClassName: 'selected',
      isExact: true,
      iconClassName: 'icon',
      access: 'reporting',
    },
  },
  {
    name: 'Hub License Report',
    component: HubLicenseReport,
    exact: true,
    path: '/hubLicenseReport',
    menuLink: {
      linkName: 'Hub License',
      path: '/hubLicenseReport/',
      icon: faChartLine,
      activeClassName: 'selected',
      isExact: true,
      iconClassName: 'icon',
      access: 'reporting',
    },
  },
  {
    name: 'Account Information',
    component: AccountsPage,
    exact: false,
    path: '/accounts/:id',
  },
  {
    name: 'Release Information',
    component: ReleasesPage,
    exact: false,
    path: '/releases/:id',
  },
  {
    name: 'Customer Information',
    component: CustomersPage,
    exact: false,
    path: '/customers/:id'
  }
];
