import { gql } from '@apollo/client';

export const GET_USER_ROLES = gql`
  query GetUserRoles($id: String) {
    customerRoles(id: $id){
      id
      description
      name
    }
  }
`;
